import React, { useState, useEffect } from 'react';
import { Card, Table, Input, Select, Button, Space, Spin } from 'antd';
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import constants from '../../Constants/constants';
import { fetchStaffApi } from '../../Services/Api';
import { performSearch, CustomSortIcon, getNextAndPreviousButtons, HighlightedHTML } from '../../GlobalFunctions/GlobalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { COOKIE, getCookie } from '../../Services/Cookie';

const { Option } = Select;

const CoursePlanNotes = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const { student_id, } = location.state || {};
	const [tableData, setTableData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const pageTitle = constants.CoursePlanNotes;
	const breadcrumbItems = [];
	if(location?.state?.locationData?.from_route){
		breadcrumbItems.push({ label: location?.state?.locationData?.from, href: location.state?.locationData?.from_route })
	}
	breadcrumbItems.push(
		{ label: constants.SemesterCourses, href: '/student-information',data:location?.state?.locationData },
		{ label: constants.CoursePlanNotes, active: true }
	)
	const [selectedYear, setSelectedYear] = useState('0');
	const isStudent = getCookie(COOKIE.Role) === '0';
	useEffect(() => {
		getStudentNotes();
	}, [selectedYear]);

	const getStudentNotes = () => {
		setLoading(true);
		let formData = {
			student_id: student_id,
			studentYear: selectedYear
		}
		fetchStaffApi('/get-student-notes', 'POST', formData, isStudent ? 'student' : null)
			.then((res) => {
				if (res?.code === "200") {
					setTableData(res.data);
					setFilteredData(res.data)
				} else {
					setTableData([]);
					setFilteredData([]);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching student notes:", error);
				setTableData([]);
			});
	};
	const handleYearChange = (value) => {
		setSelectedYear(value);
	};

	const AdvisingDocsCols = [
		{
			title: `${constants.YearProgram}`,
			dataIndex: 'year_in_program',
			key: 'yearProgram',
			sorter: (a, b) => a.year_in_program.length - b.year_in_program.length,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			// render: (yearProgram) => <HighlightedText text={yearProgram} highlight={searchValue} />
		},
		{
			title: constants.Semester,
			dataIndex: 'semester_type',
			key: 'semester_type',
			sorter: (a, b) => a.semester_type.length - b.semester_type.length,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.Notes,
			dataIndex: 'student_note',
			key: 'student_note',
			width: '35%',
			sorter: (a, b) => a.student_note.length - b.student_note.length,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (student_note) => student_note?.trim() ? <HighlightedHTML html={student_note} highlight={searchValue} /> : '-',
		},
		{
			title: 'Advisor Submission Date',
			dataIndex: 'advisor_submitted_date',
			key: 'advisor_submitted_date',
			sorter: (a, b) => a.advisor_submitted_date.length - b.advisor_submitted_date.length,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: 'Student Acknowledgement Date',
			dataIndex: 'student_submitted_date',
			key: 'student_submitted_date',
			sorter: (a, b) => a.student_submitted_date.length - b.student_submitted_date.length,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (date) => date?.trim() ? <>{date}</> : '-'
		},
	];

	const viewSemCourses = () => {
		if(isStudent) {
			navigate('/semester-courses')
			return
		}
		let props= {
      tabkey:"semester-courses",
      tab_title:constants.SemesterCourses,
      isArchived:location?.state?.locationData?.isArchived,
      reloadTab:false,
      from:location?.state?.locationData?.from,
      from_route:location?.state?.locationData?.from_route,
      student_name:location?.state?.locationData?.student_name,
      student_id:location?.state?.locationData?.student_id,
      sem_id:location?.state?.locationData?.student_semester,
      year_in_program:location?.state?.locationData?.year_in_program,
      student_advisor_id:location?.state?.locationData?.student_advisor_id,
    }
		navigate("/student-information",{state:{...props}})
	};

	const onSearch = (event) => {
		const search = event.target.value;
		const fieldNames = ["student_note"];
		const searchedValue = performSearch(search, filteredData, fieldNames);
		setTableData(searchedValue);
		setSearchValue(search);
	};

	return (
		<div>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems}/>
			<div>
				<Card className="container-border-custom">
					<div className='flex flex-wrap gap-2 justify-between'>
						<Space>
							<Button
								className='!border border-cyan-500 text-cyan-500'
								onClick={viewSemCourses}
							>
								{constants.Back}
							</Button>
							<Select placeholder={constants.SelectYear} className='!w-48' onChange={handleYearChange} allowClear>
								{constants.YearOptions.map((option) => (
									<Option key={option.Year_id} value={option.Year_id}>{option.year_name}</Option>))}
							</Select>
						</Space>
						<Input className="w-48" placeholder={constants.Search} onChange={onSearch} value={searchValue} />
					</div>
					<div className="mt-2">
						<Spin tip={constants.PleaseWait} spinning={loading}>
							<Table
								className="records-table data_table"
								columns={AdvisingDocsCols}
								dataSource={tableData}
								bordered
								scroll={{ x: 768, y: 'calc(100vh - 350px)' }}
								size="small"
								pagination={{
									showTotal: (total, range) => <span className="fw-bold fs-6">{`Showing ${range[0]}-${range[1]} of ${total} entries`}</span>,
									itemRender: getNextAndPreviousButtons,
									pageSize: 20,
								}}
							/>
						</Spin>
					</div>
				</Card>
			</div>
		</div>
	)
}

export default CoursePlanNotes