import React from 'react';
import { Button, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { COOKIE, getCookie } from '../../Services/Cookie';
import constants from '../../Constants/constants';
const PageNotFound = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const redirectToDashboard = () =>{
		const userRole = getUserRole();
		if(userRole?.role==="0"){
			navigate("/student-dashboard")
		}else{
			navigate("/dashboard")
		}
	}
	const getUserRole = () => {
		let user = {
			userId:getCookie(COOKIE.UserId),
			token:getCookie(COOKIE.Token),
			role:getCookie(COOKIE.Role),
		}
		return user;
	};
	return(
		<div className='flex items-center justify-center min-h-screen'>
		<Result
			status={location?.state?.fromLocation==="fromRoutes"?"403":"404"}
			title={location?.state?.fromLocation==="fromRoutes"?constants.NotAuthorizedText:constants.PageNotFoundText}
			extra={
				location?.state?.fromLocation==="fromRoutes"?
				<Button type="primary" onClick={()=>{redirectToDashboard()}}>Back to Dashboard</Button>
				:<Button type="primary" onClick={()=>{navigate("/student-login")}}>Back to login</Button>
		}
		/>
		</div>
	)
};
export default PageNotFound;
