import React,{useState,useEffect} from 'react';
import { Row, Col, Card, Form, Button, Table, Select, Input, message, Space } from 'antd';
import constants from '../../Constants/constants';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { fetchStaffApi, fetchStaffDownloadApi } from '../../Services/Api';
import {CustomSortIcon, HighlightedText, getNextAndPreviousButtons, performSearch, showTotalItems } from '../../GlobalFunctions/GlobalFunctions';
import {useNavigate} from 'react-router-dom';

const DissmissedList = () => {
	const [form] = Form.useForm(); 
	const navigate = useNavigate();
	const [dismissedStudentsList,setDismissedStudentsList] = useState([]);
	const [studentType, setStudentType] = useState(0);
	const [studentYear, setStudentYear] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [filteredDismissedStudentList, setFilteredDismissedStudentList] = useState([]);
	const [selectedRecord, setSelectedRecord] = useState({});
	const [selectedRow, setSelectedRow] = useState([]);
  const [selectedStudentActive,setSelectedStudentActive] = useState('');
	const [pdfDownload, setPdfDownload] = useState(false);
	const [sortOrder,setSortorder] = useState('');
	const breadcrumbItems = [
		{
			label: constants.DismissedStudents,
			active: true
		}
	];
	useEffect(() => {
		getDissmissedStudentList();
	}, [studentType, studentYear])
	const rowSelection = {
		selectedRowKeys: selectedRow,
		onSelect: (record, selected) => {
			const newRowKeys = selected ? [record.id] : []; // If a row is selected, set its id to selectedRowKeys, otherwise clear it
			setSelectedRow(newRowKeys);
			setSelectedRecord(selected ? record : {}); // Update selectedRecord if a row is selected
			setSelectedStudentActive(selected?record?.is_active:''); // Update selectedStudentActive if a row is selected
		},
		hideSelectAll: true, // Hide header checkbox
	};
	useEffect(()=>{
		if (selectedRecord){
			setSelectedStudentActive(selectedRecord?.is_active);
		}else{
			setSelectedStudentActive('');
		}
	}, [selectedRecord])
	const getStudentType = (value)=>{
		clearSearch();
		if (value === undefined || value === null || value === '') {
			setStudentType(0);
		} else {
			setStudentType(value);
		}
	}
	const getStudentYear = (value) => {
		clearSearch();
		if (value === undefined || value === null || value === '') {
			setStudentYear(0);
		} else {
			setStudentYear(value);
		}
	}
	const clearSearch = () => {
		form.setFieldsValue({searchBar:""});
		setSearchText("");
	};
	const getDissmissedStudentList = ()=>{
		let payload = null;
		payload={
			year_in_program: studentYear,
			type: studentType
		}
		fetchStaffApi(`/get-dismissed-students`, 'POST', payload).then((res) => {
			let data = res?.data?.length > 0 ? res?.data?.map((item) => { 
				let archived = item?.is_active === '3' ? constants.Archived : ''
				let status = statusTypes[item?.new_status]
				if(archived) status += `, ${archived}`
				return {
				...item,
				statusLabel: status
			}}):[]
			setDismissedStudentsList(data);
			setFilteredDismissedStudentList(data);
		});
	}

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchText(search);
		const searchFieldNames = [
			'name',
			'year_in_program_text',
			'gpa',
			'statusLabel'
		];
		let searchedValue = performSearch(search, dismissedStudentsList, searchFieldNames);
		setFilteredDismissedStudentList(searchedValue);
	};
	const statusTypes = {
		'1': constants.Dismissed,
		'2': constants.Probation,
		'3': constants.LeftProgram,
		'4': constants.Break,
		'5': constants.InActive,
		'6': constants.SlpMajor,
	}
	const tabColumns = [
		{
			title: constants.Name,
			key: '1',
			sorter: (a, b) => a.name.localeCompare(b.name),
			showSorterTooltip: { placement: 'bottom' },
			render: (record) => {
				const studentName = record?.name || '';
				return <HighlightedText text={studentName} highlight={searchText} />
			},
			sortIcon: ({ sortOrder }) => {
				setSortorder(sortOrder);
				return <CustomSortIcon sortOrder={sortOrder} />;
			},
		},
		{
			title:constants.YearProgram,
			key: '2',
			sorter: (a, b) => a.year_in_program_text.localeCompare(b.year_in_program_text),
			showSorterTooltip: { placement: 'bottom' },
			render: (record) => {
				const yearLabel = record?.year_in_program_text || '';
				return <HighlightedText text={yearLabel} highlight={searchText} />
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title:<div style={{textAlign: 'left'}}>{constants.Gpa}</div>,
			key: '3',
			align: 'right',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => a.gpa - b.gpa,
			render:(record)=>{
				if (record?.gpa) {
					let gpaScoreLength = record.gpa.length;
					return <HighlightedText text={gpaScoreLength === 1 ? (record.gpa + '.00') : gpaScoreLength === 3 ? record.gpa + '0' : record.gpa} highlight={searchText} />;
				} else {
					return "-";
				}
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.Status,
			key: '4',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => {
				const statusA = parseInt(a.student_type);
				const statusB = parseInt(b.student_type);
				if (statusA !== statusB) {
					return statusA - statusB;
				}
				// If statuses are the same, sort by active status
				const isActiveA = a.is_active === '1';
				const isActiveB = b.is_active === '1';
				if (isActiveA && !isActiveB) {
					return -1;
				} else if (!isActiveA && isActiveB) {
					return 1;
				} else {
					return 0;
				}
			},
			render: (record) => {
				return <HighlightedText text={record?.statusLabel} highlight={searchText} />
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		}
	]
	const redirectToEditStudentPage = (record = null) => {
		let selected = record ? record : selectedRecord
		let state = {
			student_id: selected?.id,
			student_semester: selected?.student_semester_id,
			from: constants.DissmissedList,
			from_route: "/dismissed-students",
			tabkey: "StudentDetails",
			tab_title: constants.StudentDetails,
			student_name: selected?.student_first_name + " " + selected?.student_last_name,
			isArchived:selected?.is_active==="3"?true:false,
			isActive:selected?.is_active,
		}
		navigate(`/student-information`,{state: state});
	};
	const downloadPdf = () => {
		if (!pdfDownload) {
			setPdfDownload(true);
			const withPdfPayload = {
				year_in_program: studentYear,
				type: studentType,
				generate_pdf:0,
				is_descend: sortOrder === 'descend'?1:0,
			};
			fetchStaffDownloadApi(`/get-dismissed-students`, 'POST', withPdfPayload).then((res) => {
				if (res) {
					const blob = new Blob([res], { type: 'application/pdf' });
					const url = URL.createObjectURL(blob);
					window.open(url, '_blank');
				}
				setPdfDownload(false);
			});
		}
	};
	const changeStudentStatus =(selectedId)=>{
		let payload = null;
		payload={
			id: selectedId
		}
		fetchStaffApi(`/update-dismissed-student-status`, 'POST', payload).then((res) => {
			if(res?.code === '200'){
				message.success(constants.DataSaved);
				setSelectedRow([]);
				getDissmissedStudentList();
				setSelectedStudentActive('8')
			}else{
				message.error(constants.Error);
			}
		});
	}
	const handleRowClick = (record) => {
		rowSelection.onSelect(record, !selectedRow.includes(record.id));
	};
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.DismissedStudents} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom position-relative">
				<Form form={form}>
					<Row>
						<Col span={24}>
							<div className='flex justify-between flex-wrap gap-3'>
								<Space wrap>
									<Form.Item name="studentType" className='w-36 !mb-2.5'>
										<Select 
											placeholder='Type' 
											maxTagCount='responsive'
											onChange={getStudentType}
											allowClear
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().includes(input.toLowerCase())
											}
										>
											{constants.StudentTypeList.map((record) =>
												<Select.Option value={record.value} key={record.label}>{record.label}</Select.Option>
											)}
										</Select>
									</Form.Item>
									<Form.Item name="studentYear" className='w-36 !mb-2.5'>
										<Select 
											placeholder='Year' 
											maxTagCount='responsive'
											onChange={getStudentYear}
											disabled={form.getFieldValue('studentType') === 2}
											allowClear
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().includes(input.toLowerCase())
											}
										>
											{constants.StudentYearOptions.map((option, index) => (
												<Select.Option key={option} value={index+1}>
													{option}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<div className='!mb-2.5'>
										<Button 
										type={selectedStudentActive === '3' ? 'default' : 'primary'}
										className={selectedStudentActive === '3' ? 'bgGreen500' : '#337AB7'}
										disabled={selectedRow.length === 0}
											onClick={() => changeStudentStatus(selectedRecord?.id)}>{selectedStudentActive === '3' ? constants.Unarchive : constants.Archive}</Button>
										<Button type='primary' className='ms-2' disabled={selectedRow.length === 0} onClick={() => redirectToEditStudentPage()}>{constants.Edit}</Button>
										<Button type='primary' className="ms-2" onClick={downloadPdf}>{constants.Download}</Button>
									</div>
								</Space>
								<Form.Item name="searchBar" className='!mb-2.5'>
									<Input placeholder={constants.Search} className='w-48' onChange={onSearch} value={searchText}></Input>
								</Form.Item>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item name="gpaValues">
								<Table
									className='records-table data_table'
									bordered
									dataSource={filteredDismissedStudentList}
									columns={tabColumns}
									size="small"
									rowSelection={{
										type: "checkbox",
										...rowSelection
									}}
									pagination={{
										pageSize: constants.TablePageSize,
										showTotal: (total, range) => showTotalItems(total, range, filteredDismissedStudentList),
										itemRender: getNextAndPreviousButtons,
									}}
									rowKey={(record) => record.id}
									onRow={(record) => ({
										onClick: () => handleRowClick(record),
										onDoubleClick: () => redirectToEditStudentPage(record),
									})}
								>
								</Table>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Card>
		</div>
	)
}

export default DissmissedList
