import React, { useEffect, useState } from "react";
import { Card, Table, Input, Tooltip } from 'antd';
import BreadcrumbHeader from "./Component/BreadcrumbHeader";
import { fetchStaffApi } from '../Services/Api';
import moment from 'moment/moment';
import dayjs from 'dayjs';
import constants from "../Constants/constants";
import { getNextAndPreviousButtons, performSearch, CustomSortIcon } from '../GlobalFunctions/GlobalFunctions'

const HighlightedText = ({ text, highlight }) => {
	if (!highlight.trim()) {
		return <span>{text}</span>;
	}

	const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
	return (
		<span>
			{parts.map((part, i) => (
				<span key={i} className={part.toLowerCase() === highlight.toLowerCase() ?
					'highlight' : ''}>
					{part}
				</span>
			))}
		</span>
	);
};
const ActivityLogs = () => {
	const [tableData, setTableData] = useState([]);
	const [showHiddenActivityLogs,] = useState('1');

	const [filterTable, setFilterTable] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	const [currentPage, setCurrentPage] = useState(1);

	//fetching activity list data
	useEffect(() => {
		getActivityList();
	}, [showHiddenActivityLogs]);

	const getActivityList = () => {
		fetchStaffApi('/get-activity-logs?value=2', 'GET').then((res) => {
			if (res?.code === "200") {
				let data = res?.data?.map((item) => { return {
					...item,
					time: moment(item?.date_added).startOf('day').fromNow(),
				}})
				setTableData(data);
				setFilterTable(data);
			} else {
				setTableData([]);
			}
		});
	};

	const pageTitle = 'Activity Logs';
	const breadcrumbItems = [
		{ label: 'Activity Logs', href: '/', active: true },
	]

	// Activity logs 
	const ActivityLogsCols = [
		{
			title: '#',
			rowScope: 'row',
			width: '5%',
			render: (_, __, index) => (currentPage - 1) * 20 + index + 1,
		},
		{
			title: 'Messages',
			dataIndex: 'message',
			key: 'message',
			render: (text) => <HighlightedText text={text} highlight={searchValue} />,
		},
		{
			title: 'Time',
			dataIndex: 'date_added',
			key: 'date_added',
			render: (value,record) => {
				return <Tooltip title={dayjs(value).format(constants.DateAndTimeFormat)}><span><HighlightedText text={record?.time} highlight={searchValue} /></span></Tooltip>
			},
			sorter: (a, b) => new Date(a.date_added) - new Date(b.date_added),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			width: '20%'
		},
	];

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search);
		const searchFieldNames = [
			'message',
			'time'
		];
		let searchedValue = performSearch(search, filterTable, searchFieldNames);
		setTableData(searchedValue);
	};

	return (
		<div>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />

			<Card
				className="container-border-custom"
			>
				<div className="flex justify-end mb-2">
					<Input className="w-48" placeholder="Search" onChange={onSearch} value={searchValue} />
				</div>
				<Table
					columns={ActivityLogsCols}
					dataSource={tableData}
					className="records-table data_table"
					size="small"
					bordered
					scroll={{ x: 768,y: 'calc(100vh - 281px)' }}
					rowKey="yourKey"
					rowRender={(record, index) => ({
						key: index,
					})}
					pagination={{
						className: "!mb-0",
						showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
						itemRender: getNextAndPreviousButtons,
						pageSize: 20,
						onChange: setCurrentPage,
					}}
				/>
			</Card>
		</div>
	);
};
export default ActivityLogs;
