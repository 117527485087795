import React, { useEffect, useState } from 'react';
import { Input, Checkbox, Button, Form, Row, Col, Radio, message, Modal } from 'antd';
import constants from '../../../Constants/constants';
import { CloseOutlined, DragOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { checkEmptySpace, closeModal, removeExtraSpaces } from '../../../GlobalFunctions/GlobalFunctions';
import { fetchApi } from '../../../Services/Api';
const { TextArea } = Input;


const QuestionContentModal = ({ type, surveyTitle, deleteQuestion, setShowDeleteModal, setShowAddQuestionModal, setShowEditQuestionModal,surveyDetails,getAllQuestions,modalVisible,modalTitle,editQuestionId,setEditQuestionId}) => {
	const [form] = Form.useForm();
	const [continueSave,setContinueSave]=useState(false);
	const [mandatoryQuestion,setMandatoryQuestion] = useState(false);
	const [questionTypeValue,setQuestionTypeValue] = useState(surveyTitle === 'Instructor' ? 3 : 1);
	const [questionOptions,setQuestionOptions] = useState([
		{ option_text: '', showDetailsInput: false, option_detail:'',order_by:1,edited:false},
		{ option_text: '', showDetailsInput: false, option_detail:'',order_by:2,edited:false},
	]);
	const [deletedIds, setDeletedIds] = useState([]);
	const [courseOptions,setCourseOptions]=useState(false);
	const [loading,setLoading] = useState(false);
	const [refresh,setRefresh] = useState(false);
	const handleAddRow = () => {
		const newQuestion = {option_text: '', option_detail: '', order_by: Number(questionOptions.length + 1)};
		setQuestionOptions([...questionOptions,newQuestion]);
	};
	const handleOptionEdit = (index,field,valueOrChecked) => {
		let updatedOptions = [...questionOptions];
		updatedOptions[index][field] = valueOrChecked;
		updatedOptions[index].edited = true; // Set the edited property to true
		setQuestionOptions(updatedOptions);
	};
	const handleDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const newItems = Array.from(questionOptions);
		const [reorderedItem] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, reorderedItem);
		newItems[result?.destination?.index].order_by=result?.destination?.index+1;
		newItems[result?.destination?.index].dragged=true;
		newItems[result?.source?.index].order_by=result?.source?.index+1;
		setQuestionOptions(newItems);
	};
	const getMandatoryTypeValue = (e)=>{
		setMandatoryQuestion(e.target.checked);
	}
	useEffect(()=>{
		if(type===1){
			form.setFieldsValue({
				question: '',
				question_type: surveyDetails?.surveytype === 'Instructor'? 3 : 1,
			});
		}else if(type===2){
			fetchQuestionEditData();
		}
	},[])
	const fetchQuestionEditData=()=>{
		fetchApi(`/all-questions?survey_id=${surveyDetails?.record?.id}&question_id=${editQuestionId}`, 'POST').then((res) => {
			if(res&&res.data.length>0){
				let optionData=res?.data[0]?.options;
				if(optionData&&surveyDetails?.surveytype !== 'Instructor'&&surveyDetails?.surveytype !== 'Courses'){
					for (let index = 0; index < optionData.length; index++) {
						optionData[index].edited=false;
						optionData[index].option_id = optionData[index].id;
						optionData[index].order_by=parseInt(optionData[index].order_by);
						if(optionData[index].option_detail){
							optionData[index].showDetailsInput=true;
						}else{
							optionData[index].showDetailsInput=false;
						}
					}
					optionData.sort((a, b) => a.order_by - b.order_by);
				}
				setQuestionOptions(optionData);
				form.setFieldsValue({
					question: res?.data[0]?.question_text,
					question_type: Number(res?.data[0]?.question_type),
				});
				setCourseOptions(Number(res?.data[0]?.question_type)===2?true:false);
				setMandatoryQuestion(Number(res?.data[0]?.is_mandatory));
			}
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
	const addOrEditQuestions=(values)=>{
		setLoading(true);
		let payload = null;
		let url=``;
		payload={
			question_type: values?.question_type,
			question_text: removeExtraSpaces(values?.question),
			is_mandatory: surveyDetails?.surveytype === 'Instructor'&&questionTypeValue===3 ? 0 : mandatoryQuestion?1:0,
			survey_id: surveyDetails?.record?.id,
			survey_type: surveyDetails?.record?.survey_type,
		}
		if(type===2){
			const editedOptions = questionOptions.filter(option => option.dragged && option.option_id);
			const localOptions = questionOptions.filter(option => option.edited);
			payload.id=sessionStorage.getItem('qId');
			if(deletedIds.length>0){
				payload.delete_options=deletedIds;
			}
			if(questionOptions.length>0){
				payload.edited_option=(values?.question_type === 1 && surveyDetails?.surveytype === 'Courses') || (values?.question_type === 2) ? [] : editedOptions;
			}
			if(localOptions.length>0){
				payload.option=(values?.question_type === 1 && surveyDetails?.surveytype === 'Courses') || (values?.question_type === 2) ? [] : localOptions;
			}
			url=`/edit-questions`;
		}else if(type===1){
			url=`/add-questions`;
			if(surveyDetails?.surveytype!=="Instructor"&&surveyDetails?.surveytype!=="Courses"){
				payload.option = questionOptions;
			}else{
				payload.option = [];
			}
		}
		const allOptionsEmpty = questionOptions.every(option => option.option_text === '');
		if(((surveyDetails?.record?.survey_type!=="3"&&surveyDetails?.record?.survey_type!=="2")&&
			allOptionsEmpty&&values?.question_type === 1)){
			setLoading(false);
			message.error(constants.OptionMandatory)
		}else{
			fetchApi(url, 'POST',payload).then((res) => {
					if(res?.code === '200'){
						setLoading(false);
						message.success(res?.message);
						getAllQuestions();
					}else{
						setLoading(false);
						message.error(res?.message);
					}
					if(type===1){
						if(continueSave){
							clearFields();
						}else{
							closeModal(setShowAddQuestionModal);
						}
					}else if(type===2){
						closeModal(setShowEditQuestionModal);
						sessionStorage.removeItem("qid");
					}
					setLoading(false);
			}).catch(() => {
				setLoading(false);
				message.error(constants.ErrorMessage);
			});
		}
	}
	const clearFields=()=>{
		form.setFieldsValue({
			question_type:surveyTitle === 'Instructor' ? 3 : 1,
			question:"",
		});
		setCourseOptions(false);
		setQuestionTypeValue(surveyTitle === 'Instructor' ? 3 : 1);
		setMandatoryQuestion(false);
		setContinueSave(false);
		updateQuestionOptions();
	}
	const closeModalUpdates=()=>{
		if(type===1){
			clearFields();
			setMandatoryQuestion(false);
			setShowAddQuestionModal(false);
		}else if(type===2){
			setShowEditQuestionModal(false);
		}else{
			setShowDeleteModal(false);sessionStorage.removeItem('qId');clearFields()
		}
	}
	const updateQuestionOptions = () =>{
		setQuestionOptions([
			{ option_text: '', showDetailsInput: false, option_detail:'',order_by:1,edited:false},
			{ option_text: '', showDetailsInput: false, option_detail:'',order_by:2,edited:false},
		])
	}
	const handleDeleteOptions = (item,index) =>{
		const updatedQuestions = [...questionOptions];
		if(updatedQuestions?.length<=2){
			message.error("Cannot delete option");
		}else{
			setDeletedIds(prevIds => [...prevIds, Number(item?.option_id)]);
			updatedQuestions.splice(index, 1);
			setQuestionOptions(updatedQuestions);
		}
	}
	const handleTypeChange = (e)=>{
		setQuestionTypeValue(e.target.value)
		if((surveyTitle!=='Instructor'||surveyTitle !== 'Courses')&&questionOptions.length===0){
			updateQuestionOptions();
		}
		setRefresh(!refresh);
		setCourseOptions(((surveyTitle !== 'Courses')||
			 (surveyTitle === 'Courses' && e.target.value === 2) ||
			 (e.target.value === 2)))
	}
	return (
		<Modal
			open={modalVisible}
			onCancel={() => {closeModalUpdates()}}
			title={modalTitle}
			className="!top-5"
			footer={null}
			width={700}
		>
			{(type === 1 || type === 2) ?
			<Form form={form} onFinish={addOrEditQuestions}>
				<Form.Item 
					label={constants.Question} 
					name='question' 
					rules={[{
						required: true, 
						message: 'This field is required',
						whitespace: true,
					}]}
				>
					<TextArea 
						showCount 
						maxLength={1000} 
						rows={4} 
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('question'))}
					/>
				</Form.Item>
				<div className='d-flex justify-content-between !important'>
					<Form.Item label={surveyTitle !== 'Instructor' ? 'Question Type' : ''} name='question_type' rules={constants.requiredFieldRules}>
						<Radio.Group onChange={(e) => {handleTypeChange(e)}}>
							<Radio value={surveyTitle === 'Instructor' ? 3 : 1}>{surveyTitle !== 'Instructor' ? constants.Options : constants.StudentsList}</Radio>
							<Radio value={2}>{surveyTitle !== 'Mentors' ? constants.Comment : constants.Text}</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item label='Mandatory Question' name='mandatoryQuestion' hidden={surveyTitle === 'Instructor'?questionTypeValue!==3?false:true:false}>
						<Checkbox defaultChecked={mandatoryQuestion} checked={mandatoryQuestion} onChange={getMandatoryTypeValue}></Checkbox>
					</Form.Item>
				</div>
				<span style={{ opacity: surveyTitle === 'Instructor' || questionTypeValue === 2 || (surveyTitle === 'Courses' && form.getFieldValue('question_type') === 2) ? 0 : 1 }} className='fs-6 fw-bold'>{form.getFieldValue('question_type') !== 2&&constants.Options}</span>
				<Form.Item hidden={surveyTitle === 'Instructor' || surveyTitle === 'Courses' || form.getFieldValue('question_type') === 2}>
					<div className='mt-4 mb-1'><Button className='btn btn-info no-padding' onClick={handleAddRow}>{constants.AddOption}</Button></div>
				</Form.Item>
				{!courseOptions&&surveyTitle === 'Courses'&&
				<Form.Item className='mt-2'>
					{
						constants.CoursesOptions.map((option) => {
							return <span key={option.option_name} className="py-2 px-3 border border-muted">{option.option_name}</span>
						})
					}
				</Form.Item>}
				<Form.Item name='optionValues' hidden={surveyTitle === 'Instructor' || surveyTitle === 'Courses' || form.getFieldValue('question_type') === 2}>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="droppable" direction="vertical">
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{questionOptions.map((item, index) => (
										<Draggable key={index?.toString()} draggableId={index?.toString()} index={index}>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={{
														...provided.draggableProps.style,
														marginTop: '3px'
														// border: '1px solid #ccc',
														// padding:'6px',
													}}
												>
													<Row>
														<Col span={24} className='flex'>
														<DragOutlined className='text-sm mx-1' />
															<Input
																placeholder="Option Text"
																value={item?.option_text}
																onChange={(e) => {
																	handleOptionEdit(index,"option_text",e.target.value);
																}}
																className='w-50 me-2 rounded-0'
															/>
														{item?.showDetailsInput && (
															<Input
																placeholder="Details"
																value={item?.option_detail}
																onChange={(e) => {
																	handleOptionEdit(index,"option_detail",e.target.value);
																}}
																className='w-50 rounded-0 me-1'
															/>
														)}
														<Checkbox
															className='ms-2 optionCheckbox'
															onChange={(e) => {
																handleOptionEdit(index,"showDetailsInput",e.target.checked);
															}}
															checked={item?.showDetailsInput}
														>
															{!item?.showDetailsInput ? 'If this option selected' : null}
														</Checkbox>
														<CloseOutlined
														className='fs-6 text-danger' 
														onClick={() => {handleDeleteOptions(item,index)}} />
														</Col>
													</Row>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</Form.Item>
				<div className='flex justify-end'>
					<Button type="primary" className="text-sm mb-3" onClick={() => closeModalUpdates()} danger>{constants.Cancel}</Button>
					<Button disabled={loading} type='primary' className='ms-2 text-sm mb-3' htmlType='submit'onClick={()=>setContinueSave(false)}>{constants.Save}</Button>
					{
						type === 1 ?
							<Button disabled={loading} className='bgGreen500 ms-2 text-sm mb-3' htmlType='submit'onClick={()=>setContinueSave(true)}>{constants.SaveAndContinue}</Button>
							:
							null
					}
				</div>
			</Form>
			:
			<div>
				<div className="text-sm font-bold">{constants.DeleteQuestionConfirmationMessage}</div>
				<div className="flex justify-end">
					<Button type='primary' className='text-sm mb-3' onClick={() => {closeModalUpdates()}} danger>{constants.Cancel}</Button>
					<Button type='primary' className='ms-2 mb-3' onClick={()=>deleteQuestion(sessionStorage.getItem('qId'))}>{constants.Delete}</Button>
				</div>
			</div>}
		</Modal>

	)
}
export default QuestionContentModal
