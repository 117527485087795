import React, { useState, useEffect } from 'react'
import { fetchApi, fetchStaffApi } from '../../Services/Api'
import { COOKIE, createCookie, deleteCookie, getCookie } from '../../Services/Cookie'
import constants from '../../Constants/constants'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import { performSearch, HighlightedText, CustomSortIcon, showTotalItems, getNextAndPreviousButtons, handleRowSelect, filterSelectOption } from '../../GlobalFunctions/GlobalFunctions'
import { Card, Input, Table, Button, Select, Space, Checkbox} from 'antd'
import { useNavigate } from 'react-router'

const { Option } = Select;


function StudentCourseList() {
	const breadcrumbItems = [{ label: 'Student Course List', active: true },]

	const [filterTableData, setfilterTableData] = useState([]);
	const [dataSource, setdataSource] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [type, setType] = useState(null)
	const [year, setYear] = useState(null)
	const [status, setStatus] = useState(null)
	const [selectedRow, setSelectedRow] = useState([])
	const [searchValue, setSearchValue] = useState("");
	const [check,setCheck]=useState(false)
	const [rowData,setRowData] = useState([])
	const [rowKeys,setRowKeys] = useState([])
	const [semesterData, setSemesterData] = useState([]);
	const [selectedSemesterId,setSelectedSemesterId]=useState("0");
	const [refresh, setRefresh] = useState(false);
	const navigate = useNavigate();
	const facultyRole = getCookie(COOKIE.Role)

	const rowSelection = {
		type: 'checkbox',
		selectedRowKeys:selectedRowKeys,
		columnWidth: '5%',
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? dataSource:[]
			onSelectChange(
			data?.map((item) => item.student_id),
			data,
			null,
			null,
			true
		)},
		onSelect: (record, selected, selectedRows) =>{
			const isSelected = selectedRowKeys.includes(record.student_id);
			const newSelectedRowKeys = isSelected
				? selectedRowKeys.filter(key => key !== record.student_id)
				: [...selectedRowKeys, record.student_id];
			
			const newSelectedRowData = filterTableData.filter(data => newSelectedRowKeys.includes(data.student_id));

			onSelectChange(
				newSelectedRowKeys, // Update with new selected row keys
				newSelectedRowData, // Update with new selected row data
				record, // Current selected record
				filterTableData.findIndex(item => item.student_id === record.student_id) // Index of the current record
			);
		}
	};
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
			// rowData.length===facultyList.length?[]:facultyList
		)
		setSelectedRow(selectedRowData)
		setSelectedRowKeys(newSelectedRowKeys)
  };

	useEffect(() => {
		// Fetch data from API
		getSemesterData();
		deleteCookie(COOKIE.FacultyIdFromEdit, "/");
	}, []);
	const generateSelectDropdown = (data) => {
		let filteredData = data?.map((item) => {
			const semesterObj = constants.StudentSemList?.find((sem) => sem.key === item.semester_type)
			return {
				...item,
				semester: item?.semester_is_ended === '0' ? `Current(${semesterObj?.value})` : semesterObj?.value
			}
		}).sort((a, b) => { return a.semester_is_ended - b.semester_is_ended })
		return filteredData
	}
	const getSemesterData = () => {
		fetchApi('/semester-data', 'get').then((res) => {
			if (res?.code === "200"&&res?.data?.length>0){
				setSemesterData(generateSelectDropdown(res.data));
				setRefresh(!refresh);
				getFacultyStudents(year, type, status, check,"0");
			}else{
				setSemesterData([]);
			}
		});
	}
	const getFacultyStudents = (year, type, status, check, sem) => {
		setdataSource([]);
		const payload = {
			year: year? year:"0",
			type: type? type:"0",
			status: status?status:"0",
			faculty_id: getCookie(COOKIE.UserId),
			show_all: check === false ? "0" : "2",
			semester:sem,
		}

		fetchStaffApi('/display-faculty-students', 'POST', payload).then((res) => {

			if (res?.code === "200") {
				// const Data = res?.data
				setdataSource(res?.data)
				setfilterTableData(res?.data)
			} else {
				
				setdataSource([])
				setfilterTableData([])
			}
		});
	}
	const displayData = (field) => {
		return <HighlightedText text={field || "-"} highlight={searchValue} />
	}
	const columns = [
		{
			title: 'Name',
			width:"35%",
			sorter: (a, b) => a?.student.localeCompare(b?.student),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_,record) => <span onDoubleClick={()=>viewSemCourses(record)}>{displayData(record?.student || '')}</span>
		},
		{
			title: 'Year in Program',
			width:"25%",
			sorter: (a, b) => a?.year_in_program_text.localeCompare(b?.year_in_program_text),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_,record) => displayData(record?.year_in_program_text || '')
		},
		{
			title: 'Student Type',
			width:"20%",
			sorter: (a, b) => a?.student_type_value.localeCompare(b?.student_type_value),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_,record) => displayData(record?.student_type_value || '')
		},
		{
			title: 'Advisor',
			width:"20%",
			sorter: (a, b) => a?.faculty.localeCompare(b?.faculty),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_,record) => displayData(record?.faculty || '')
		},
	]
	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search)
		const searchFieldNames = [
			"student", "faculty", "year_in_program_text", "student_type_value"
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setdataSource(searchedValue);
	}
	const handleTypeChange = (value) => {
		value === 2? setYear(null): setYear(year)

		getFacultyStudents(year, value, status,check,selectedSemesterId)
		setType(value)
	}
	const handleYearChange = (value) => {
		getFacultyStudents(value, type, status,check,selectedSemesterId)
		setYear(value);
	}
	const handleStatusChange = (value) => {
		getFacultyStudents(year, type, value,check,selectedSemesterId)
		setStatus(value)
	}
	const viewSemCourses = (record) => {
		createCookie("facultyId", record?record.faculty_id:selectedRow?.[0]?.faculty_id, null, "/");
    navigate(`/student-information`, {
      state: {
        student_id: record?record.student_id:selectedRow?.[0]?.student_id,
        tabkey: "courses",
				from: "Student Course List",
				from_route: "/student-course-list",
				tab_title: constants.Courses,
        student_name: record?record.student:selectedRow?.[0]?.student,
        faculty_id: record?record.faculty_id:selectedRow?.[0]?.faculty_id,
				year_in_program: record?record.year_in_program:selectedRow?.[0]?.year_in_program,
				sem_id: record?record.sem_id:selectedRow?.[0]?.sem_id,
				current_sem: record?record.current_semeter:selectedRow?.[0]?.current_semeter
      }
    });
  };
	const sendEmail = () => {
		navigate("/send-email", {
			state: {
				selectedRow
			}
		});
	};
	const sendNotification = () => {
		navigate("/notification-add", {
			state: {
				selectedRow,studentCourseList:true
			}
		});
	};
	const handleSelectedSemesterId=(value)=>{
		setSelectedSemesterId(value);
		getFacultyStudents(year, type, status, check,value);
	}
	return (
		<div>
			<BreadcrumbHeader pageTitle="Student Course List" breadcrumbItems={breadcrumbItems} />

			<Card className="container-border-custom">
				<div className="d-flex justify-content-between">
					<Space className='mb-2' wrap>
						<Select allowClear className='selectCompWidth150' popupClassName="select-dropdown" placeholder={constants.SelectType} 
							onChange={handleTypeChange}
							showSearch
							optionFilterProp="children"
							filterOption={filterSelectOption}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							{constants.StudentTypeList.map((record) =>
								<Option value={record.value} key={record.value}>{record.label}</Option>
							)}
						</Select>
						<Select value={year} disabled={type === '2'} allowClear className='selectCompWidth' popupClassName="select-dropdown" placeholder={constants.SelectYear} 
							onChange={handleYearChange}
							showSearch
							optionFilterProp="children"
							filterOption={filterSelectOption}
						>
							{constants.YearList.map(option => (
								<Option key={option.value} value={option.value} >{option.label}</Option>
							))}
						</Select>

						<Select allowClear className='selectCompWidth' popupClassName="select-dropdown" placeholder={constants.SelectStatus} 
							onChange={handleStatusChange}
							showSearch
							optionFilterProp="children"
							filterOption={filterSelectOption}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
						>
							{constants.StatusOptions.map(option => (
								<Option key={option.id} value={option.id} >{option.name}</Option>
							))}
						</Select>
						{semesterData?.length>1?<Select placeholder='Semester' maxTagCount='responsive' onChange={handleSelectedSemesterId}defaultValue={selectedSemesterId}>
							{semesterData?.map((sem) => (
								<Select.Option 
									key={sem.semester} 
									value={sem.semester_is_ended
								}
								>
									{sem.semester}
								</Select.Option>
							))}
						</Select>:<div></div>}
					</Space>

					{facultyRole === 3 && (
						<Checkbox className='checkboxMargin' onChange={(e) => {
							setCheck(e.target.checked)
							getFacultyStudents(year, type, status,e.target.checked,selectedSemesterId);
						}}>
							{constants.ShowAllStudents}</Checkbox>
					)}
				</div>

				<div className="flex flex-wrap justify-between items-center">
					<Space size={5} wrap>
						<Button className=" text-capitalize btn-info"
							onClick={()=>viewSemCourses(null)}
							disabled={selectedRowKeys?.length === 0 || selectedRowKeys?.length > 1}>Course</Button>
						<Button className="text-capitalize btn-info"
							disabled={selectedRowKeys?.length === 0}
							onClick={sendEmail}>Email</Button>
						{facultyRole !== 2  && (
							<Button className="text-capitalize btn-info"
								disabled={selectedRowKeys?.length === 0}
								// hidden={facultyRole===2} 
								onClick={sendNotification}>Notification</Button>
						)}
						
					</Space>
					<Input className="mt-2 lg:mt-0 w-48" placeholder="Search" onChange={onSearch} />
				</div>


				<div className="mt-2">
					<Table rowSelection={rowSelection}
						columns={columns}
						dataSource={dataSource}
						rowKey={(record) => record.student_id}
						bordered size="small"
						className="records-table data_table" rowSelectedBg='#e6f4ff'
						pagination={{
							className:"!mb-0",
							// pageSize: constants.TablePageSize,
							pageSize: 10,
							showTotal: (total, range) => showTotalItems(total, range, dataSource),
							itemRender: getNextAndPreviousButtons,
						}}
						scroll={{ x: 768, y: 'calc(100vh - 320px)' }}
					  onRow={(record, rowIndex) => ({
							onClick: () =>{
								const isSelected = selectedRowKeys.includes(record.student_id);
								const newSelectedRowKeys = isSelected
									? selectedRowKeys.filter(key => key !== record.student_id)
									: [...selectedRowKeys, record.student_id];
								
								const newSelectedRowData = filterTableData.filter(data => newSelectedRowKeys.includes(data.student_id));
					
								onSelectChange(
									newSelectedRowKeys, // Update with new selected row keys
									newSelectedRowData, // Update with new selected row data
									record, // Current selected record
									filterTableData.findIndex(item => item.student_id === record.student_id) // Index of the current record
								);
							},
						})}
					/>
				</div>
			</Card>
		</div>
	)
}

export default StudentCourseList