import React, { useEffect, useState } from "react";
import constants from "../../Constants/constants";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { Button, Card, Col, Input, Modal, Row, Space, Spin, Table, message, Form } from "antd";
import { CustomSortIcon, HighlightedText, getNextAndPreviousButtons, performSearch, showTotalItems, HighlightedHTML, removeExtraSpaces } from "../../GlobalFunctions/GlobalFunctions";
import { fetchApi } from "../../Services/Api";
import { COOKIE, getCookie } from "../../Services/Cookie";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const StudentNotes = () => {
	const [form] = Form.useForm();
	const pageTitle = constants.Notes;
	const breadcrumbItems = [{ label: constants.Notes, active: true }];
	const studentID = getCookie(COOKIE.UserId);
	const [listData, setListData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [rowData, setRowData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const showModal = () => {
		form.setFieldsValue({
			advisor: rowData?.faculty_name || "",
			note: rowData?.note.replace(/<[^>]+>|&nbsp;/g, "") || ""
		})
		setIsModalOpen(true);
	};
	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
	};

	useEffect(() => {
		studentNotesList();
	}, []);
	const studentNotesList = () => {
		setLoading(true);
		fetchApi(`/student/get-note-for-single-student/${studentID}`, "GET").then(
			(res) => {
				if (res?.code === "200") {
					setListData(res.data)
					setFilteredData(res.data)
					setLoading(false);
				} else {
					setListData([])
					setFilteredData([])
					setLoading(false);
				}
			}
		);
	};

	const StudentNotesCols = [
		{
			title: `${constants.Note}`,
			dataIndex: "note",
			key: "note",
			sorter: {
				compare: (a, b) => {
					const noteA = a.note.replace(/<[^>]+>|&nbsp;/g, "");
					const noteB = b.note.replace(/<[^>]+>|&nbsp;/g, "");
					return noteA.localeCompare(noteB);
				},
			},
			render: (note) => {
				// Remove HTML tags from the note string
				// const noteWithoutTags = note.replace(/<[^>]+>|&nbsp;/g, "");
				return <HighlightedHTML html={removeExtraSpaces(note)} highlight={searchValue} />
				// <HighlightedText text={noteWithoutTags} highlight={searchValue} />
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: `${constants.ESign}`,
			dataIndex: "faculty_name",
			key: "faculty_name",
			sorter: {
				compare: (a, b) => a.faculty_name - b.faculty_name,
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (faculty_name) => <HighlightedText text={faculty_name} highlight={searchValue} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: `${constants.DateUpdated}`,
			dataIndex: "faculty_sign_dt",
			key: "faculty_sign_dt",
			sorter: {
				compare: (a, b) =>
					dayjs(a.faculty_sign_dt).isBefore(dayjs(b.faculty_sign_dt)) ? -1 : 1,
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (date) => <HighlightedText text={dayjs(date).format(constants.DateFormat)} highlight={searchValue} />,
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: `${constants.Esign}`,
			dataIndex: "student_sign",
			key: "student_sign",
			width: "6%",
			render: (student_sign) =>
				student_sign ? (
					<CheckOutlined className="flex-center success-icon faculty-form-item" />
				) : (
					<CloseOutlined className="flex-center error-icon faculty-form-item" />
				),
		},
		{
			title: `${constants.DateSigned}`,
			dataIndex: "student_sign_dt",
			key: "student_sign_dt",
			sorter: {
				compare: (a, b) =>
					dayjs(a.student_sign_dt).isBefore(dayjs(b.student_sign_dt)) ? -1 : 1,
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (date) => (
				date ? (
					<HighlightedText
						text={dayjs(date).format(constants.DateFormat)}
						highlight={searchValue}
					/>
				) : (
					"-"
				)
			),
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
	];

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			if (selectedRowKeys.length > 1) {
				selectedRowKeys = [selectedRowKeys[selectedRowKeys.length - 1]];
				selectedRows = selectedRows.slice(-1);
			}
			setSelectedRowKeys(selectedRowKeys);
			setRowData(selectedRows[0]);
		},
		selectedRowKeys: selectedRowKeys
	};

	const onRowClick = (record) => {
		const key = record.id;
		let newSelectedRowKeys = [...selectedRowKeys];

		if (newSelectedRowKeys.includes(key)) {
			newSelectedRowKeys = [];
		} else {
			newSelectedRowKeys = [key];
		}

		setSelectedRowKeys(newSelectedRowKeys);
		setRowData(newSelectedRowKeys.length > 0 ? record : null);
	};

	const onSearch = (event) => {
		const search = event.target.value;
		const fieldNames = [
			"note",
			"faculty_name",
			"faculty_sign_dt",
			"student_sign_dt",
		];
		const searchedValue = performSearch(search, filteredData, fieldNames);
		setListData(searchedValue);
		setSearchValue(search);
	};

	const handleSaveRecord = (value) => {
		const payload = {
			id: rowData.id,
			student_sign: value?.sign,
			notification_id: rowData.notification_id,
			student_id: studentID,
		};
		fetchApi("/student/add-student-note-esign", "POST", payload)
			.then((res) => {
				if (res.code === "200") {
					studentNotesList();
					setIsModalOpen(false);
					setSelectedRowKeys([]);
					setRowData(null);
					form.resetFields();
					message.success(constants.SignSuccessful);
				} else {
					message.error(constants.ErrorMessage)
					setIsModalOpen(false);
				}
			})
			.catch(() => {
				message.error(constants.ErrorMessage)
			});
	};

	return (
		<>
			<BreadcrumbHeader
				pageTitle={pageTitle}
				breadcrumbItems={breadcrumbItems}
			/>
			<div>
				{loading && (
					<div className="res-loader flex-center">
						<Spin tip={constants.PleaseWait} />
					</div>
				)}
				<Card className="container-border-custom">
					<div className="d-flex justify-content-between">
						<Space wrap>
							<Button
								className="text-capitalize btn-info"
								onClick={showModal}
								disabled={rowData === null}
							>
								{constants.View}
							</Button>
						</Space>
						<Input
							className="w-48"
							placeholder={constants.Search}
							onChange={onSearch}
							value={searchValue}
						/>
					</div>
					<div className="mt-2">
						<Table
							className="records-table overflow-auto data_table"
							rowSelectedBg="#e6f4ff"
							columns={StudentNotesCols}
							dataSource={listData}
							rowKey={(record) => record.id}
							bordered
							size="small"
							rowSelection={{
								type: "checkbox",
								...rowSelection,
								hideSelectAll: true,
							}}
							scroll={{ x: 768, y: 'calc(100vh - 350px)' }}
							pagination={{
								pageSize: constants.TablePageSize,
								showTotal: (total, range) =>
									showTotalItems(total, range, listData),
								itemRender: getNextAndPreviousButtons,
							}}
							searchableProps={{ fuzzySearch: true }}
							onRow={(record) => ({
								onClick: () => onRowClick(record),
							})}
						/>
					</div>
				</Card>
				<Modal
					title="Student Notes"
					className="records-popup"
					footer={null}
					open={isModalOpen}
					onCancel={handleCancel}
				>
					<Form
						layout="vertical"
						form={form}
						onFinish={handleSaveRecord}
					>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item name="advisor" label={constants.Advisor}>
									<Input readOnly />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item name="note" label={constants.Note}>
									<TextArea readOnly />
								</Form.Item>
							</Col>
							<Col span={12}>
								<label className="mb-2">
									{constants.AdvisorESign}
								</label>
								<div>
									<div className="h-10 flex justify-center items-center text-3xl font-semibold bg-gray-200 sign">
										{rowData?.faculty_sign || ""}
									</div>
									<div className="mb-2">
										{constants.SignedOn}{" "}
										{dayjs(rowData?.faculty_sign_dt).format(
											constants.DateFormat
										) || ""}
									</div>
								</div>
							</Col>
							{rowData?.student_sign &&
								rowData?.student_sign_dt ?
								<Col span={12}>
									<label className="mb-2">
										{constants.StudentEsign}
									</label>
									<div>
										<div className="h-10 flex justify-center items-center text-3xl font-semibold bg-gray-200 sign">
											{rowData?.student_sign || ""}
										</div>
										<div className="mb-2" >
											{constants.SignedOn}{" "}
											{dayjs(rowData?.student_sign_dt).format(
												constants.DateFormat
											) || ""}
										</div>
									</div>
								</Col>
								:
								<>
									<Col span={12}>
										<Form.Item
											name="sign"
											label={constants.StudentEsign}
											className="!mb-0"
											rules={[
												{
													required: true,
													message: constants.SignatureRequired,
												},
											]}
										>
											<TextArea maxLength={25} autoSize={{ maxRows: 1 }} placeholder={constants.StudentEsign} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<div className="text-right">
											<Form.Item>
												<Button
													type="primary"
													className="action-btn"
													htmlType="submit"
												>
													{constants.Save}
												</Button>
											</Form.Item>
										</div>
									</Col>
								</>
							}
						</Row>
					</Form>
				</Modal>
			</div>
		</>
	);
};

export default StudentNotes;
