import React, { useState, useEffect, useRef } from 'react';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import constants from '../../../Constants/constants';
import {Form, Card, Select, Button, Tabs, Table, Input, message, Space} from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { showTotalItems, getNextAndPreviousButtons, CustomSortIcon, getModal, closeModal, HighlightedText, } from '../../../GlobalFunctions/GlobalFunctions';
import { fetchStaffApi, fetchApi } from '../../../Services/Api';
import dayjs from 'dayjs';

const SurveyReport = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchText, setSearchText] = useState('');
	const [commonSearchText, setCommonSearchText] = useState('');
	const inputRef = useRef(null);
	const [tabId, setTabId] = useState('1');
	const [allSurveys,setAllSurveys] = useState([]);
	const [filteredSurveys, setAllFilteredSurveys] = useState([]);
	const [semesterData,setSemesterData] = useState([]);
	const [surveyId, setSurveyId] = useState(location?.state?.selectedRecord?.id);
	const [showResendModal,setShowResendModal] = useState(false);
	const [reportsData,setReportsData]=useState([]);
	const [filteredReportsData,setFilteredReportsData]=useState([]);
	const [selectedRecord,setSelectedRecord]=useState(location?.state?.selectedRecord);
	const [sections,setSections]=useState([]);
	const [selectedSection,setSelectedSection]=useState(null);
	const [selectedSemesterId,setSelectedSemesterId]=useState(null);
	const [refresh,setRefresh]=useState(false);
	const getFilteredText = (e)=>{
		const value = e.target.value;
		setCommonSearchText(value);
		if (value) {
			let tempData = (reportsData).filter((record) => {
				let resendDate = record?.resend_dt?dayjs(record?.resend_dt).format(constants.DateFormat):"";
				let sentDate = (tabId === '1' || tabId === '2')?record?.dt_created? dayjs(record?.dt_created).format(constants.DateFormat):"" :record?.dt_updated? dayjs(record?.dt_updated).format(constants.DateFormat):"";
				return (
					record?.name?.toLowerCase().includes(value.toLowerCase()) ||
					record?.year_or_role?.toLowerCase().includes(value.toLowerCase())  ||
					sentDate.toLowerCase().includes(value.toLowerCase()) ||
					resendDate.toLowerCase().includes(value.toLowerCase())
				);
			});
			setFilteredReportsData(tempData);
		} else {
			setFilteredReportsData(reportsData);
		}
	}
	const getFilteredData = (e) => {
		const value = (e.target.value).trim();
		setSearchText(value);
		if (value) {
			let tempData = allSurveys.filter((record) => {
				let surveyName = record?.survey_name || '';
				return (surveyName.toLowerCase().includes(value.toLowerCase()));
			});
			setAllFilteredSurveys(tempData);
		} else {
			setAllFilteredSurveys(allSurveys);
		}
	}
	const breadcrumbItems = [
		{
			label: constants.ManageSurveys,
			href: '/manage-surveys',
			active: false
		},
		{
			label: constants.SurveysReport,
			active: true
		}
	];
	const handleChangeSection = (value) =>{
		setSelectedSection(value);
		getSelectedSurveyReport(tabId,selectedRecord,value,selectedSemesterId);//ORDER OF PARAMS TABID, SURVEYID, SECTION,SEMESTERID
	}
	useEffect(()=>{
		getAllSurveys();
		getSemesterDetails();
		getSelectedSurveyReport(tabId,selectedRecord);
	},[])
	const getSemesterDetails = () => {
		fetchApi(`/semester-data`, 'GET').then((res) => {
			if(res?.data){
				setSemesterData(generateSelectDropdown(res.data));
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const generateSelectDropdown = (data) => {
		let filteredData = data?.map((item) => {
			const semesterObj = constants.StudentSemList?.find((sem) => sem.key === item.semester_type)
			return {
				...item,
				semester: item?.semester_is_ended === '0' ? `Current(${semesterObj?.value})` : semesterObj?.value
			}
		}).sort((a, b) => { return a.semester_is_ended - b.semester_is_ended })
		return filteredData
	}
	const getAllSurveys = () => {
		let payload = {
			is_hidden: 0 ,
			type: location?.state?.selectedRecord?.surveytype,
		}
		fetchStaffApi(`/get-all-surveys`, 'POST', payload).then((res) => {
			if (res?.data) {
				const surveysWithIndex = res?.data.map((item, index) => ({ ...item, index }));
				setAllSurveys(surveysWithIndex);
				setAllFilteredSurveys(surveysWithIndex);
			} else {
				setAllSurveys([]);
				setAllFilteredSurveys([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const getSelectedSurveyReport = (tabId,selectedRecord,selectedSection,selectedSemesterId)=>{
		let payload = {
			survey_id:selectedRecord?.id,
		};
		if(selectedRecord?.course_id&&selectedRecord?.survey_type==="2"){
			payload.course_id=selectedRecord?.course_id;
		}
		if(tabId==="1"){
			payload.resend=0;
		}else if(tabId==="2"){
			payload.resend=1;
		}
		if(selectedSemesterId&&semesterData.length>1){
			payload.semester=selectedSemesterId;
		}
		if(selectedSection){
			payload.section_no=selectedSection;
		}
		fetchStaffApi('/get-survey-report', 'POST', payload).then((res) => {
			if(res?.data){
				setReportsData(res.data?.report);
				setFilteredReportsData(res.data?.report);
				let options = [];
				if(res?.data?.sections?.length===0) {
					options?.push(...constants?.extraSections)
				}else{
					options = res?.data?.sections?.map((item) => {
						return {
							id: item,
							name: item,
						}
					})
					options?.push(...constants?.extraSections)
				}
				setSections(options);
			}else{
				setReportsData([]);
				setFilteredReportsData([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const tabColumnsOne = [
		{
			title: constants.Name,
			key:'1',
			sorter: (a, b) => {return a?.name?.localeCompare(b?.name)},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: { placement: 'bottom' },
			render:(record)=>{
				return (<HighlightedText text={record?.name} highlight={commonSearchText} />);
			},
		},
		{
			title: constants.ResourceRole,
			key: '2',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => {return a?.year_or_role?.localeCompare(b?.year_or_role)},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (record) => {
				return (<HighlightedText text={record?.year_or_role} highlight={commonSearchText} />);
			},
		},
		{
			title: tabId === "1"?constants.SentDate:'Answered Date',
			key: '3',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => dayjs(tabId === "1" ? a?.dt_created : a?.dt_updated) - dayjs(tabId === "1" ? b?.dt_created : b?.dt_updated),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (record) => {
				const sentDate = tabId === '1' ? dayjs(record?.dt_created).format(constants.DateFormat) : dayjs(record?.dt_updated).format(constants.DateFormat);
				return <HighlightedText text={sentDate} highlight={commonSearchText} />
			},
		}
	]
	const tabColumnsTwo = [
		{
			title: constants.Name,
			key: '1',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => {return a?.name?.localeCompare(b?.name)},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (record) => {
				return (<HighlightedText text={record?.name} highlight={commonSearchText} />);
			},
		},
		{
			title: constants.ResourceRole,
			key: '2',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => {
				return a?.year_or_role.localeCompare(b?.year_or_role)
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (record) => {
				return (<HighlightedText text={record?.year_or_role} highlight={commonSearchText} />);
			},
		},
		{
			title:constants.ReSentOn,
			key: '3',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => dayjs(a?.resend_dt) - dayjs(b?.resend_dt),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
				render: (record) => {
				return <HighlightedText text={dayjs(record?.resend_dt).format(constants.DateFormat)} highlight={commonSearchText} />
			},
		},
		{
			title: constants.SentDate,
			key: '4',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => dayjs(a?.dt_created) - dayjs(b?.dt_created),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (record) => {
				return<HighlightedText text={dayjs(record?.dt_created).format(constants.DateFormat)} highlight={commonSearchText} />
			},
		}
	] 
	const getTabId = (activeKey) => {
		clearVariables();
		setTabId(activeKey);
		getSelectedSurveyReport(activeKey,selectedRecord,selectedSection,selectedSemesterId);
	}
	const redirectToManageSurveys = ()=>{
		navigate(-1);
	}
	const clearVariables = () =>{
		setReportsData([]);
		setFilteredReportsData([]);
		setSections([]);
	}
	const resendSurveys = ()=>{
		let payload;
		payload={
			survey_id: surveyId,
			resend:0,
		}
		fetchStaffApi(`/resend-pending-survey`, 'POST', payload).then((res) => {
			if(res?.code === '200'){
				message.success(res?.message);
				if (showResendModal){
					closeModal(setShowResendModal);
				}
				getSelectedSurveyReport(tabId,selectedRecord,selectedSection,selectedSemesterId);
			}else{
				message.error(res?.message);
				if (showResendModal) {
					closeModal(setShowResendModal);
				}
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const resendModalContent = () => {
		return <>
			<div className='text-sm mb-2 font-bold'>{constants.ResendModalText}</div>
			<div className="flex justify-end">
				<Button type='primary' className='mb-3' danger onClick={()=>closeModal(setShowResendModal)}>Cancel</Button>
				<Button type='primary' className='mb-3 ms-2' onClick={resendSurveys}>Continue</Button>
			</div>
		</>
	}
	const tableLayout = () => {
		return (
			<div>
				<div>
				{filteredReportsData.length > 0 && tabId === '1' ?
					<Button
						type='primary'
						className='mb-1'
						onClick={location?.state?.selectedRecord?.is_active === '1' ? resendSurveys : ()=>setShowResendModal(true)}
					>
						Resend
					</Button>:null}
					<Table
						rowKey={(record) => record?.name}
						size='small'
						columns={tabId === '1' || tabId === '3' ? tabColumnsOne : tabColumnsTwo}
						className='records-table data_table recepientsTab'
						dataSource={filteredReportsData}
						bordered
						pagination={{
							pageSize: 20,
							showTotal: (total, range) => showTotalItems(total, range,reportsData),
							itemRender: getNextAndPreviousButtons,
						}}
					>
					</Table>
				</div>
			</div>
		)
	} 
	const getSurveyValue =(value,record)=>{
		setSelectedRecord(record?.record);
		setSurveyId(value);
		setRefresh(!refresh);
		getSelectedSurveyReport(tabId,record?.record,selectedSection,selectedSemesterId);
	}
	const clearKeys = ()=>{}
	const handleSelectedSemesterId=(value)=>{
		setSelectedSemesterId(value);
		getSelectedSurveyReport(tabId,selectedRecord,selectedSection,value);
	}
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.SurveysReport} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom">
				<Form form={form} layout='vertical'>
					<div className="flex flex-wrap justify-between items-end mb-3">
						<Space wrap className='!items-end'>
							<Form.Item
								label={constants.Survey}
								name='survey_type'
								className='!mb-0'
							>
								<Select 
									placeholder='Type' 
									onChange={getSurveyValue} 
									defaultValue={surveyId}
									className='w-48'
									dropdownRender={(menu) => (
										<div className=''>
											<Input
											  className='w-48'
												placeholder="Search"
												ref={inputRef}
												value={searchText}
												onChange={getFilteredData}
												onKeyDown={(e) => e.stopPropagation()}
											>
											</Input>
											{menu}
										</div>
									)}
									>
									{filteredSurveys && filteredSurveys.map((option,index) => (
										<Select.Option
											key={index}
											className={option?.is_active==="1"?"!bg-blue-200":""}
											value={option?.id}
											record={option}
										>
											{option?.survey_name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>				
							{selectedRecord?.survey_type === '2'&&
								<Form.Item
									label={constants.Section}
									name='section'
									className='w-48 !mb-0'
								>
									<Select maxTagCount='responsive' className='w-48' placeholder={constants.Section} defaultValue={selectedSection} onChange={handleChangeSection}>
										{sections?.map((option) => (
											<Select.Option key={option.id} value={option.id}>
											{option.name}
										</Select.Option>
									))}
									</Select>
								</Form.Item>
							}
							{semesterData?.length>1?
								<Form.Item label={constants.Semester} className='!mb-0'>
									<Select placeholder='Type' className='w-48' maxTagCount='responsive' onChange={handleSelectedSemesterId}>
									{semesterData?.map((sem) => (
										<Select.Option 
											key={sem.semester} 
											value={sem.semester_is_ended}
										>
											{sem.semester}
										</Select.Option>
									))}
									</Select>
								</Form.Item>
							:null}
							<Button className="text-sm bg-white backButton" onClick={redirectToManageSurveys}>{constants.Back}</Button>
						</Space>
						<Form.Item className='!mb-0'><Input type='search' placeholder={constants.Search} onChange={getFilteredText} value={commonSearchText} className='w-48'></Input></Form.Item>
					</div>
				</Form>
				<Tabs
					bordered
					type="card"
					size='small'
					activeKey={tabId}
					className='newTab'
					onChange={getTabId}
					items={new Array(3).fill(null).map((_, i) => {
						const id = String(i + 1);
						let label = '';
						if (id === '1') {
							label = constants.Pending;
						} else if (id === '2') {
							label = constants.ReSent;
						} else if (id === '3') {
							label = constants.Answered;
						}
						return {
							label: label,
							key: id,
							children: tableLayout(),
						};
					})}
				/>
				{showResendModal && getModal('Confirm', resendModalContent(), showResendModal, closeModal, setShowResendModal, () => clearKeys())}
			</Card>
		</div>
	)
}
export default SurveyReport
