// BreadcrumbHeader.js
import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { COOKIE, getCookie } from '../../Services/Cookie';

const BreadcrumbHeader = ({ pageTitle, breadcrumbItems }) => {
  const navigate = useNavigate();
  const passPropsToSemsesterCourses=(item)=>{
    let props= {
      tabkey:"semester-courses",
      tab_title:item?.data?.tab_title,
      isArchived:item?.data?.isArchived,
      reloadTab:false,
      from:item?.data?.from,
      from_route:item?.data?.from_route,
      student_name:item?.data?.student_name,
      student_id:item?.data?.student_id,
      sem_id:item?.data?.student_semester,
      year_in_program:item?.data?.year_in_program,
      student_advisor_id:item?.data?.student_advisor_id,
    }
    if(item?.data?.student_advisor_id){
      props.student_advisor_id=item?.data?.student_advisor_id;
    }
    navigate(item.href,{state:{...props}})
  }
  return (
    <div className="d-flex align-items-lg-center justify-content-between flex-lg-row flex-column mb-3">
      <h4 className="mb-lg-0">{pageTitle}</h4>
      {breadcrumbItems && (
        <Breadcrumb className="current-page-link py-1 ps-3">
          <Breadcrumb.Item key="home" onClick={()=>navigate(getCookie(COOKIE.Role)!=="0" ?"/dashboard":"/student-dashboard")}>
            Home
          </Breadcrumb.Item>
          {breadcrumbItems.map((item) => (
            <Breadcrumb.Item 
              key={item.label} 
              onClick={()=>item.href?item?.data?passPropsToSemsesterCourses(item):navigate(item.href):false} active={item.active}>
              {item.label}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
};

BreadcrumbHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ),
};

export default BreadcrumbHeader;
