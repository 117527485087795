import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import constants from '../../Constants/constants';
import { fetchApi, fetchStaffApi, fetchStaffDownloadApi, fetchStaffFormData, } from '../../Services/Api';
import { Button, Checkbox, Col, Collapse, DatePicker, Divider, Flex, Form, Row, Select, message, Table, Modal, Input, Space, Radio, Spin, Tooltip } from 'antd';
import { InfoCircleOutlined, UploadOutlined, LoadingOutlined, UserAddOutlined, DownCircleFilled } from '@ant-design/icons';
import { disableSpaceBar, allowAlphabets, beforeProfileUpload, beforeUploadCheckFileType, generateNumberValidator, getBase64, getStatusName, handleKeyPressHandler, getNextAndPreviousButtons, removeExtraSpaces, checkEmptySpace } from '../../GlobalFunctions/GlobalFunctions';
import FileUpload from '../Component/FileUpload';
import dayjs from 'dayjs';
import { COOKIE, getCookie } from '../../Services/Cookie';
import { useFetchStates,useFetchPermaStates } from '../Component/FacultyComponents/FetchStates';
import moment from 'moment/moment';
import { isArray } from 'highcharts';
const { Option } = Select;
const { TextArea } = Input;

let statuses = constants.ShowStatusOptions.map((item) => {
	return {
		...item,
		disabled: false,
	}
})

const EditStudent = () => {
	const errorRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const [studentId,] = useState(location.state?.student_id);
	const [listCountries, setListCountries] = useState([]);
	const [listAdvisors, setListAdvisors] = useState([]);
	const [showPermanentAdress, setShowPermanentAdress] = useState(false);
	const [statusTableData, setStatusTableData] = useState([]);
	const [studentDetails, setStudentDetails] = useState([]);
	const [yearInProgramsList, setYearInProgramsList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [onSaveLoader, setOnSaveLoader] = useState(false);
	const [imageURL, setImageURL] = useState("");
	const [profileFile, setProfileFile] = useState([]);
	const [uploadFileList, setUploadFileList] = useState([]);
	const [isActive, setIsActive] = useState('');
	const [tempIsActiveStatus, setTempIsActiveStatus] = useState('');
	const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
	const [isTransferChecked, setIsTransferChecked] = useState(false);
	const [isOnlineChecked, setIsOnlineChecked] = useState(false);
	const [showTransferInputs, setShowTransferInputs] = useState(false);
	const [currentStatus, setCurrentStatus] = useState("");
	const [tempCurrentStatus, setTempCurrentStatus] = useState("");
	const [showMentorDetails, setShowMentorDetails] = useState(true);
	const [warningPopupType, setWarningPopupType] = useState('');
	const [isStatusWarningModalOpen, setIsStatusWarningModalOpen] = useState(false);
	const [warningStatusMessage, setWarningStatusMessage] = useState("");
	const [mentorDetails,setMentorDetails] = useState([]);
	const [currentStatusList, setCurrentStatusList] = useState(constants.StatusList);
	const [statusList, setStatusList] = useState(statuses);
	const [, setShowTransferDetails] = useState(false);
	const [studentType, setStudentType] = useState('');
	const [isSameSem, setIsSameSem] = useState(false);
	const [colSize, setColSize] = useState(8);
	const [semesterData, setSemesterData] = useState([])
	// const [isOpen, setIsOpen] = useState(false)
	const { permaStates, fetchPermaStates } = useFetchPermaStates();
	const { states, fetchStates } = useFetchStates();
	const facultyRoleId = getCookie(COOKIE.Role);
	const isAdminOrSysAdmin = (facultyRoleId === "1" || facultyRoleId ==="3") ? true : false;

	useEffect(() => {
		getCountries();
		getStudentDetails();
	}, []);

	//get student details
	const getStudentDetails = (isSuccessiveCall = true) => {
		fetchStaffApi(`/get-student-data/${studentId}`, 'get').then((res) => {
			if (res?.code === "200") {
				let record = res?.data?.student_data[0]
				//API CALLS START
				if(isSuccessiveCall) {
					getMentorList(null, record);
					getAdvisorList(record, res?.data?.year_in_program_studied);
					fetchStates(record?.student_current_country);
					fetchPermaStates(record?.student_permanent_country);
					setYearInProgramsList(res?.data?.year_in_program_studied)
				}
				//API CALLS END

				const currentStatus = res?.data?.status[0]?.new_status
				res?.data?.status?.shift() //REMOVE FIRST ELEMENT FROM ARRAY TO HIDE CURRENT STATUS FROM STATUS HISTORY TABLE
				setStatusTableData(res?.data?.status);
				setStudentFormDetails(record, currentStatus);
				setStudentDetails(record);
				setImageURL(record?.student_image);
				setUploadFileList(res?.data?.document);
				//check for permanent adress same as current.
				if (res?.data?.student_data?.[0]?.student_permanent_address === res?.data?.student_data?.[0]?.student_address) {
					setShowPermanentAdress(true);
				} else {
					setShowPermanentAdress(false);
				}
			} else {
				setStudentDetails([]);
				setStatusTableData([]);
				setShowPermanentAdress(false);
			}
		});
	};
	//get all advisor
	const getAdvisorList = (studentRecord, yearInProgData) => {
		fetchStaffApi(`/get-advising-faculty-details`, 'post').then((res) => {
			let data = res?.data?.length > 0 ? res.data : []
			setListAdvisors(data);
			getSemesterData(yearInProgData, studentRecord);
			let id = studentRecord?.faculty_first_name
			let contact = studentRecord?.faculty_mobile_number
			let email = studentRecord?.faculty_university_email
			
			let findIndex = data.findIndex((advisor) => advisor.id === studentRecord?.faculty_id);
			if(findIndex > -1) {
				id = data[findIndex]?.id
				contact = data[findIndex]?.faculty_mobile_number
				email = data[findIndex]?.faculty_university_email
			}
			form.setFieldsValue({
				advisor: id,
				advisor_contact_no: contact,
				advisor_email: email,
			})
		});
	};
	//get all mentors
	const getMentorList = (e, studentRecord) => {
		let { id, mentor_id, semester } = studentRecord
		let sem = e ? e : semester
		let payload = {
			value: 1,
			semester: sem,
			mentor_id: id,
		}
		fetchStaffApi('/get-all-mentors','POST',payload).then((res)=> {
			let data = res?.data?.length > 0 ? res?.data : []
			setMentorDetails(data)
			let id = studentRecord?.mentor_first_name
			let contact = studentRecord?.mentor_mobile_number
			let email = studentRecord?.mentor_university_email

			let findIndex = data?.findIndex((item) => item.id === mentor_id);
			if(findIndex > -1) {
				id = data?.[findIndex]?.id
				contact = data?.[findIndex]?.student_mobile_number
				email = data?.[findIndex]?.student_university_email
			}
			form.setFieldsValue({
				mentor: id,
				mentor_contact_no: contact,
				mentor_email: email,
			})
		});
	}
	//get semester data
	const getSemesterData = (yearInProgData, studentRecord) => {
		const filterCurrentStatusList = (hideActive) => {
			let filtered = []
			if(studentRecord?.student_type === '3')
				filtered = [...currentStatusList]?.filter((item) => item?.value !== '6')
			if(hideActive)
				filtered = [...currentStatusList].filter((it) => it?.value !== '2' && it?.value !== '6' && it?.value !== '9')
			setCurrentStatusList(filtered?.length > 0 ? filtered : currentStatusList)
		}
		const generateSelectDropdown = (data) => {
			let filteredData = data?.map((item) => {
				const semesterObj = constants.StudentSemList?.find((sem) => sem.key === item.semester_type)
				return {
					...item,
					semester: item?.semester_is_ended === '0' ? `Current(${semesterObj?.value})` : semesterObj?.value
				}
			}).sort((a, b) => { return a.semester_is_ended - b.semester_is_ended })
			return filteredData
		}
		const checkDisableActiveRadio = (sameSem) => {
			let returnFlag = false
			if(studentRecord?.is_active === '1') returnFlag = true
			if(studentRecord?.current_status === '4') returnFlag = true
			if(studentRecord?.current_status !== '4' && sameSem) returnFlag = true
			if(returnFlag) {
				filterCurrentStatusList(false)
				return
			} else {
				let radioStatuses = [...statusList].map((item) => {
					return {
						...item,
						disabled: item?.label === constants.Active ? true : false,
					}
				})
				setStatusList(radioStatuses)
				//HIDE SLP MAJOT CURRENT STATUS OPTION IF STUDENT TYPE IS UNDECLARED
				filterCurrentStatusList(true)
			}
		}
		fetchApi('/semester-data', 'get').then((res) => {
			if (res?.code === "200") {
				let data = res?.data
				let getSemesterIds = res?.data?.map((item) => item?.id)
				let isSameSem = getSemesterIds?.includes(yearInProgData[0]?.semester_details_id)
				setIsSameSem(isSameSem)
				setSemesterData(generateSelectDropdown(data));
				checkDisableActiveRadio(isSameSem);
			}
		});
	}
	//setting form details for student.
	const setStudentFormDetails = (student_details, current_status) => {
		const mentorDetailsModalCheck = () => {
			let isTransfer = student_details?.is_transfer === "1" ? true : false
			let isFifthYear = student_details?.year_in_program === '5'
			if(
				(isFifthYear || (!isTransfer && student_details?.year_in_program > '2')) || //NON-TRANSFER LOGIC
				(isFifthYear || (isTransfer && parseInt(student_details?.show_mentor_details) === 0))) { //TRANSFER LOGIC
				setShowMentorDetails(false)
				return
			}
		}
		const dateCreatedCheck = () => {
			let current = moment()
			let createDate = moment(student_details?.dt_created)
			let difference = current.diff(createDate, 'years')
			if(difference < 2) setShowTransferDetails(true)
		}
		setCurrentStatus(current_status);
		let cell_no = { target: { value: student_details?.student_mobile_number } };
		let home_phn = { target: { value: student_details?.student_home_number } };
		handleCellPhoneInputChange(cell_no, "cell_no");
		handleCellPhoneInputChange(home_phn, "home_phn");
		setIsActive(student_details?.is_active === '0' || student_details?.is_active === '2' ? '2' : student_details?.is_active);
		setIsTransferChecked(student_details?.is_transfer === "1" ? true : false);
		setIsOnlineChecked(student_details?.online === "1" ? true : false)
		// handleSelect(student_details?.faculty_id, 'advisor')
		// handleSelect(student_details?.mentor_id, 'mentor')
		mentorDetailsModalCheck()
		dateCreatedCheck()
		setShowTransferInputs(false)
		setStudentType(student_details?.student_type)

		form.setFieldsValue({
			user_profile: student_details?.student_image,
			gpa: student_details?.student_gpa,
			first_name: student_details?.student_first_name,
			last_name: student_details?.student_last_name,
			middle_name: student_details?.student_middle_name,
			student_id: student_details?.student_id_number,
			type: student_details?.student_type,
			year_program: student_details?.year_in_program,
			asha_num: student_details?.student_asha_number,
			psha_num: student_details?.student_psha_number,
			home_phn: student_details?.student_home_number,
			username: student_details?.student_username,
			p_email: student_details?.student_personal_email,
			mu_email: student_details?.student_university_email,
			identify_as: student_details?.student_identify,
			sat_combined_super: student_details?.student_sat_combined_super,
			sat_reading_super: student_details?.student_sat_reading_super,
			sat_math_super: student_details?.student_sat_math_super,
			act_composite_super: student_details?.student_act_composite_super,
			permanent_country: student_details?.student_permanent_country,
			permanent_state: student_details?.student_permanent_state,
			permanent_street_adress: student_details?.student_permanent_address,
			permanent_city: student_details?.student_permanent_city,
			permanent_zip: student_details?.student_permanent_zip,
			current_country: student_details?.student_current_country,
			current_state: student_details?.student_current_state,
			current_street_adress: student_details?.student_address,
			current_city: student_details?.student_current_city,
			current_zip: student_details?.student_zip,
			notes: student_details?.student_notes,
			semester_type: student_details?.semester === '1' ? [] : student_details?.semester,
			mentor_id: student_details?.mentor_id,
			advisor_id: student_details?.faculty_id,
			transfer_details: student_details?.transfer_details,
			transfer_date: student_details?.transfer_date ? dayjs(student_details?.transfer_date).format(constants.DateFormat) : '',
			joining_date: student_details?.joining_date ? dayjs(student_details?.joining_date).format(constants.DateFormat) : '',
			transfer_gpa: student_details?.transfer_gpa,
			transfer: student_details?.is_transfer === "1" ? true : false,
			dob: student_details?.student_dob ? dayjs(student_details?.student_dob) : null
		});
	};

	//function to filter select option
	const selectFilterOption = (input, option) => {
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};

	//get all country list
	const getCountries = () => {
		fetchApi(`/get-countries`, 'get').then((res) => {
			if (res?.code === "200") {
				setListCountries(res?.data?.countries);
			} else {
				setListCountries([]);
			}
		});
	};

	//function to return similer form field
	const getSimilerForm = (label, formfield, is_required, check_alphabet = null) => {
		let rules = []
		if(!check_alphabet)
			rules.push({ pattern: /^\d*\.?\d+$/, message: 'Only numerics are allowed.' })
		if (is_required) {
			return <Col xs={24} lg={8}>
				<Form.Item
					label={label}
					name={formfield}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
					]}
				>
					{check_alphabet !== null ?
						<Input 
							disabled={!isAdminOrSysAdmin} 
							onKeyPress={(event) => check_alphabet(event)} 
							className="faculty-inp" 
							placeholder={label}
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
						/> : 
						<Input 
							disabled={!isAdminOrSysAdmin} 
							className="faculty-inp" 
							placeholder={label}
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
						/>}
				</Form.Item>
			</Col>
		} else {
			return <Col xs={24} lg={8}>
				<Form.Item
					label={label}
					name={formfield}
					rules={[...rules]}
				>
					{check_alphabet !== null ? 
						<Input 
							disabled={!isAdminOrSysAdmin} 
							onKeyPress={(event) => check_alphabet(event)} 
							className="faculty-inp" 
							placeholder={label}
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
						/> : 
						<Input 
						disabled={!isAdminOrSysAdmin} 
						className="faculty-inp" 
						placeholder={label}
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
					/>}
				</Form.Item>
			</Col>
		}
	};

	//onChange Country
	const onChangeCountry = (val) => {
		form.setFieldsValue({ current_state: "" });
		fetchStates(val)
	};

	const onChangePermanentCountry = (val) =>{
		form.setFieldsValue({ permanent_state: "" });
		fetchPermaStates(val)
	}

	//adress form field
	const adressFormField = (adressType,listStates,fetchStateData) => {
		let field_name;
		if (adressType === "current") {
			field_name = "current_";
		} else {
			field_name = "permanent_";
		};
		return <Row gutter={[24, 0]}>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.StreetAddress}
					name={field_name + "street_adress"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
					]}
				>
					<Input 
						className="faculty-inp" 
						placeholder={constants.StreetAddress}
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field_name}street_adress`))}
					/>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.City}
					name={field_name + "city"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
					]}
				>
					<Input 
						className="faculty-inp" 
						placeholder={constants.City}
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field_name}city`))}
					/>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.Zip}
					name={field_name + "zip"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
						// {
						// 	validator: validateZipCode,
						// }
					]}

				>
					<Input 
						/*onKeyPress={(e) => handleKeyPressHandler(e, 5)}*/ 
						className="faculty-inp" 
						placeholder={constants.Zip}
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field_name}zip`))}
					/>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.Country}
					name={field_name + "country"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
						},
					]}
				>
					<Select
						placeholder={constants.Country}
						showSearch
						allowClear
						filterOption={selectFilterOption}
						onChange={fetchStateData}
					>
						{listCountries &&
							listCountries.map((listCountry) => (
								<Option key={listCountry.country_code} value={listCountry.id}>
									{listCountry.country_name}
								</Option>
							))}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.State}
					name={field_name + "state"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
						},
					]}
				>
					<Select
						placeholder={constants.State}
						showSearch
						allowClear
						filterOption={selectFilterOption}>
						{listStates &&
							listStates.map((listState) => (
								<Option key={listState.state_code} value={listState.id}>
									{listState.state_name}
								</Option>
							))}
					</Select>
				</Form.Item>
			</Col>
		</Row>
	};

	const statusColumn = [
		{
			title: <b>{constants.Status}</b>,
			dataIndex: 'new_status',
			key: 'new_status',
			render: (status_id) => <span>{getStatusName(status_id)}</span>
		},
		{
			title: <b>{constants.DateChanged}</b>,
			dataIndex: 'dt_updated',
			key: 'dt_updated',
			render: (dt_updated) => <span>{dt_updated ? dayjs(dt_updated).format(constants.DateFormat) : null}</span>
		},
		{
			title: <b>{constants.ChangedBy}</b>,
			key: 'changed_by',
			render: (val) => <span>{val?.faculty_first_name} {val?.faculty_last_name}</span>
		},
	];

	//collaps component
	const collapeComponent = (label, type, optionvalue) => {
		let collapseChildren;
		if (type === "table") {
			collapseChildren = (
				<Table
					size='small'
					className="records-table"
					columns={statusColumn}
					dataSource={statusTableData}
					bordered
					pagination={{
						showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
						itemRender: getNextAndPreviousButtons,
						pageSize: constants.TablePageSize
					}}
					scroll={{
						y: '200px'
					}}
				/>
			)
		} else {
			collapseChildren = advisorAndMentorCheck(label, type, optionvalue)
		}
		return <Collapse
			expandIconPosition="right" 
			className='collapse-custom'
			size='small'
			expandIcon={({ isActive }) => <DownCircleFilled className='text-white' rotate={isActive ? 180 : 0} />}
			items={[{ key: '1', label: <span className='text-white'><strong>{label}</strong></span>, children: collapseChildren }]} />
	};
	const advisorAndMentorCheck=(label, type, optionvalue)=>{
		let advisorId = form.getFieldValue("advisor");
		let mentorId = form.getFieldValue("mentor");
		if (isAdminOrSysAdmin || (type === "advisor" && advisorId) || (type === "mentor" && mentorId)) {
			return advisorAndMentorData(label, type, optionvalue);
		} else {
			return type === "advisor" ? constants.NoAdvisorAssigned : constants.NoMentorAssigned;
		}
	}
	const advisorAndMentorData=(label, type, optionvalue)=>(
		<Row gutter={[24, 0]} className='mt-4'>
		<Col xs={24} lg={8} xl={8} md={24} sm={24}>
			<Form.Item
				label={type === "advisor" ? "Advisor's Name" : "Mentor's Name"}
				name={type === "advisor" ? "advisor" : "mentor"}
			>
				<Select
					showSearch
					allowClear
					placeholder={constants.Select}
					filterOption={selectFilterOption}
					onChange={(id) => handleSelect(id, type === "advisor" ? "advisor" : "mentor")}
					disabled={!isAdminOrSysAdmin}
				>
					{optionvalue &&
						optionvalue.map((val) => (
							<Option key={val.id} value={val.id}>
								{type === "advisor" ? val.faculty : val.student_name}
							</Option>
						))}
				</Select>
			</Form.Item>
		</Col>
		<Col xs={24} lg={8}>
			<Form.Item
				label={constants.Email}
				name={type === "advisor" ? "advisor_email" : "mentor_email"}
			>
				<Input disabled={true} className="faculty-inp" placeholder={constants.Email} />
			</Form.Item>
		</Col>
		<Col xs={24} lg={8}>
			<Form.Item
				label={constants.ContactNo}
				name={type === "advisor" ? "advisor_contact_no" : "mentor_contact_no"}
			>
				<Input disabled={true} className="faculty-inp" placeholder={constants.ContactNo} />
			</Form.Item>
		</Col>
</Row>
	);
	const handleSelect = (id, type) => {
		let data = type === 'advisor' ? [...listAdvisors] : [...mentorDetails]
		let field = type === 'advisor' ? 'faculty' : 'student'
		let findIndex = data.findIndex((item) => item.id === id);
		form.setFieldsValue({
			[`${type}_contact_no`]: findIndex < 0 ? '' : data?.[findIndex]?.[`${field}_mobile_number`],
			[`${type}_email`]: findIndex < 0 ? '' : data?.[findIndex]?.[`${field}_university_email`],
			[`${type}_id`]: findIndex < 0 ? null : data?.[findIndex]?.id,
		})
	};

	//onChange Adress function
	const onChangeAdress = (e) => {
		setShowPermanentAdress(e.target.checked);
	};

	//Removing all null values.
	function filterNonNullValues(obj) {
		const filteredObj = {};
		Object.keys(obj).forEach((key) => {
			const value = obj[key];
			if (value !== null && value !== undefined && value !== '') {
				filteredObj[key] = value;
			}
		});
		return filteredObj;
	}

	//Saving student details
	const handleSave = (values) => {
		setOnSaveLoader(true)
		let payload = {
			student_first_name: removeExtraSpaces(values?.first_name),
			student_middle_name: removeExtraSpaces(values?.middle_name),
			student_last_name: removeExtraSpaces(values?.last_name),
			student_id_number: values?.student_id?.replace(/\s+/g, '') || '',
			student_dob: values?.dob ? dayjs(values?.dob).format("YYYY-MM-DD") : "",
			joining_date: values?.joining_date ? dayjs(values?.joining_date).format("YYYY-MM-DD") : "",
			student_asha_number: values?.asha_num,
			student_psha_number: values?.psha_num,
			student_mobile_number: values?.cell_no,
			student_home_number: values?.home_phn,
			student_personal_email: removeExtraSpaces(values?.p_email?.toLowerCase()),
			student_university_email: removeExtraSpaces(values?.mu_email?.toLowerCase()),
			student_sat_combined_super: values?.sat_combined_super,
			student_sat_reading_super: values?.sat_reading_super,
			student_sat_math_super: values?.sat_math_super,
			student_act_composite_super: values?.act_composite_super,
			student_identify: removeExtraSpaces(values?.identify_as),
			student_current_country: values?.current_country,
			student_current_state: values?.current_state,
			student_current_city_text: removeExtraSpaces(values?.current_city),
			student_address: removeExtraSpaces(values?.current_street_adress),
			student_zip: values?.current_zip,
			student_notes: removeExtraSpaces(values?.notes),
			student_semester: colSize === 4 ? values?.semester_type : studentDetails?.semester,
			transfer_details: values?.transfer_details,
			admin_id: getCookie(COOKIE.UserId),
			is_transfer: isTransferChecked === false ? "2" : "1",
			online: isOnlineChecked === true ? "1" : "0",
			is_active: studentDetails?.is_active === '0' && isActive === '2' ? studentDetails?.is_active : isActive,
			year_in_program: isArray(values?.year_program) ? 0 : values?.year_program,
			mentor_id: values?.mentor ? values?.mentor : values?.mentor_id,
			faculty_id: values?.advisor ? values?.advisor : values?.advisor_id,
			transfer_gpa: values?.transfer_gpa,
		}

		if(studentDetails?.student_is_mentor) payload.student_is_mentor = studentDetails.student_is_mentor

		if (showPermanentAdress) {
			payload.address_check = "1";
		} else {
			payload.address_check = "0";
			payload.student_permanent_country = values?.permanent_country;
			payload.student_permanent_state = values?.permanent_state;
			payload.student_permanent_city_text = removeExtraSpaces(values?.permanent_city);
			payload.student_permanent_address = removeExtraSpaces(values?.permanent_street_adress);
			payload.student_permanent_zip = values?.permanent_zip;
		}

		if(studentDetails?.student_type !== values?.type) payload.student_type = values?.type
		if(studentDetails?.current_status !== currentStatus) payload.student_status = currentStatus
		
		const filteredObject = filterNonNullValues(payload);
		handleUpload(filteredObject);
	};

	const handleUpload = async (payload) => {
		try {
			const formData = new FormData();
			//Appending profile image file.
			profileFile.forEach((file) => {
				if (file.originFileObj) {
					formData.append('student_profile', file.originFileObj);
				}
			});
			//Appending Upload file.
			uploadFileList.forEach((file) => {
				if (file.originFileObj) {
					formData.append('student_doc[]', file.originFileObj);
				}
			});
			// Append array parameter (other form values)
			Object.entries(payload).forEach(([key, value]) => {
				formData.append(`${key}`, value);
			});
			fetchStaffFormData(`/edit-student-data/${studentId}`, 'POST', formData).then((res) => {
				if (res.code === "200") {
					getStudentDetails(false);
					setProfileFile([]);
					setUploadFileList([]);
					checkArchiveRedirect();
					message.success(res?.message);
					errorRef.current.scrollIntoView({ behavior: "smooth" });
				} else message.error(res?.message);
				setOnSaveLoader(false)
			});
		} catch (error) {
			message.error(error.message);
			setOnSaveLoader(false)
		}
	};

	const checkArchiveRedirect = () => {
		if(isActive === '3') {
			navigate("/student-information", {
				state: { 
					student_id: studentDetails?.id,
					// student_semester: showTab ? tabKey : "0",
					from: constants.StudentListing,
					from_route: "/student-listing",
					tabkey: "StudentDetails",
					tab_title: constants.StudentDetails,
					student_name: studentDetails?.student_first_name + " " + studentDetails?.student_last_name,
					isArchived: true,
					year_in_program: studentDetails?.year_in_program
				}
			});
		}
	}

	//Common generateNumberValidator function for Cell Phone, PSHA, Zip Code and ASHA form field validation.
	const validateAshaNumber = generateNumberValidator(8, constants.AshaValidateMessage);
	const validatePshaNumber = generateNumberValidator(5, constants.PshaValidateMessage);
	// const validateCellNumber = generateNumberValidator(12, constants.MobileValidateMessage);
	// const validateZipCode = generateNumberValidator(5, constants.ZipCodeValidateMsg);

	//onChange phone number
	const handleCellPhoneInputChange = (e, formval) => {
		const inputValue = e.target.value;
		// const formattedInput = phoneNumberFormatter(inputValue);
		form.setFieldsValue({ [formval]: inputValue })
	};

	//handle Transfer
	const handleTransfer = (e) => {
		if (e.target.checked) {
			setShowTransferInputs(true)
			setIsTransferChecked(true);
		} else {
			setShowTransferInputs(false)
			setIsTransferChecked(false);
			setIsTransferModalOpen(true);
		}
	};

	//function to handle profile change.
	const handleProfileChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response.
			getBase64(info.file.originFileObj, (url) => {
				setLoading(false);
				setImageURL(url);
			});
		}
		if (info?.fileList) {
			setProfileFile(info.fileList);
		}
	};

	//function to handle file's change.
	const handleFilesChange = (info) => {
		if (info?.fileList) {
			let checkBeforeUpload = beforeUploadCheckFileType(info?.file,false);
			if(checkBeforeUpload){
				setUploadFileList(info.fileList);
			}
		}
	};

	const uploadButton = (
		<button
			className='bg-transparent border-0'
			type="button"
		>
			{loading ? <LoadingOutlined /> : <UserAddOutlined className='fs-1' />}
		</button>
	);

	const profileRenderContent = imageURL ? (
		<img src={imageURL} alt="avatar" className='photoPick' />
	) : (
		uploadButton
	);

	//download uploaded file function
	const downloadFile = (file) => {
		let fileExtention = file?.name?.split('.').pop();
		fetchStaffDownloadApi(`/download-student-document/${file?.id}`, "get", null).then(
			(res) => {
				if (res) {
					let url, a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application" }),
						{ type: `data:attachment/${fileExtention}` }
					);
					a = document.createElement("a");
					a.download = file?.name;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
				} else {
					message.error("Failed to download");
				}
			}
		);
	};

	const deleteFile = (file) => {
		fetchStaffApi(`/delete-student-document/${file?.id}`, 'delete').then((res) => {
			if (res?.code === "200") {
				message.success(res?.message);
				getStudentDetails(false);
			} else {
				message.error(res?.message);
			}
		});
	};

	const handleTransferOk = () => {
		setShowTransferInputs(false)
		setIsTransferModalOpen(false);
		setIsTransferChecked(false);
		//CLEAR TRANSFER INFO
		form.setFieldsValue({
			transfer_date: '',
			joining_date: '',
			transfer_details: '',
			transfer_gpa: '',
		})
	};

	const handleTransferCancel = () => {
		setIsTransferChecked(true);
		setIsTransferModalOpen(false);
	};

	const checkValidationCurrentStatus = (val) => {
		let statusText = currentStatusList.find((item) => item.value === val)?.label
		let currentStatusText = currentStatusList.find((item) => item.value === currentStatus)?.label
		if(!currentStatusText)
			currentStatusText = constants.StatusList.find((item) => item.value === currentStatus)?.label
		let overallStatusText = statusList.find((item) => item.value === isActive)?.label
		let warningMessage = ["Changing the current status from ",<b>{currentStatusText}</b>," to ", <b>{statusText}</b>]
		let openModal = false
		let newIsActiveStatus = ''

		const showArchiveMessage = () => {
			newIsActiveStatus = '3'
			warningMessage = warningMessage.concat(' will archive the student.')
			openModal = true
		}

		const showInactiveMessage = () => {
			newIsActiveStatus = '2'
			warningMessage = warningMessage.concat(' will make the student inactive.')
			openModal = true
		}

		const showActiveMessage = () => {
			newIsActiveStatus = '1'
			warningMessage = warningMessage.concat(' will make the student active.')
			openModal = true
		}
		if(val === "1" || val === "3" || val === "4" || val === "10") {
			if(overallStatusText === constants.Active) {
				if(val === "1" || val === "4") showInactiveMessage()
				else if(val === "3" || val === "10") showArchiveMessage()
			} else if(overallStatusText === constants.InActive) {
				if(val === "3" || val === "10") showArchiveMessage()
			} else if(overallStatusText === constants.Archived){
				if(val === "4") showInactiveMessage()
				else if(val === "2" || val === "6" || val === "9") showActiveMessage()
			}
		} else if (val === "2" || val === "6" || val === "9") {
			if(overallStatusText === constants.Active) {
				//DO NOTHING
			} else if(overallStatusText === constants.InActive) {
				showActiveMessage()
			}
		}
		if(openModal) {
			setTempIsActiveStatus(newIsActiveStatus)
			setTempCurrentStatus(val)
			setWarningPopupType('dropdown');
			setIsStatusWarningModalOpen(true);
			setWarningStatusMessage(warningMessage);
			return
		}
		checkSemesterDropdownDisplay(val);
		setCurrentStatus(val);
		setWarningPopupType('')
		setIsStatusWarningModalOpen(false);
	}

	const onTypeChange = (e) => {
		setStudentType(e)
		let overallStatusText = statusList.find((item) => item.value === isActive)?.label

		const partTimeLogic = () => {
			// setCurrentStatusList(constants.StatusList)
			// form.setFieldsValue({ year_program: '6' })
		}

		const fullTimeLogic = () => {
			if(form.getFieldValue('year_program') === '6') form.setFieldsValue({ year_program: [] })
			setCurrentStatusList(constants.StatusList)
		}

		const undeclaredLogic = () => {
			setCurrentStatus('9')
			setCurrentStatusList([...currentStatusList]?.filter((item) => item.value !== '6'))
			form.setFieldsValue({ year_program: [] })
		}
		
		if(overallStatusText === constants.Active) {
			if(e === '2') { //IF TYPE IS PART TIME DISABLE AND EMPTY YEAR IN PROG FIELD
				partTimeLogic()
			} else if(e === '3') { //IF TYPE IS UNDECLARED SET CURRENT STATUS TO UNDECLARED AND ENABLE YEAR IN PROG FIELD
				undeclaredLogic()
			} else fullTimeLogic()
			return
		} else if(overallStatusText === constants.InActive) {
			if(e === '2') { //IF TYPE IS PART TIME DISABLE AND EMPTY YEAR IN PROG FIELD
				partTimeLogic()
			} else fullTimeLogic()
			return
		}
		//SET STUDENT AS ACTIVE AND MAKE SLP MAJOR AS DEFAULT
		setTempIsActiveStatus('1')
		setTempCurrentStatus('6')
		setWarningPopupType('dropdown')
		setWarningStatusMessage(constants.ActiveSlpMajorMessage)
		setIsStatusWarningModalOpen(true)
	}

	const onChangeIsActive = (status) => {
		const archiveWarning = () => {
			setTempIsActiveStatus('3')
			setTempCurrentStatus('3')
			setWarningStatusMessage(constants.ArchiveStudent);
			setWarningPopupType('dropdown')
			setIsStatusWarningModalOpen(true);
		}
		if((currentStatus !== "1" && currentStatus !== "3" && currentStatus !== "10") && status === '3') {
			setWarningStatusMessage(constants.WarningStatusArchiveMsg);
			setWarningPopupType('radio')
			setIsStatusWarningModalOpen(true);
			return
		} else {
			// archiveWarning() //MIGHT NOT NEED THIS
		}

		if(currentStatus === "10") {
			if (status === '2') {
				setWarningStatusMessage(constants.WarningStatusInActiveMsg);
				setWarningPopupType('radio')
				setIsStatusWarningModalOpen(true);
				return
			} else if (status === '1') {
				setWarningStatusMessage(constants.WarningStatusActiveMsg);
				setWarningPopupType('radio')
				setIsStatusWarningModalOpen(true);
				return
			}
		}

		if (currentStatus === "2" || currentStatus === "6" || (currentStatus === "9" && form.getFieldValue('type') !== '3')) {
			if (status === '2') {
				setWarningStatusMessage(constants.WarningStatusInActiveMsg);
				setWarningPopupType('radio')
				setIsStatusWarningModalOpen(true);
				return
			}
		} else if (currentStatus === "4" || currentStatus === "1" || currentStatus === "3") {
			if (status === '1') {
				setWarningStatusMessage(constants.WarningStatusActiveMsg);
				setWarningPopupType('radio')
				setIsStatusWarningModalOpen(true);
				return
			}
		} else {
			if(form.getFieldValue('type') === '3') {
				archiveWarning()
			}
		}
		setIsActive(status);
		setWarningPopupType('')
		setIsStatusWarningModalOpen(false);
	};

	const handleStatusWarning = () => {
		if(warningPopupType === 'dropdown') {
			setCurrentStatus(tempCurrentStatus)
			setIsActive(tempIsActiveStatus)
			checkSemesterDropdownDisplay(tempCurrentStatus);
		}	
		setWarningPopupType('')
		setIsStatusWarningModalOpen(false);
	};

	const checkSemesterDropdownDisplay = (status) => {
		//CHECK IF STUDENT WAS ON BREAK AND MOVED TO SLP MAJOR WHEN 2 SEMS ARE ACTIVE
		if(studentDetails?.current_status === '4' && status === '6' && semesterData?.length > 1 && !isSameSem) {
			setColSize(4)
			return
		}
		setColSize(8)
		form.setFieldsValue({ semester_type: [] });
	}
	
	const handleStatusWarningCancel = () => {
		if(warningPopupType === 'dropdown') {
			setTempIsActiveStatus('')
			setTempCurrentStatus('')

			// if(isActive === '2') {
			// 	form.setFieldsValue({ type: [] })
			// }
		}	
		setIsStatusWarningModalOpen(false)
	};

	const disableFutureDate = (current) => {
		return current && current > dayjs().endOf('day');
	};
	const statusSelect=()=>(
		<Select
			className="w-full"
			placeholder={constants.SelectStatus}
			showSearch
			filterOption={selectFilterOption}
			onChange={(e) => checkValidationCurrentStatus(e)}
			value={currentStatus}
			disabled={!isAdminOrSysAdmin}
		>
			{
				currentStatusList.map((item) => (
					<Option value={item.value}>{item.label}</Option>
				))
			}
		</Select>
	)
	
	const gpaTooltip = (studentDetails?.student_gpa && studentDetails?.grad_gpa) ? (
		<>
			<span>{`Undergrad GPA: ${parseFloat(studentDetails?.student_gpa)?.toFixed(2)}`}</span>
			<br />
			<span>{`Grad GPA: ${parseFloat(studentDetails?.grad_gpa)?.toFixed(2)}`}</span>
		</>
	 ) : null;

	const yearInProgramOption = (isPartTime) => {
		// if(isPartTime) return <Option value="6">{constants.PartTime}</Option>
		return(
			<>
				<Option value="1">{constants.Freshman}</Option>
				<Option value="2">{constants.Sophomore}</Option>
				<Option value="3">{constants.Junior}</Option>
				<Option value="4">{constants.GraduateYear1}</Option>
				<Option value="5">{constants.GraduateYear2}</Option>
				{isPartTime && <Option value="6">{constants.PartTime}</Option>}
			</>		
		)
	}

	const yearInProgramDisabled = () => {
		const transferStudent = isTransferChecked
		let isAdmin = isAdminOrSysAdmin //DISABLE IF NOT ADMIN
		// let isPartTime = form.getFieldValue('type') === '2'; //DISABLE IF PART TIME STUDENT
		let wasOnBreak = studentDetails?.current_status === '4' //IF STUDENT CURRENT STATUS IS BREAK DONT DISABLE
		let isFirstSem = yearInProgramsList?.length === 1 //IF NON TRANSFER STUDENT IS IN FIRST SEM THEN DONT DISABLE
		// let isDropdownEmpty = isArray(form.getFieldValue('year_program')) //IF DROPDOWN IS EMPTY THEN DONT DISABLE
		let yearInProgramGreaterThanOne = yearInProgramsList?.length > 1 //IF TRANSFER STUDENT IS IN FIRST SEM THEN DONT DISABLE

		let transferTypeCheck;
		if(transferStudent) { //FOR TRANSFER STUDENT
			transferTypeCheck = !wasOnBreak && (!isSameSem || yearInProgramGreaterThanOne)/* && !isDropdownEmpty*/;
		} else { //FOR NON-TRANSFER STUDENT
			transferTypeCheck = !wasOnBreak && (!isSameSem || !isFirstSem)/* && !isDropdownEmpty*/;
		}
		let flag = !isAdmin || transferTypeCheck/* || isPartTime*/
		return {
			disableYearPrg: flag,
			disableType: transferStudent ? yearInProgramGreaterThanOne : !isFirstSem
		}
	}

	const renderTypeDropdown = () => {
		let options = constants.StudentTypeList
		if(isActive !== '2') return options
		return options.filter((item) => item.value !== '3') //HIDE UNDECLARED OPTION IF STATUS IS INACTIVE
	}

	return (
		<Spin tip={constants.PleaseWait} spinning={onSaveLoader}>
			<div ref={errorRef}>
				<div className='student-form-header position-relative rounded h-100px mt-2'>
					<h3 className='p-4 mb-2'>{studentDetails?.student_first_name} {studentDetails?.student_last_name}</h3>
				</div>
				<Form 
					layout="vertical" 
					form={form} 
					onFinish={handleSave} 
					scrollToFirstError={true}
				>
					<Flex align='center' justify='center'>
						<Form.Item
							name="user_profile"
							className='position-absolute z-3 top-5 lg:top-12'
						>
							<FileUpload
								renderContent={profileRenderContent}
								name='avatar'
								listType='picture-circle'
								className='avatar-uploader'
								showUploadList={false}
								beforeUpload={beforeProfileUpload}
								onChange={handleProfileChange}
								isMultiple={false}
								disabled={!isAdminOrSysAdmin}
							/>
						</Form.Item>
					</Flex>
					<div className='flex justify-between items-center my-3'>
						<Space>
							<Form.Item
								name="gpa"
								className='!mb-0'
							>
								<span className='bg-secondary p-2 text-white rounded-pill fw-bold'>
									{constants.Gpa} : {studentDetails?.grad_gpa ? parseFloat(studentDetails?.grad_gpa)?.toFixed(2) :
										studentDetails?.student_gpa ? parseFloat(studentDetails?.student_gpa)?.toFixed(2) :
											'N/A'}
								</span>
							</Form.Item>
							<Tooltip title={gpaTooltip}><InfoCircleOutlined /></Tooltip>
						</Space>
						<Form.Item
							name="is_Active"
							valuePropName='checked'
							className='!mb-0'
						>
							<Radio.Group
								className='radioSwitch'
								// options={statusList}
								onChange={(e) => onChangeIsActive(e.target.value)}
								optionType="button"
								buttonStyle="solid"
								value={isActive}
							>
								{
									statusList?.map((item) => (
										<Radio disabled={item?.disabled} value={item?.value}>{item?.label}</Radio>
									))
								}
							</Radio.Group>
						</Form.Item>
					</div>
					<div>
						<div className='flex items-center'>
							<Space>
								<Form.Item
									name="transfer"
									className='mb-0'
								>
									{(isAdminOrSysAdmin || (!isAdminOrSysAdmin && isTransferChecked)) && (
										<Checkbox
											checked={isTransferChecked}
											onChange={handleTransfer}
										>
											{constants.Transfer}
										</Checkbox>
									)}
								</Form.Item>
								<Form.Item
									name="student_type2"
									className='mb-0'
								>
									{isAdminOrSysAdmin && (
										<Checkbox
											checked={isOnlineChecked}
											onChange={(e) => setIsOnlineChecked(e.target.checked)}
										>
											{constants.Online}
										</Checkbox>
									)}
								</Form.Item>
							</Space>
						</div>
						{isAdminOrSysAdmin&&<Row gutter={[24, 0]}>
							{
								isTransferChecked && showTransferInputs ?
									<>
										<Col xs={24} lg={16} className='mt-3'>
											<Form.Item
												name="transfer_details"
												label={constants.TransferDetails}
												className='mb-0'
												rules={[
													{
														required: isTransferChecked,
														message: constants.RequiredField,
													},
												]}
											>
												<TextArea className="faculty-inp" placeholder={constants.TransferDetails} autoSize />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8} className='mt-3'>
											<Form.Item
												name="transfer_gpa"
												label={constants.Gpa}
												className='mb-0'
												rules={[
													{
														required: isTransferChecked,
														message: constants.RequiredField,
													},
													{ 
														pattern: /^([1-3](\.\d+)?|4(\.0+)?)$/, message: 'Only numerics are allowed (Range from 1-4).',
													}
												]}
											>
												<Input 
													className="w-100" 
													placeholder={constants.Gpa} 
													onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`transfer_gpa`))}
												/>
											</Form.Item>
										</Col>
									</>
									:
									null
							}
						</Row>}
					</div>
					<Row gutter={[24, 0]} className='mt-4'>
						{getSimilerForm(constants.FirstName, "first_name", true, allowAlphabets)}
						{getSimilerForm(constants.MiddleName, "middle_name", false, allowAlphabets)}
						{getSimilerForm(constants.LastName, "last_name", true, allowAlphabets)}
						{getSimilerForm(constants.StudentId, "student_id", false)}
						<Col xs={24} lg={colSize} xl={colSize}>
							<Form.Item
								label={constants.Type}
								name="type"
								rules={[
									{
										required: true,
										message: constants.RequiredField,
									},
								]}
							>
								<Select
									disabled={!isAdminOrSysAdmin} 
									onChange={onTypeChange}
									placeholder={constants.SelectType}
								>
									{renderTypeDropdown().map((item, index) => (
										<Option value={item.value} key={index} >{item.label}</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						{colSize === 4 ?
								<Col xs={24} lg={colSize} xl={colSize}>
									<Form.Item
										label={constants.Semester}
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}
										name='semester_type'
									>
										<Select 
											placeholder='Semester' 
											onChange={(e) => getMentorList(e, studentDetails)}
										>
											{
												semesterData?.map((sem) => (
													<Option 
														key={sem.semester} 
														value={sem.semester_is_ended}
														>
															{sem.semester}
													</Option>
												))
											}
										</Select>
									</Form.Item>
								</Col> : ''
							}
						<Col xs={24} lg={8}>
							<Form.Item
								label={constants.YearProgram}
								name="year_program"
								rules={[
									{
										required: /*form.getFieldValue('type') === '2' ? false : */true,
										message: constants.RequiredField,
									},
								]}
							>
								<Select disabled={yearInProgramDisabled()?.disableYearPrg} placeholder={constants.SelectYear}>
									{
										studentType === '2' ? yearInProgramOption(true) : yearInProgramOption(false)
									}
								</Select>
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item
								label={constants.DOB}
								name="dob"
								rules={[
									{
										required: true,
										message: constants.RequiredField,
									},
								]}
							>
								<DatePicker disabled={!isAdminOrSysAdmin} disabledDate={disableFutureDate} placeholder={constants.DOB} format={constants.DateFormat} className="w-100" />
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item
								label={constants.AshaNumber}
								name="asha_num"
								rules={[
									{
										validator: validateAshaNumber,
									},
								]}
							>
								<Input disabled={!isAdminOrSysAdmin} onKeyPress={(e) => handleKeyPressHandler(e, 8)} className="faculty-inp" placeholder={constants.AshaNumber} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item
								label={constants.PshaNumber}
								name="psha_num"
								rules={[
									{
										validator: validatePshaNumber,
									},
								]}
							>
								<Input disabled={!isAdminOrSysAdmin} onKeyPress={(e) => handleKeyPressHandler(e, 5)} className="faculty-inp" placeholder={constants.PshaNumber} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={8}>
							<Form.Item
								label={constants.CellPhone}
								name="cell_no"
								rules={[
									{
										required: true,
										message: constants.RequiredField,
										whitespace: true,
									},
									// {
									// 	validator: validateCellNumber
									// }
								]}
							>
								<Input 
									disabled={!isAdminOrSysAdmin} 
									onChange={(e) => handleCellPhoneInputChange(e, "cell_no")} 
									onKeyPress={(e) => handleKeyPressHandler(e, null)} 
									className="faculty-inp" 
									placeholder={constants.CellPhone} />
							</Form.Item>
						</Col>
						{isAdminOrSysAdmin&&<>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.HomePhn}
									name="home_phn"
									rules={[

										// {
										// 	validator: validateCellNumber
										// }
									]}
								>
									<Input 
										onChange={(e) => handleCellPhoneInputChange(e, "home_phn")}
										onKeyPress={(e) => handleKeyPressHandler(e, null)} 
										className="faculty-inp" 
										placeholder={constants.HomePhn} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.Username}
									name="username"
								>
									<Input className="faculty-inp" disabled={true} placeholder={constants.Username} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.PersonalEmail}
									name="p_email"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
										{
											type: 'email',
											message: constants.InvalidFormat,
										},
									]}
								>
									<Input onKeyDown={disableSpaceBar} className="faculty-inp" placeholder={constants.PersonalEmail} />
								</Form.Item>
							</Col>
						</>
						}
						<Col xs={24} lg={8}>
							<Form.Item
								label={constants.MuEmail}
								name="mu_email"
								rules={[
									{
										type: 'email',
										message: constants.InvalidFormat,
									},
								]}
							>
								<Input onKeyDown={disableSpaceBar} disabled={!isAdminOrSysAdmin} className="faculty-inp" placeholder={constants.MuEmail} />
							</Form.Item>
						</Col>
						{getSimilerForm(constants.IdentifyAs, "identify_as", false)}
						{isAdminOrSysAdmin&&
						<>
						<Col xs={24} lg={12}>
							<Form.Item
								label={constants.SatCombinedSuper}
								name="sat_combined_super"
							>
								<Input 
									onKeyPress={(e) => handleKeyPressHandler(e, 6)} 
									className="faculty-inp" 
									placeholder={constants.SatCombinedSuper} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label={constants.SatReadingSuper}
								name="sat_reading_super"
							>
								<Input onKeyPress={(e) => handleKeyPressHandler(e, 6)} className="faculty-inp" placeholder={constants.SatReadingSuper} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label={constants.SatMathSuper}
								name="sat_math_super"
							>
								<Input  onKeyPress={(e) => handleKeyPressHandler(e, 6)} className="faculty-inp" placeholder={constants.SatMathSuper} />
							</Form.Item>
						</Col>
						<Col xs={24} lg={12}>
							<Form.Item
								label={constants.ActCompositeSuper}
								name="act_composite_super"
							>
								<Input onKeyPress={(e) => handleKeyPressHandler(e, 6)} className="faculty-inp" placeholder={constants.ActCompositeSuper} />
							</Form.Item>
						</Col>
						</>
					}
					</Row>
					{isAdminOrSysAdmin&&
						<>
							<Divider className='mt-0 mb-3' />
							<div className='mb-2'><label>{constants.CurrentAddress}</label></div>
							{adressFormField("current",states,onChangeCountry)}
							<Col xs={24} lg={8}>
							<Form.Item
								name="is_permanent_adress"
							>
								<Checkbox checked={showPermanentAdress} onChange={onChangeAdress}>{constants.AdressLabel}</Checkbox>
							</Form.Item></Col>
							{
								!showPermanentAdress ?
									<>
										<div className='mb-2'><label>{constants.PermanentAddress}</label></div>
										{adressFormField("permanent",permaStates,onChangePermanentCountry)}
									</> :
									null
							}
						<Divider className='mt-0 mb-3' />
						<Row gutter={[24, 0]} className='mt-4'>
							<Col xs={24} lg={16}>
								<Form.Item
									label={constants.Notes}
									name="notes"
								>
									<TextArea rows={2} showCount maxLength={255} placeholder={constants.Notes} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.UploadDoc}
									name="upload_doc"
								>
									<FileUpload
										renderContent={<Button icon={<UploadOutlined />}>{constants.ChooseFile}</Button>}
										name='file'
										listType='text'
										className=''
										showUploadList={true}
										isMultiple={true}
										beforeUpload={beforeUploadCheckFileType}
										onChange={handleFilesChange}
										onPreviewFile={downloadFile}
										onRemoveFile={deleteFile}
										defaultFileList={uploadFileList.length > 0 ? uploadFileList : []}
									/>
									<div>{constants.ValidFileType}</div>
									<div>{constants.ValidFileSize}</div>
								</Form.Item>
							</Col>
						</Row>
					</>}
					<Row gutter={[16, 16]}>
						<Col xl={12} lg={12} md={24} sm={24} xs={24}>{collapeComponent(constants.AdvisorDetls, "advisor", listAdvisors)}</Col>
						{showMentorDetails && <Col xl={12} lg={12} md={24} sm={24} xs={24} >{collapeComponent(constants.MentorDetls, "mentor",mentorDetails)}</Col>}
					</Row>
					{isTransferChecked && (form.getFieldValue('transfer_date') !== '') &&
					<>
						<Divider />
						<Row>
							<Col span={24}>
								<div className='mb-3'>
									<label>{constants.TransferInfo}</label>
								</div>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xs={24} lg={4}>
								<Form.Item
									label={constants.TransferDate}
									name="transfer_date"
								>
									<Input disabled />
								</Form.Item>
							</Col>
							<Col xs={24} lg={4}>
								<Form.Item
									label={constants.JoiningDate}
									name="joining_date"
								>
									<Input disabled />
								</Form.Item>
							</Col>
							<Col xs={24} lg={16}>
								<Form.Item
									label={constants.Details}
									name="transfer_details"
								>
									<TextArea 
										disabled
										style={{
											resize: 'none',
										}}	
									/>
								</Form.Item>
							</Col>
						</Row>
					</>}
					{isAdminOrSysAdmin&&
					<>
						<Divider />
						<div className='mb-3'>
							<label>{constants.StudentStatus}</label>
						</div>
						<Row gutter={[16, 0]}>
								<Col xs={24} lg={8}>
										<label className='mb-2'>{constants.CurrentStatus}</label>
										<div>{statusSelect()}</div>
								</Col>
								<Col xs={24} lg={16}>
									<div className='mt-6'>{collapeComponent(constants.StatusHistory, "table", null)}</div>
								</Col>
							</Row>
					<div className='mt-2 float-end'>
						<Space wrap>
							<Button
								type="primary" danger
								onClick={() => navigate(`/student-listing`)}
							>
								{constants.Cancel}
							</Button>
							<Button
								type="primary"
								// htmlType="submit"
								onClick={() => form.submit()}
								disabled={onSaveLoader || studentDetails?.is_active === '3'}
								loading={onSaveLoader}
							>
								{constants.Save}
							</Button>
						</Space>
					</div>
					</>}
					{/* Transfer modal starts */}
					<Modal
						title={constants.ClearTransferTitle}
						open={isTransferModalOpen}
						onOk={handleTransferOk}
						onCancel={handleTransferCancel}
						cancelText={constants.Close}>
						<p>{constants.ClearTransferBody}</p>
					</Modal>
					{/* Transfer modal ends */}
					<Form.Item name='mentor_id' hidden />
					<Form.Item name='advisor_id' hidden />
				</Form>
				{/* Current status Warning modal starts */}
				<Modal
					centered
					title={constants.PleaseNote}
					open={isStatusWarningModalOpen}
					onOk={() => handleStatusWarning()}
					onCancel={() => handleStatusWarningCancel()}
					footer={(_, { OkBtn, CancelBtn }) => (
						<>
							{warningPopupType === 'dropdown' && <CancelBtn />}
							<OkBtn />
						</>
					)}
				>
					<p>{warningStatusMessage}</p>
				</Modal>
				{/* Current status Warning modal ends */}
			</div>
		</Spin>
	)
};

export default EditStudent;