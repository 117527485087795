import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, Card, Row, Col, Popover, Space, Divider, Input, Modal, Form, Select, message, Spin, Popconfirm, DatePicker, Checkbox, Tooltip } from 'antd';
import { PlusCircleOutlined, InfoCircleFilled, DeleteOutlined, SettingOutlined, CheckOutlined, LikeOutlined } from '@ant-design/icons';
import constants from '../../Constants/constants';
import { fetchApi, fetchDownloadApi, fetchStaffApi } from '../../Services/Api';
import { useLocation, useNavigate } from "react-router-dom";
import { filterSelectOption, removeExtraSpaces } from "../../GlobalFunctions/GlobalFunctions";
import moment from 'moment/moment';
import { COOKIE, getCookie } from '../../Services/Cookie';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';

const { TextArea } = Input;
const { Option, OptGroup } = Select;
const legends = (
	<div>
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-1'></div>
			{constants.Required}
		</div>
		<Divider className='my-2' />
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-2'></div>
			{constants.Waived}
		</div>
		<Divider className='my-2' />
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-3'></div>
			{constants.Transfer}
		</div>
		<Divider className='my-2' />
		<div className='d-flex align-items-center gap-3'>
			<div className='box legend-color-4'></div>
			{constants.Recommended}
		</div>
	</div>
);
let locale = {
	emptyText: <span className='text-secondary'>{constants.NoCourseData}</span>,
};
const formItemsInline = {
	labelCol: { span: 9 },
	wrapperCol: { span: 15 }
}
const formItemsHorizontal = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 }
}

function getAllSections(sections) {
	return Array.from({ length: sections }, (_, i) => {
		return {
			id: i + 1,
			name: i + 1
		}
	}).concat(constants.extraSections)
}

function getSectionNo(sectionNumber) {
	let section = parseInt(sectionNumber)
	let sectionNo = section > 8 ?
		constants.extraSections?.find((sec) => sec.id === section)?.name :
		sectionNumber
	return sectionNo
}

function courseCreditOptions() {
	return [
		{
			value: 1, label: "1",
		},
		{
			value: 2, label: "2",
		},
		{
			value: 3, label: "3",
		},
		{
			value: 4, label: "4",
		},
	]
}

function statusOptions() {
	return [
		{
			value: '0',
			label: <Space><div className='box legend-color-1'></div>{constants.Required}</Space>,
		},
		{
			value: '1',
			label: <Space><div className='box legend-color-2'></div>{constants.Waived}</Space>,
		},
		{
			value: '2',
			label: <Space><div className='box legend-color-3'></div>{constants.Transfer}</Space>,
		},
		{
			value: '3',
			label: <Space><div className='box legend-color-4'></div>{constants.Recommended}</Space>,
		},
	]
}

function extractText(reactNode) {
	if (typeof reactNode === 'string') {
		return reactNode;
	} else if (React.isValidElement(reactNode)) {
		return extractText(reactNode.props.children);
	} else if (Array.isArray(reactNode)) {
		return reactNode.map(child => extractText(child)).join('');
	}
	return '';
};

function filterOption(input, option) {
	const label = extractText(option.label) || '';
	return label.toString().toLowerCase().indexOf(input?.toLowerCase()) >= 0;
};

function filterOptionChild(input, option) {
	const children = extractText(option.children) || '';
	return children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const SemesterCourses = ({ student_id = getCookie(COOKIE.UserId), sem_id, year_in_program, student_name, locationData }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [semesterCourses, setSemesterCourses] = useState([]);
	const [semCoursesDataPopup, setSemCoursesDataPopup] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [modalTitle, setModalTitle] = useState('');
	const [summerYear, setSummerYear] = useState('');
	const [modalYear, setModalYear] = useState('');
	const [modalYearTitle, setModalYearTitle] = useState('');
	const [modalYearID, setModalYearID] = useState('');
	const [modalYearSummer, setModalYearSummer] = useState('');
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [, setIsSemesterApproved] = useState(false);
	const [studentNoteApproved, setStudentNoteApproved] = useState(false);
	const [isCourseCreditDisabled, setIsCourseCreditDisabled] = useState(false);
	const [disableButton, setDisableButton] = useState(true);
	const [preventPDFGeneration, setPreventPDFGeneration] = useState(true);
	const [showGenerateCoursePlanPopup, setShowGenerateCoursePlanPopup] = useState(false);
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();
	const notesRef = useRef('')
	const role = getCookie(COOKIE.Role);
	const userId = getCookie(COOKIE.UserId);
	let isArchived = location?.state?.isArchived;
	const isStudent = role === '0' ? 'student' : null

	useEffect(() => {
		getSemCourses();
	}, []);

	const handleStatusChange = (value) => {
		if (value === '1') {
			form2.setFieldsValue({
				course_credit: '0'
			});
			setIsCourseCreditDisabled(true);
		} else {
			setIsCourseCreditDisabled(false);
			const selected = selectedOption?.course_credit || '';
			form2.setFieldsValue({
				course_credit: selected
			});
		}
	};

	const restructureResponse = (data) => {
		let yearArray = data?.data
		let isCurrentYearFlag = false
		let canAddFlag = false
		let underGradCount = 0
		let gradCount = 0
		let seniorCount = 0

		//ADD UNDERGRAD SEMESTER DATA FILLED COUNT EXCEPT FOR SUMMER SESSIONS
		const underGradSemestersFilledCounter = (sems) => {
			return underGradCount+=sems?.filter((sem) => !sem.is_summer && sem.is_filled)?.length
		}
		//ADD GRAD SEMESTER DATA FILLED COUNT EXCEPT FOR SUMMER SESSIONS
		const gradSemestersFilledCounter = (year, sems) => {
			let sliceFn = () => sems?.filter((sem) => !sem.is_summer)?.filter((s) => s.is_filled > 0)?.length
			if(year === 4)
				return gradCount+=sliceFn()
			return seniorCount+=sliceFn()
		}
		//LOGIC TO ENABLE/DISABLE GENERATE PDF BUTTON FOR INITIAL SEMESTERS
		const enablePdfGeneration = (count) => {
			if(underGradCount !== count) return false //DISABLE IF ANY ONE UNDERGRAD SEMESTER IS MISSING
			if(gradCount === 0 && seniorCount > 0) return false //DISABLE IF GRAD SEM IS EMPTY BUT SENIOR IS FILLED
			return true
		}
		//LOGIC TO ALLOW USER TO ADD COURSES TO A SEMESTER
		const enablePlusIconLogic = (sem, yearIndex, previousYearObj = null) => {
			//IF STUDENT YEAR IN PROGRAM IS PART TIME, ALLOW ADD ANYTIME
			if(data?.year_in_program === 6) {
				sem.can_add = true
				return
			}
			//CONDITIONS FOR TRANSFER STUDENT
			if(data?.is_transfer === 1) {
				//IF CURRENT SEMESTER, CHECK IF PREVIOUS SEMESTERS WERE ATTENDED OR IF IT IS CURRENT/FUTURE SEM
				if(yearIndex > -1 && previousYearObj) {
					if(sem?.is_previous_semester || sem?.is_current_or_future_sem) {
						sem.can_add = true
						return
					}
				} else if(sem?.is_current_or_future_sem) { //IF CURRENT/FUTURE SEM
					sem.can_add = true
					return
				}
			}
			//IF CURRENT SEMESTER, IF PREV/PREV2PREV SEMESTER APPROVED AND IF NO SUMMER SESSIONS
			if(sem?.is_current_semester) {
				if(sem?.is_previous_semester_approved && sem?.is_previous_to_previous_semester_approved) {
					if(sem?.any_summer_sessions === false) {
						canAddFlag = true
						sem.can_add = true
					}
				}
			}
			//CAN ADD FOR FUTURE SEMESTERS IF THE ABOVE CONDITION IS TRUE
			if(canAddFlag) sem.can_add = true
		}
		//LOGIC TO ALLOW ADMIN/ADVISOR TO APPROVE A SEMESTER
		const enableApproveIconLogic = (sem, yearIndex, previousYearObj = null) => {
			//IF STUDENT YEAR IN PROGRAM IS PART TIME, ALLOW APPROVE ANYTIME
			if(data?.year_in_program === 6) {
				sem.can_approve = true
				return
			}
			//IF FIRST YEAR, SHOW APPROVE ICON
			if(data?.is_initial_semester) {
				//HIDE APPROVE BUTTON FOR SPRING IF CURRENT SEMESTER IS FALL
				if(sem.semester === 2 && !sem.is_current_semester) return
				sem.can_approve = true
				return
			}
			//IF NOT CURRENT SEMESTER, THEN STOP FUNCTION (EXCEPT FIRST YEAR)
			if(!sem?.is_current_semester) return
			//IF TRANSFER STUDENT AND IS IN CURRENT SEMESTER
			if(data?.is_transfer === 1 && yearIndex > -1) sem.can_approve = true 
			//IF ANY DATA IS THERE IN SUMMER SESSIONS
			if(!sem?.is_previous_semester_approved && sem?.is_previous_to_previous_semester_approved) {
				if(sem?.any_summer_sessions === false && yearIndex > 0) {
					previousYearObj.semesters[1].can_approve = true
				}
			} else if(sem?.is_previous_semester_approved && !sem?.is_previous_to_previous_semester_approved) {
				if(sem?.any_summer_sessions === false && yearIndex > 0) {
					previousYearObj.semesters[0].can_approve = true
				}
			} else if(sem?.is_previous_semester_approved && sem?.is_previous_to_previous_semester_approved) {
				if(sem?.any_summer_sessions === false && yearIndex > 0) {
					sem.can_approve = true 
				}
			} else if(sem?.any_summer_sessions === false && yearIndex > 0) {
				previousYearObj.semesters[0].can_approve = true
				previousYearObj.semesters[1].can_approve = true
			}
		}

		for(let i = 0; i < yearArray.length; i++) {
			let items = yearArray[i]
			let semesters = items.semesters
			for(let j = 0; j < semesters.length; j++) {
				let semester = semesters[j]
				semester.is_summer = semester.semester === 3 || semester.semester === 4 //SUMMER FLAG FOR ONLY 3RD(INDEX 2) AND 4TH(INDEX 3) SEMESTERS
				semester.can_add = false
				semester.can_approve = false
				semester.is_filled = semester?.data?.length > 0 //IS SEMESTER DATA PRESENT FOR EACH SEMESTER
				let isCurrentSem = semesters[j].is_current_semester
				semester.is_current_or_future_sem = isCurrentSem || isCurrentYearFlag
				enablePlusIconLogic(semester, i, semesters[j].is_current_semester ? yearArray[i - 1] : null)
				enableApproveIconLogic(semester, i, yearArray[i - 1])
				if(!isCurrentYearFlag)
					isCurrentYearFlag = isCurrentSem
			}
			if(items.year !== 4 && items.year !== 5)
				underGradSemestersFilledCounter(semesters)
			else gradSemestersFilledCounter(items.year, semesters)
		}
		let count = data?.data?.length * 2
		data.are_all_sems_filled = (underGradCount + gradCount + seniorCount) === count
		if(data?.is_transfer === 1) setPreventPDFGeneration(false)
		else setPreventPDFGeneration(!enablePdfGeneration(underGradCount))
		return { ...data }
	}

	const getSemCourses = () => {
		setLoading(true);
		let payload = null;
		let method = ''
		let url = ''
		if(isStudent) {
			url = `/student-semester-courses/${student_id}`
			method = 'GET'
		} else {
			url = '/student-semester-courses'
			method = 'POST'
			payload = { student_id: student_id };
		}
		fetchApi(url, method, payload, isStudent)
			.then((res) => {
				if (res?.code === "200") {
					setStudentNoteApproved(res?.data?.students_submitted)
					let data = restructureResponse(res?.data);
					console.log(data);
					setSemesterCourses(data)
				} else {
					setSemesterCourses([]);
				}
				setLoading(false);
			});
	};

	const getSemCoursesPopup = (title, year, yearTitle, yearID, summer, summerYear) => {
		const payload = {
			student_id: student_id,
			year: year_in_program,
			sem: sem_id
		};

		fetchStaffApi(`/get-semester-course`, 'POST', payload, isStudent)
			.then((res) => {
				if (res?.code === "200") {
					setSemCoursesDataPopup(res?.data);
					setModalTitle(title);
					setModalYear(year);
					setModalYearTitle(yearTitle);
					setModalYearID(yearID);
					setModalYearSummer(summer);
					setSummerYear(summerYear);
					setIsModalOpen(true); // Open modal on successful API call
				} else {
					setSemCoursesDataPopup([]);
					message.error('Error fetching semester courses: ' + res?.message || 'Unexpected error');
				}
			})
			.catch(error => {
				message.error('Error fetching semester courses' + error.message);
			});
	};

	const getEditSemCoursesPopup = (title, yearTitle, yearID, summer, record, yearObject) => {
		let { semester_year, is_semester_approved } = yearObject
		setModalTitle(title);
		setModalYear(semester_year);
		setModalYearTitle(yearTitle);
		setModalYearID(yearID);
		setModalYearSummer(summer);
		setIsEditModalOpen(true);
		setSelectedRecord(record);
		setIsSemesterApproved(is_semester_approved);
		form3.setFieldsValue({
			course_area: record?.course_area || '',
			course_text: record?.course_text || '',
			status: String(record?.status) || '',
			// year: record?.year || '',
			number_of_sections: record?.section_number || '',
			course_credit: record?.course_credit || '',
			grade: record?.grade || null,
			calendar_year_scheduled: record?.calendar_year_scheduled ? dayjs(record?.calendar_year_scheduled) : null,
		});
	};

	const handleSaveRecord = (values) => {
		let payload = null;
		let url = ''
		if(isStudent) {
			url = '/save-semester-notes'
			payload = {
				student_id: student_id,
				student_year: year_in_program,
			}
		} else {
			url = '/save-student-notes'
			payload = {
				student_notes: removeExtraSpaces(values.notes),
				student_id: student_id,
				student_sem_id: sem_id,
				student_year: year_in_program,
				student_advisor_id: semesterCourses?.student_advisor_id || userId,
				is_saved: 1,
				agreeCheckbox: 0,
				faculty_id: getCookie(COOKIE.UserId)
			}
		}
		fetchStaffApi(url, 'POST', payload, isStudent)
		.then((res) => {
			if (res?.code === "200") {
				message.success('Note saved successfully!');
				notesRef.current = removeExtraSpaces(values.notes);
				setDisableButton(true);
				form1.resetFields();
				getSemCourses()
			} else {
				message.error('Failed to save note. Please try again later.');
			}
		});
	};

	const handleSaveCourse = (values) => {
		setLoading(true);
		let courseSem =
			modalTitle === constants.Fall ? 1 :
				modalTitle === constants.Spring ? 2 :
					summerYear;

		const calendarYearScheduled = values.year ? values.year.year() : undefined;
		const isExisting = selectedOption?.is_taken === 1;
		const isCore = selectedOption?.id === "34" || selectedOption?.course_type === 0;
		const isFreeElective = selectedOption?.course_type === "2";
		const isSLP = selectedOption?.course_type === "1" || selectedOption?.course_type === "3";


		let payload = {
			course_year: parseInt(modalYearID),
			course_semester: courseSem,
			student_id: student_id,
			course_credit: parseInt(values.course_credit),
			status: values.status,
			is_taken: selectedOption?.is_taken,
			course_type: selectedOption?.course_type
		};

		if (isCore) {
			payload.id = selectedOption?.id;
			payload.core_name = removeExtraSpaces(values?.core_name);
			payload.area = selectedOption?.area;
			if (isExisting) {
				payload.core_curriculum_id = selectedOption?.core_curriculum_id;
			}
		} else if (isFreeElective) {
			if (isExisting) {
				payload.course_code = selectedOption?.course_code;
				payload.slp_id = selectedOption?.slp_id;
			} else {
				payload.id = selectedOption?.id;
				payload.course_code = selectedOption?.course_code;
				payload.course_text = removeExtraSpaces(values?.core_name);
				payload.section_number = values?.number_of_sections;
			}
		} else if (isSLP) {
			payload.id = selectedOption?.id;
			payload.course_code = selectedOption?.course_code;
			payload.core_name = removeExtraSpaces(values?.core_name);
			payload.section_number = values.number_of_sections;
			if (isExisting) {
				payload.slp_id = selectedOption?.slp_id;
			}
		}

		payload.calendar_year_scheduled = calendarYearScheduled;
		
		fetchStaffApi(`/add-semester-course`, 'POST', payload, isStudent)
		.then((res) => {
				if (res?.code === "200") {
					message.success(constants.CourseSaved);
					getSemCourses();
					handleCancel();
				} else {
					message.error(constants.CourseFailedSave);
				}
		})
		.finally(() => {
			setLoading(false);
		});
	};

	const handleDeleteRecord = (id, courseType) => {
		let payload = null;
		payload = {
			id: id,
			course_type: courseType,
		};
		fetchStaffApi(`/delete-semester-course`, 'POST', payload, isStudent)
			.then((res) => {
				if (res?.code === "200") {
					message.success(constants.deleteCourse);
					getSemCourses();
				} else {
					message.error(constants.DiscontinueAPIError);
				}
			});
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		form2.resetFields();
	};

	const handleEditCancel = () => {
		setIsEditModalOpen(false);
		setSelectedRecord(null);
		setIsSemesterApproved(false);
	};

	const handleApprove = (semester, year) => {
		let payload = null;
		payload = {
			student_id: student_id,
			faculty_id: userId,
			studentPreviousSem: semester,
			studentPreviousYear: year
		};

		fetchStaffApi(`/approve-previous-courses`, 'POST', payload)
			.then((res) => {
				if (res?.code === "200") {
					message.success(constants.CourseApproved);
					getSemCourses();
				} else {
					message.error(constants.CourseFailedToApprove);
				}
			});
	};

	const handleUpdateRecord = (values) => {
		setLoading(true);
		let payload = null;
		let isCourseTextRequired = selectedRecord?.area_id && (selectedRecord?.course_type === 0 || selectedRecord?.course_type === 2)
		payload = {
			id: selectedRecord?.id,
			status: values.status,
			course_type: selectedRecord?.course_type,
			core_name: isCourseTextRequired ? selectedRecord?.course_text : '',
			grade: values?.grade || null,
			year: values?.calendar_year_scheduled ? dayjs(values?.calendar_year_scheduled).format('YYYY') : null,
		};
		
		fetchStaffApi(`/edit-semester-course`, 'POST', payload, isStudent)
		.then((res) => {
			if (res?.code === "200") {
				message.success(constants.EditSuccMsg);
				getSemCourses();
				handleEditCancel();
			} else {
				message.error(constants.EditAPIError);
			}
		}).catch(() => {
			setLoading(false);
		});
	};

	const pdfActions = (type) => {
		const payload = {
			generate_pdf: type,
			student_id: student_id,
		};

		let apiFetch = fetchDownloadApi
		if(type === 2) {
			apiFetch = fetchApi
			payload.faculty_id = userId
		}
		apiFetch(`/student-semester-courses`, 'POST', payload).then((res) => {
			setShowGenerateCoursePlanPopup(false)
			if(type === 2 && res?.code) { //IF USER GENERATES PDF SHOW ONLY MESSAGE
				let notifType = res?.code === '200' ? 'success' : 'error';
				message?.[notifType](res?.message);
				getSemCourses()
				return
			}
			if(res) { //IF USER EXPORTS PDF OR GENERATES INITIAL COURSE PLAN
				const blob = new Blob([res], { type: 'application/pdf' });
				const url = URL.createObjectURL(blob);
				window.open(url, '_blank');
			}
		});
	};

	const renderOptGroup = (label, courseType) => {
		const filteredItems = [...areaItems, ...courseNameItems].filter(item => item.course_type === courseType);

		if (filteredItems.length === 0) {
			return null;
		}

		return (
			<OptGroup label={label}>
				{filteredItems.map(item => (
					<Option
						courseType={item.course_type}
						courseCode={item.course_code}
						isTaken={item.is_taken}
						courseCredit={item.course_credit}
						key={item.id}
						value={item.area || item.course_name}
					>
						<span className={item.is_taken === 1 ? 'text-red-500' : ''}>
							{item.area || item.course_name}
						</span>
					</Option>
				))}
			</OptGroup>
		);
	};

	const handleSelectChange = (_, obj) => {
		let payload = {
			area: obj?.value,
			id: obj?.key,
			course_type: obj?.courseType,
			course_code: obj?.courseCode,
			is_taken: obj?.isTaken,
			course_credit: obj?.courseCredit
		}
		setSelectedOption(payload);
		form2.setFieldsValue({
			course_credit: obj?.courseCredit || '',
			status: undefined
		});
	};

	const onChangeNotes = (value) => {
		const currentValue = value
		setDisableButton(removeExtraSpaces(currentValue) === notesRef.current);
	}

	const filteredData = selectedOption?.area
		? semCoursesDataPopup.filter(item => item.area === selectedOption?.area || item.course_name === selectedOption?.area)
		: [];
	const areaItems = semCoursesDataPopup.filter(item => item.area);
	const courseNameItems = semCoursesDataPopup.filter(item => !item.area);

	const renderSemesterTable = (item, yearObject, index = 0) => {
		let initialSemester = semesterCourses?.is_initial_semester || false
		let yearInProgram = `${constants.FullYearList.find((it) => parseInt(it.value) === yearObject.year)?.label} Year`
		// Function to calculate total course_credit excluding records with status 1
		const calculateTotalCredits = () => {
			return item?.data
				.filter(record => record.status !== 1) // Exclude records with status 1
				.reduce((total, record) => total + record.course_credit, 0); // Sum up the course_credit values
		};
		// Function to calculate total course_credit excluding records with status equal to 1 or 3
		const totalCreditsInt = () => {
			return item?.data
				.filter(record => record.status !== 1 && record.status !== 3) // Exclude records with status 1 and 3
				.reduce((total, record) => total + record.course_credit, 0); // Sum up the course_credit values
		};
		//APPROVED ICON
		let approveTitle = item?.approved_by_name && item?.approved_date && `${item?.approved_by_name} on ${item?.approved_date}`
		const checkIcon = () =>  (
			<Tooltip title={approveTitle}>
				<CheckOutlined className='green-text h5 mb-0 mt-1' />
			</Tooltip>
		)
		//APPROVE ICON
		const approveIcon = () => (
			<Popconfirm
				title="Approve Course"
				description={constants.ApproveCourse}
				onConfirm={() => handleApprove(item?.semester, yearObject?.year)}
				okText="Yes"
				cancelText="No"
			>
				<LikeOutlined className='h5 mb-0' />
			</Popconfirm>
		)
		//PLUS ICON
		const plusIcon = () => (
			<PlusCircleOutlined 
				className='cursor-pointer h5 mb-0 mt-1' 
				onClick={() => getSemCoursesPopup(
					getSemesterTitle(),
					item?.semester_year,
					yearInProgram,
					yearObject?.year,
					constants.Summer,
					item?.semester,
				)} />
		)
		//FUNCTION TO GET SEMESTER TITLE FOR TABLE HEADER
		const getSemesterTitle = () => {
			const semester = constants.SemType.find((sem) => parseInt(sem.semester_id) === item?.semester)?.semester_name
			return semester
		}
		//FUNCTION TO NOT SHOW SEMESTER STRING IF SEMESTER IS SUMMER SESSIONS
		const getSemesterYearTitle = () => {
			if(item?.semester === 1 || item?.semester === 2) {
				return `Semester ${item?.semester_year || ''}`
			}
			return item?.semester_year || ''
		}
		//FUNCTION TO SHOW/HIDE PLUS ICON
		const showPlusIcon = () => {
			if(locationData?.is_not_logged_in) return false //F NOT LOGGED IN THEN DONT SHOW PLUS
			if(locationData?.isArchived) return false //IF ARCHIVED DONT ALLOW TO ADD
			if(item?.is_semester_approved) return false //IF SEMESTER IS APPROVED DON'T SHOW
			if(initialSemester || semesterCourses?.student_type === '6') return true //IF INITIAL SEMESTER OR STUDENT IS PART TIME
			if(item?.can_add || (item?.can_add && item?.is_summer)) return true //IF CAN ADD OR IF CAN ADD IN SUMMER THEN SHOW PLUS
			return false
		}
		//FUNCTION TO SHOW/HIDE APPROVE ICON
		const showApproveIcon = () => {
			if(locationData?.isArchived) return false //IF ARCHIVED DONT ALLOW TO APPROVE
			if(isStudent) return false //IF STUDENT THERE WONT BE APPROVE
			if(item?.is_semester_approved) return false //IF SEMESTER APPROVED DONT SHOW APPROVE
			if(item?.can_approve && item?.data?.length > 0) return true //IF NOT APPROVED AND THERE IS DATA, SHOW APPROVE
			return false
		}
		//TABLE COLUMNS
		const columns = [
			{
				title: 
					<Space>
						{`${getSemesterTitle()} ${getSemesterYearTitle()}`}
						{item?.is_semester_approved && checkIcon()}
						{showPlusIcon() && plusIcon()}
						{showApproveIcon() && approveIcon()}
					</Space>,
				dataIndex: 'course_name',
				key: 'fallSem',
				width: '50%'
			},
			{
				title: 'Credits',
				dataIndex: 'course_credit',
				key: 'credits',
				width: '15%',
				align: 'right',
				render: (text, record) => {
					const displayText = record.status === 1 ? 0 : text;
					return <span>{record.status === 3 ? `(${displayText})` : displayText}</span>;
				},
			},
			{
				title: 'Section',
				dataIndex: 'section_number',
				key: 'section',
				align: 'right',
				render: (_, record) => <span>{record?.section_number ? getSectionNo(record?.section_number) : '-'}</span>
			},
			{
				title: 'Grade',
				dataIndex: 'grade',
				key: 'grade',
				render: (text) => <span>{text?.trim() ? constants.gradeMapping[text] : '-'}</span>,
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				width: '5%',
				render: (_, record) => (
					<Space size='small'>
						{/* HIDE DELETE BUTTON IF SEMESTER IS APPROVED */}
						{!item?.is_semester_approved && 
						<Popconfirm
							title={constants.RecordLostForever}
							description={constants.DeleteRecord}
							onConfirm={() => handleDeleteRecord(record?.id, record?.course_type)}
							okText="Yes"
							cancelText="No"
						>
							<Button icon={<DeleteOutlined />} type='text' />
						</Popconfirm>}
						<Button 
							icon={<SettingOutlined />} 
							type='text' 
							onClick={() => getEditSemCoursesPopup(
								getSemesterTitle(), 
								yearInProgram, 
								yearObject?.year,
								null,
								record,
								item, 
							)} />
					</Space>
				),
			}
		];

		return (
			<div>
				<Table dataSource={item?.data} columns={columns} scroll={{ x: 375 }} locale={locale} className="records-table" size='small' pagination={false} rowClassName={record => `status-${record.status}`}
					summary={() => {
						if (item?.data.length === 0) return null
						return (
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
								<Table.Summary.Cell index={1} align='right'><span><strong>{totalCreditsInt()} ({calculateTotalCredits()})</strong></span></Table.Summary.Cell>
								<Table.Summary.Cell index={2} colSpan={2}></Table.Summary.Cell>
							</Table.Summary.Row>
						);
					}}
				/>
			</div>
		);
	}

	const checkSubmitDisabled = () => {
		if(!isStudent) return disableButton
		if(!semesterCourses?.note) return true
		if(!semesterCourses?.students_submitted && !studentNoteApproved) return true
		if(semesterCourses?.students_submitted && studentNoteApproved) return true
		return false
	}

	const generateCoursePlanPDF = (allowAPICall = false) => {
		if(allowAPICall === false && !semesterCourses?.are_all_sems_filled) {
			setShowGenerateCoursePlanPopup(true)
			return
		}
		//API CALL GOES HERE
		pdfActions(2);
	}

	const downloadInitialPlan = () => {
		let coursePlan = semesterCourses?.initial_plan_pdf
		if(!coursePlan) return
		//API CALL GOES HERE
		pdfActions(3);
	}

	const renderSemesters = (item, index) => {
		let yearInProgram = constants.StudentYearList.find((it) => parseInt(it.value) === item.year).label
		return (
			<div className='border border-primary p-2 mb-3' key={index}>
				<Card
					className={`mb-3 ${item?.is_student_current_year ? 'current-year' : 'gray-title'}`}
					size='small'
					title={
						<div className='text-center h5 text-primary-pale mb-0'>
							<Space>
								{`${yearInProgram}`}
								<Popover content={legends} placement="right">
									<InfoCircleFilled className='cursor-pointer' />
								</Popover>
							</Space>
						</div>
					}>
					<Row gutter={[16, 16]}>
						{
						item?.semesters?.filter((sem) => !sem.is_summer)?.map((s, ind) => (
							<Col xs={24} lg={12} key={ind}>
								{renderSemesterTable(s, item, index)}
							</Col>
						))
							// item?.semesters?.slice(0, 2)?.map((sem, ind) => (
							// 	<Col xs={24} lg={12} key={ind}>
							// 		{renderSemesterTable(sem, item, index)}
							// 	</Col>
							// ))
						}
					</Row>
				</Card>
				<Card className='mb-0'>
					<div className='text-center h5 text-primary-pale'>
						<Space>Summer Semester
							<Popover content={legends} placement="right">
								<InfoCircleFilled className='cursor-pointer' />
							</Popover>
						</Space>
					</div>
					<Row gutter={[16, 16]}>
						{
							item?.semesters?.filter((sem) => sem.is_summer)?.map((s, ind) => (
								<Col xs={24} lg={12} key={ind}>
									{renderSemesterTable(s, item, index)}
								</Col>
							))
							// item?.semesters?.slice(2, 4)?.map((sem, ind) => (
							// 	<Col xs={24} lg={12} key={ind}>
							// 		{renderSemesterTable(sem, item, null)}
							// 	</Col>
							// ))
						}
					</Row>
				</Card>
			</div>
		)
	}

	const generateCoursePlanPopup = () => {
		let footer = [
			<Button 
				key="save" 
				disabled={loading} 
				onClick={preventPDFGeneration ? () => setShowGenerateCoursePlanPopup(false) : () => generateCoursePlanPDF(true)} 
				loading={loading} 
				className='btn-info'
			>
				{preventPDFGeneration ? constants.GoBack : constants.Yes}
			</Button>
		]

		if(!preventPDFGeneration) {
			footer.unshift(
				<Button onClick={() => setShowGenerateCoursePlanPopup(false)} key='back' type='primary' danger>
					{constants.Close}
				</Button>
			)
		}
		return (
			<Modal
				open={showGenerateCoursePlanPopup}
				width={700}
				title={constants.Warning}
				footer={footer}
				onCancel={() => setShowGenerateCoursePlanPopup(false)}
				// onOk={() => generateCoursePlanPDF(true)}
			>
				<p>
					You have not added courses to one or more semesters. Kindly update the same. <br />
					Once the initial course plan is generated it cannot be changed.
					{!preventPDFGeneration && ` ${constants.WouldStillLikeToContinue}`}
				</p>
			</Modal>
		)
	}

	return (
		<Spin tip={constants.PleaseWait} spinning={loading}>
			<div>
				<div className='text-end mb-2'>
					{(!isStudent && !isArchived) && 
					<Button type='primary' onClick={() => pdfActions(1)}>
						Export PDF
					</Button>}
				</div>
				{
					semesterCourses?.data?.length > 0 && 
					semesterCourses?.data.map((item, index) => (
						renderSemesters(item, index)
					))
				}
				{<div className='border border-primary p-2'>
					<Form onFinish={handleSaveRecord} form={form1} initialValues={{ notes: notesRef.current }}>
						<Card className='grey-card mb-3'>
							<div className='d-flex justify-content-between align-items-center mb-2'>
								<span className='h5 mb-0'>Notes</span>
								<Space>
									Click on history to see all notes.
									<Button className='btn-info' size='small' onClick={() => navigate("/course-plan-notes", { state: { student_id: student_id, student_name: student_name, locationData: locationData } })}>{constants.History}</Button>
								</Space>
							</div>
							<div>
								<Form.Item name="notes" rules={[{ required: isStudent ? false : true, message: 'Please enter a note' }]}>
									{
										isStudent ? 
										<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(semesterCourses?.note || '') }} /> : 
										<TextArea rows={4} onChange={(e) => onChangeNotes(e.target.value)} />
									}
								</Form.Item>
								{isStudent && 
								<Checkbox 
									disabled={semesterCourses?.students_submitted}
									checked={studentNoteApproved}
									onChange={(e) => setStudentNoteApproved(e.target.checked)}
								>
									I acknowledge that I have read this note.
								</Checkbox>}
							</div>
						</Card>
						<div className='d-flex align-items-center justify-content-end'>
							<Space>
								{/* SHOW COURSE PLAN ACTION BUTTON IF NOT STUDENT, IF INITIAL SEM AND IF NON-TRANSFER STUDENT */}
								{
								!isStudent &&
									<Button 
										className='btn-info'
										onClick={semesterCourses?.initial_plan_pdf ? () => downloadInitialPlan() : () => generateCoursePlanPDF()} 
									>{semesterCourses?.initial_plan_pdf ? constants.InitialCoursePlan : constants.GeneratePDF}
									</Button>
								}
								<Button 
									className='btn-info' 
									onClick={() => form1.submit()} 
									disabled={checkSubmitDisabled()}
								>{isStudent ? constants.Submit : constants.SaveNote}
								</Button>
							</Space>
						</div>
					</Form>
				</div>}
				<Modal
					title={<span>{modalYearTitle} - {`${modalTitle} ${modalYearSummer ? modalYearSummer : ''} Semester ${modalYear ? modalYear : ''}`}</span>}
					width={600}
					open={isModalOpen}
					onCancel={handleCancel}
					footer={[
						<Button key="back" onClick={handleCancel} type='primary' danger>
							{constants.Close}
						</Button>,
						<Button key="save" disabled={loading} onClick={() => form2.submit()} loading={loading} className='btn-info'>
							{constants.Save}
						</Button>,
					]}
				>
					<Form
						autoComplete="off"
						layout='vertical'
						form={form2}
						onFinish={handleSaveCourse}
					>
						<Form.Item
							label={<span>{constants.Areas} / {constants.Courses}</span>}
							rules={[
								{
									required: true,
									message: 'This field is required.',
								},
							]}
							name="course_area"
						>
							<Select
								allowClear
								showSearch
								className="w-100"
								placeholder="Search to Select"
								optionFilterProp="children"
								filterOption={filterOptionChild}
								onChange={(e, f) => handleSelectChange(e, f)}
							>
								{renderOptGroup('Area', 0)}
								{renderOptGroup('Slp Undergraduate', '1')}
								{renderOptGroup('Free Electives', '2')}
								{renderOptGroup('Slp Graduate', '3')}
							</Select>
						</Form.Item>
						{selectedOption?.area && filteredData.length > 0 && (
							<>
								{selectedOption?.area && semCoursesDataPopup.find(item => item.area === selectedOption?.area) && (
									<Form.Item
										rules={[
											{
												required: true,
												message: 'This field is required.',
											},
										]}
										name="core_name"
									>
										<Input placeholder={constants.CourseName} />
									</Form.Item>
								)}
								<Row gutter={[8, 0]}>
									<Col lg={7}>
										<Form.Item
											label={constants.Year}
											rules={[
												{
													required: true,
													message: 'This field is required.',
												},
											]}
											className='mb-0'
											name="year"
										>
											<DatePicker
												picker="year"
												className='w-100'
												disabled={!filteredData.some(item => item.year)} // Disable if year is not present
												value={filteredData.length > 0 ? moment(filteredData[0].year, 'YYYY') : undefined} // Set value if year data exists
											/>
										</Form.Item>
									</Col>
									{filteredData.some(item => item.number_of_sections) && (
										<Col lg={5}>
											<Form.Item
												label={constants.Section}
												rules={[
													{
														required: true,
														message: 'This field is required.',
													},
												]}
												className='mb-0'
												name="number_of_sections"
											>
												<Select
													showSearch
													className='w-100'
													optionFilterProp="label"
													filterOption={filterOption}
													placeholder={constants.Sections}
												>
													{filteredData?.length > 0 && getAllSections(filteredData[0]?.number_of_sections).map((item) => (
														<Select.Option label={item.name.toString()} key={item.id} value={item.id}>{item.name}</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									)}
									<Col lg={8}>
										<Form.Item
											label={constants.Status}
											rules={[
												{
													required: true,
													message: 'This field is required.',
												},
											]}
											className='mb-0'
											name="status"
										>
											<Select
												showSearch
												className='w-100 status-select'
												optionFilterProp="label"
												filterOption={filterOption}
												onChange={handleStatusChange}
												placeholder={constants.Status}
												options={statusOptions()}
											/>
										</Form.Item>
									</Col>
									<Col lg={4}>
										{selectedOption?.area && semCoursesDataPopup.find(item => item.area === selectedOption.area) ? (
											<Form.Item
												label={constants.Credits}
												rules={[
													{
														required: true,
														message: 'This field is required.',
													},
												]}
												className='mb-0'
												name="course_credit"
											>
												<Select
													showSearch
													className='w-100'
													optionFilterProp="label"
													filterOption={filterOption}
													placeholder={constants.Credits}
													disabled={isCourseCreditDisabled}
													options={courseCreditOptions()}
												/>
											</Form.Item>
										) : (
											<Form.Item
												label={constants.Credits}
												className='mb-0'
												name="course_credit"
											>
												<Input value="" disabled />
											</Form.Item>
										)}
									</Col>
								</Row>
							</>
						)}
					</Form>
				</Modal>
				{/* Edit records */}
				<Modal
					title={<span><strong className='font-semibold'>Edit : </strong> {modalYearTitle} - {`${modalTitle} ${modalYearSummer ? modalYearSummer : ''} Semester ${modalYear ? modalYear : ''}`}</span>}
					width={900}
					open={isEditModalOpen}
					onCancel={handleEditCancel}
					footer={[
						<Button key="back" onClick={handleEditCancel} type='primary' danger>
							{constants.Close}
						</Button>,
						<Button key="save" disabled={loading} onClick={() => form3.submit()} loading={loading} className='btn-info'>
							{constants.Save}
						</Button>,
					]}
				>
					<Form
						autoComplete="off"
						form={form3}
						onFinish={handleUpdateRecord}
					>
						<Form.Item
							label={<span>{constants.Areas} / {constants.Courses}</span>}
							name="course_area"
							{...formItemsHorizontal}
							className='!mb-3'
						>

							<span className='text-lg'>
								{selectedRecord?.area ? selectedRecord.area : selectedRecord?.course_name}
							</span>
						</Form.Item>
						<Row gutter={[8, 0]}>
							{selectedRecord?.course_type === 0 ? (
								<Col lg={16}>
									<Form.Item
										label={constants.CourseName}
										rules={[
											{
												required: true,
												message: 'This field is required.',
											},
										]}
										{...formItemsHorizontal}
										name="course_text"
									>
										<Input placeholder={constants.CourseName} />
									</Form.Item>
								</Col>
							) : null}
							<Col lg={8}>
								<Form.Item
									label={constants.Status}
									rules={[
										{
											required: true,
											message: 'This field is required.',
										},
									]}
									name="status"
									{...formItemsHorizontal}
								>
									<Select
										showSearch
										className='w-100 status-select'
										optionFilterProp="label"
										filterOption={filterOption}
										onChange={handleStatusChange}
										value={selectedRecord?.status}
										options={statusOptions()}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Card className='text-center'>
							<Card.Grid hoverable={false} className='!w-1/4'>
								<Form.Item
									label={constants.Year}
									className='!mb-0'
									name="calendar_year_scheduled"
								>
									<DatePicker picker="year" />
								</Form.Item>
							</Card.Grid>
							<Card.Grid hoverable={false} className='!w-1/4'>
								<Form.Item
									label={constants.Section}
									className='!mb-0'
									name="number_of_sections"
									{...formItemsInline}
								>
									<span className='text-lg'>{getSectionNo(selectedRecord?.section_number)}</span>
								</Form.Item>
							</Card.Grid>
							<Card.Grid hoverable={false} className='!w-1/4'>
								<Form.Item
									label={constants.Credits}
									className='!mb-0'
									name="course_credit"
									{...formItemsInline}
								>
									{selectedRecord?.status !== 1 ? (
										<span className='text-lg'>{selectedRecord?.course_credit}</span>
									) : 0}
								</Form.Item>
							</Card.Grid>
							<Card.Grid hoverable={false} className='!w-1/4'>
								<Form.Item
									label={constants.Grade}
									className='!mb-0'
									name="grade"
									{...formItemsInline}
								>
									<Select
										showSearch
										allowClear
										className='w-100'
										placeholder={constants.Grade}
										filterOption={filterSelectOption}
									>
										{constants.GradeDropDown.map((val) => <Option key={val?.value} value={val?.value}>{val?.label}</Option>)}
									</Select>
								</Form.Item>
							</Card.Grid>
						</Card>
					</Form>
				</Modal>
				{/* Generate Course Plan PDF Popup */}
				{showGenerateCoursePlanPopup && generateCoursePlanPopup()}
			</div>
		</Spin>
	)
}

export default SemesterCourses