import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Table, Input, Tabs, Collapse, Form, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import constants from '../../Constants/constants';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { getModal, showModal, closeModal, CustomSortIcon, getNextAndPreviousButtons, showTotalItems, handleRowSelect, performSearch, HighlightedText, sortTwoNumbers } from '../../GlobalFunctions/GlobalFunctions';
import { Link, useNavigate } from 'react-router-dom';
import { fetchStaffApi, fetchApi } from '../../Services/Api';
import dayjs from 'dayjs';
import ModalContent from '../AssigningMentors/ModalContent';

function getCustomSortIcon(sortOrder) {
	return <CustomSortIcon sortOrder={sortOrder} />
}

const AssigningMentor = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const initialYearTypesRef = useRef([]);
	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showReAssignModal, setShowReAssignModal] = useState(false);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
	const [tabId, setTabId] = useState('1');
	const [panelKey, setPanelKey] = useState('1');
	const [studentsAssigned, setStudentsAssigned] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [formValues, setFormValues] = useState({ student_type: 0, student_year: 0 });
	const [semesterType, setSemesterType] = useState();
	const [allMentorsExceptSelectedOne, setAllMentorsExceptSelectedOne] = useState([]);
	const [newMentorId, setNewMentorId] = useState();
	const [isMentorChanged, setIsMentorChanged] = useState(false);
	const [semesterData, setSemesterData] = useState([]);
	const [filteredStudentsAssigned, setFilteredStudentsAssigned] = useState([]);
	const [newSearchText, setNewSearchText] = useState('');
	const [modifiedYearTypes, setModifiedYearTypes] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
	const [filteredData,setFilteredData] = useState([]);
	const [allMentorData,setAllMentorData]=useState([]);
	const [rowKeysModal, setRowKeysModal] = useState([]);
	const [rowDataModal, setRowDataModal] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [loading,setLoading] = useState(false);

	const yearTypes = {
		'1': constants.Freshman,
		'2': constants.Sophomore,
		'3': constants.Junior,
		'4': constants.Senior,
		'5': constants.Graduate,
		'6': constants.PartTime
	}
	const breadcrumbItems = [
		{
			label: constants.AssigningMentors,
			active: true
		}
	];
	useEffect(() => {
		if (panelKey && tabId) {
			getMentorDetails();
		}
	}, [panelKey, tabId])
	const getAllTheMentorsExceptTheSelectedOne = () => {
		let payload = null;
		payload = {
			value: tabId === '1' ? 2 : tabId === '2' ? 1 : 3,
			semester: fetchSemesterValue()
		}
		if(rowKeys){
			payload.mentor_id=rowKeys[0]
		}
		fetchStaffApi(`/get-all-mentors`, 'POST', payload).then((res) => {
			if (res?.data?.length>0) {
				setAllMentorsExceptSelectedOne(res?.data);
			} else {
				setAllMentorsExceptSelectedOne([])
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	useEffect(() => {
		getSemesterDetails();
		setNewMentorId(null);
		setRowDataModal([]);
		setRowKeysModal([]);
	}, [])
	useEffect(() => {
		if (initialYearTypesRef.current.length === 0) {
			// Create a Set to store unique year_in_program values
			const uniqueYearTypes = new Set(studentsAssigned.map(student => student.year_in_program));
			// Convert the Set to an array of objects with labels
			const yearType = Array.from(uniqueYearTypes)
			.filter(year_in_program => year_in_program !== "6")
			.map(year_in_program => ({
				year_in_program,
				label: yearTypes[year_in_program]
			}))
			initialYearTypesRef.current = yearType;
		}
		setModifiedYearTypes(initialYearTypesRef.current);
	}, [studentsAssigned]);

	useEffect(() => {
		if (showReAssignModal === true) {
			getAllTheMentorsExceptTheSelectedOne();
		}
	}, [showReAssignModal])
	const getSemesterDetails = () => {
		fetchApi(`/semester-data`, 'GET').then((res) => {
			if (res?.data) {
				res?.data.length === 1 ? setSemesterType(0) : setSemesterType(2);
				setSemesterData(res?.data);
			}
		});
	}
	//Display all the mentors(Main page UI)
	const getMentorDetails = () => {
		let payload = null;
		payload = {
			value: tabId === '1' ? 2 : tabId === '2' ? 1 : 3,
			semester: fetchSemesterValue()
		}
		fetchStaffApi(`/get-all-mentors`, 'POST', payload).then((res) => {
			if(res?.data?.length>0){
				setFilteredData(res.data);
				setAllMentorData(res.data);
			}else{
				setFilteredData([]);
				setAllMentorData([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const getPanelStyle = (key) => {
		return {
			marginBottom: key === '1' ? 10 : 8,
			background: '#3c8dbc',
			borderRadius: '5px',
		}
	}
	const getTabId = (activeKey) => {
		setSearchText("");
		setFilteredData([]);
		setAllMentorData([]);
		setRowKeys([]);
		setRowData([]);
		setTabId(activeKey);
	}
	const tabColumnsOne = [
		{
			title: constants.Student,
			key: '1',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => { return a.student_name.localeCompare(b.student_name) },
			render: (record) => {
				return <HighlightedText text={record?.student_name} highlight={searchText} />
			},
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
		},
		tabId === '1'?
		{
			title: 'Sent On',
			key: '2',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => dayjs(a.student_mentor_mail_date).unix() - dayjs(b.student_mentor_mail_date).unix(),
			render: (record) => {
				if(record?.student_mentor_mail_date) {
					const dateSent = record?.student_mentor_mail_date ? dayjs(record.student_mentor_mail_date).format(constants.DateFormat) : "";
					return <HighlightedText text={dateSent} highlight={searchText} />
				}
				return '-'
			},
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
		}
		:
		{
			title: 'Rejected On',
			sorter: (a, b) => dayjs(a.mentor_response_date).unix() - dayjs(b.mentor_response_date).unix(),
			key: '2',
			showSorterTooltip: { placement: 'bottom' },
			render: (record) => {
				if(record?.mentor_response_date) {
					const dateRejected =record?.mentor_response_date? dayjs(record?.mentor_response_date).format(constants.DateFormat):"";
					return <HighlightedText text={dateRejected} highlight={searchText} />
				}
				return '-'
			},
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
		}
	].filter(Boolean);
	const tabColumnsTwo = [
		{
			title: constants.Mentor,
			key: '1',
			sorter: (a, b) => { return a.student_name.localeCompare(b.student_name) },
			showSorterTooltip: { placement: 'bottom' },
			render: (record) => { return <HighlightedText text={record?.student_name} highlight={searchText} /> },
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
		},
		{
			title: constants.YearProgram,
			key: '2',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => yearTypes[a.year_in_program].localeCompare(yearTypes[b.year_in_program]),
			render: (record) => { return <HighlightedText text={yearTypes[record?.year_in_program]} highlight={searchText} /> },
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
		},
		{
			title: constants.StudentsAssigned,
			key: '3',
			dataIndex:"students_assigned",
			render: (students_assigned) => (
				students_assigned?.length > 0 ? 
				students_assigned?.map(item => (
					<div key={item.year_in_program}>
						<b><HighlightedText text={item.year_in_program} highlight={searchText} /></b>: <HighlightedText text={item.students} highlight={searchText} />
					</div>
				)) : '-'
			),
		},
		{
			title: 'Accepted On',
			key: '4',
			dataIndex:'mentor_response_date',
			sorter: (a, b) => sortTwoNumbers(a,b,"mentor_response_date",true),
			showSorterTooltip: { placement: 'bottom' },
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render:(dateAccept)=>dayjs(dateAccept).format(constants.DateFormat)
		}
	]
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null,selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)
  };
	const rowSelection = {
		fixed: 'left',
		columnWidth: '2px',
    selectedRowKeys: rowKeys,
    onSelect: (record, selected, selectedRows) => onSelectChange( 
			// selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			null,
			null,
			record, //CURRENT SELECTED RECORD
			filteredData?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? filteredData:[]
			onSelectChange(
			data?.map((item) => item.id),
			data,
			null,
			null,
			true
		)},
  };
	const tableColumns = [
		{
			title: constants.Student,
			key: '1',
			// width: "30%",
			align: 'left',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => { return a.name.localeCompare(b.name) },
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
			render: (record) => { return <HighlightedText text={record?.name} highlight={newSearchText} /> },
		},
		{
			title: constants.YearProgram,
			key: '2',
			align: 'left',
			// width: '25%',
			showSorterTooltip: { placement: 'bottom' },
			render: (record) => { return <HighlightedText text={yearTypes[record?.year_in_program]} highlight={newSearchText} /> },
			sorter: (a, b) => yearTypes[a.year_in_program].localeCompare(yearTypes[b.year_in_program]),
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
		},
		{
			title: constants.StudentType,
			key: '3',
			// width: '20%',
			showSorterTooltip: { placement: 'bottom' },
			render: (record) => { return <HighlightedText text={record?.student_type_value} highlight={newSearchText} /> },
			align: 'left',
			sorter: (a, b) => { return a.student_type_value.localeCompare(b.student_type_value) },
			sortIcon: ({ sortOrder }) => getCustomSortIcon(sortOrder),
		}
	]
	const onSearch = (e) => {
		const search = e.target.value;
		setSearchText(e.target.value);
		const searchFieldNames = [
			"student_name",
			"year_in_program_text",
			"student_mentor_mail_date",
			"students_assigned",
			"mentor_response_date",
		];
		let searchedValue = performSearch(search, allMentorData, searchFieldNames, tabId === "2");
		setFilteredData(searchedValue);
	};
	const handleAssignSectionFormChange = (changedValues, values) => {
		setFormValues(values);
		getAllMentorStudents(values, 1);
	};
	const handleReAssignSectionFormChange = (changedValues, values) => {
		setFormValues(values);
		getAllMentorStudents(values, 2);
	};
	const handleViewSectionFormChange = (changedValues, values) => {
		setFormValues(values);
		getAllMentorStudents(values, 3);
	};

	//Get list of students to be assigned/reassigned  to selected  mentor(Assign and ReAssign modal content)
	const getAllMentorStudents = (values, type) => {
		let payload = null;
		payload = {
			year: values?.student_year?values?.student_year:0,
			type: values?.student_type?values?.student_type:0 ,
			semester: fetchSemesterValue()
		}
		if(rowKeys&&type!==1){
			payload.mentor_id=rowKeys[0];
		}
		let url = ``;
		let functionKey = "";
		if(type===1){
			url = `/get-all-students-without-mentor`;
			functionKey = () => fetchStaffApi(url, 'POST',payload);
		}else{
			url = `/get-mentor-students`;
			functionKey = () => fetchApi(url, 'POST',payload);;
		}
		functionKey().then((res) => {
			if (res?.data?.length>0&&Array.isArray(res?.data)){
				setStudentsAssigned(res?.data);
				setFilteredStudentsAssigned(res?.data);
			} else {
				setStudentsAssigned([]);
				setFilteredStudentsAssigned([]);
			}
			showModal(type === 1 ? setShowAssignModal : type === 2 ? setShowReAssignModal : setShowViewModal);
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const fetchSemesterValue = ()=>{
		let semesterValue = null;
		if (semesterType === 2) {
			if (panelKey === '2') {
				semesterValue = 2;
			} else if (panelKey === '1') {
				semesterValue = 0;
			}
		} else {
			semesterValue = 0;
		}
		return semesterValue
	}
	//Display buttons tabwise
	const getTopLayout = (id) => {
		let content;
		const disabledButton = rowKeys.length > 1 || rowKeys.length === 0;
		const disabledBtns = rowKeys.length === 0;
		const disabledReAssignBtn = rowData && (rowData[0]?.students_assigned === null || rowKeys.length === 0 || rowKeys.length > 1);
		const disableViewBtn = rowData&&rowData[0]?.students_assigned === null;
		if (id === '1') {
			content = (
				<div className="d-flex">
					<Link to='/send-email' state={{ mentorDetails: rowData,type:"Pending",semester:fetchSemesterValue()?.toString() }}>
						<Button className='text-sm btn-info no-padding' disabled={disabledBtns}>
							{constants.Email}
						</Button>
					</Link>

					<Link to='/notification-add' state={{ mentorDetails: rowData }}>
						<Button className='ms-2 text-sm btn-info  no-padding' disabled={disabledBtns}>
							{constants.Notification}
						</Button>
					</Link>
				</div>
			)
		} else if (id === '2') {
			content = (
				<div className="d-flex">
					<Button className='text-sm btn-info no-padding' disabled={disabledButton} onClick={() => getAllMentorStudents(formValues, 1)}>{constants.AssignBtnLabel}</Button>
					<Button className='ms-2 text-sm btn-info no-padding' disabled={disabledReAssignBtn}
						onClick={() => getAllMentorStudents(formValues, 2)}>{constants.ReAssignBtnLabel}</Button>
					<Button className='text-sm btn-info no-padding ms-2' onClick={() => getAllMentorStudents(formValues, 3)} disabled={disabledButton||disableViewBtn} >{constants.ViewBtnLabel}</Button>
					<Button disabled={disabledButton} className='text-sm btn-info ms-2 no-padding' onClick={() => changeStatus('accepted')}>{constants.ChangeStatus}</Button>
					<Link to='/send-email' state={{ mentorDetails: rowData,type:"Accepted",semester:fetchSemesterValue()?.toString() }}>
						<Button className='text-sm btn-info  ms-2 no-padding' disabled={disabledBtns}>
							{constants.Email}
						</Button>
					</Link>
					<Link to='/notification-add' state={{ mentorDetails: rowData }}>
						<Button className='ms-2 text-sm btn-info  no-padding' disabled={disabledBtns}>
							{constants.Notification}
						</Button>
					</Link>
				</div>
			);
		} else if (id === '3') {
			content = (
				<div className="d-flex">
					<Button className='text-sm btn-info no-padding' disabled={disabledButton} onClick={() => changeStatus('rejected')}>{constants.ChangeStatus}</Button>
					<Link to='/send-email' state={{ mentorDetails: rowData,type:"Rejected",semester:fetchSemesterValue()?.toString() }}>
						<Button className='text-sm btn-info no-padding  ms-2' disabled={disabledBtns}>
							{constants.Email}
						</Button>
					</Link>
					<Link to='/notification-add' state={{ mentorDetails: rowData }}>
						<Button className='ms-2 text-sm btn-info  no-padding' disabled={disabledBtns}>
							{constants.Notification}
						</Button>
					</Link>
				</div>
			);
		} else {
			content = <div></div>;
		}
		return content;
	}
	const redirectToSendEmailOrAssignMentor = (record) =>{
		if(tabId==="2"){
			getAllMentorStudents(formValues, 1)
		}else{
			let row=[];
			row.push(record);
			navigate(`/send-email`, { state: { mentorDetails: row } })
		}
	}
	const getMainLayout = () => {
		return (
			<div>
				<div className="d-flex justify-content-between mb-2">
					{getTopLayout(tabId)}
				</div>
				<div>
					<Table
						onRow={(record, rowIndex) => {
							return {
								onClick: () => {onSelectChange(null, null, record, rowIndex)
									setRefresh(!refresh)},
								onDoubleClick: () => {
									onSelectChange(null, null, record, rowIndex)
									setRefresh(!refresh)
									redirectToSendEmailOrAssignMentor(record)
								},
							}
						}}
						rowSelection={rowSelection}
						rowKey={(record) => record?.id}
						columns={tabId === '1' || tabId === '3' ? tabColumnsOne : tabColumnsTwo}
						className='records-table data_table'
						dataSource={filteredData}
						bordered
						size='small'
						pagination={{
							pageSize: constants.TablePageSize,
							showTotal: (total, range) => showTotalItems(total, range, filteredData),
							itemRender: getNextAndPreviousButtons,
						}}
					>
					</Table>
				</div>
			</div>
		)
	}
	const getTabsAndTableData = () => {
		return (
			<Tabs
				bordered
				type="card"
				size={'small'}
				activeKey={tabId}
				tabBarExtraContent={
					<Input
						onChange={onSearch}
						value={searchText}
						type='search'
						placeholder={constants.Search}
						className='w-48'>
					</Input>
				}
				className='newTab'
				onChange={getTabId}
				items={new Array(3).fill(null).map((_, i) => {
					const id = String(i + 1);
					let label = '';
					if (id === '1') {
						label = constants.PendingRequests;
					} else if (id === '2') {
						label = constants.AcceptedRequests;
					} else if (id === '3') {
						label = constants.RejectedRequests;
					}
					return {
						label: label,
						key: id,
						children: getMainLayout(),
					};
				})}
			/>
		)
	}
	const getSemesterType = (key) => {
		setRowData([]);
		setSearchText("");
		setRowKeys([]);
		setPanelKey(key[0]);
	}
	const getSemester = (arr, key) => {
		let sem;
		const semester1 = arr[0]?.semester_type;
		const semester2 = arr[1]?.semester_type;
		if (key === 1) {
			sem = semester2 === '1' ? 'Fall' : semester2 === '2' ? 'Spring' : semester2 === '3' ? 'Summer Session I' : semester2 === '4' ? 'Summer Session II' : ''
		} else {
			sem = semester1 === '1' ? 'Fall' : semester1 === '2' ? 'Spring' : semester1 === '3' ? 'Summer Session I' : semester1 === '4' ? 'Summer Session II' : ''
		}
		return sem;
	}
	const getItemsBasedOnSemester=()=>{
		const items = [
			{
				key: '1',
				label: <span className="text-sm text-light">{`Current Semester (` + getSemester(semesterData, 1) + `)`}</span>,
				children: getTabsAndTableData(),
				style: getPanelStyle('1'),
			},
		];

		if(semesterData?.length>1){
			items.push({
				key: '2',
				label: <span className="text-sm text-light">{(getSemester(semesterData, 2))}</span>,
				children: getTabsAndTableData(),
				style: getPanelStyle('2'),
			})
			return items;
		}
		return items;
	}
	const saveMentorDetails = (type) => {
		setLoading(true);
		let payload;
		payload = {
			mentor_id: isMentorChanged ? newMentorId : rowKeys?rowKeys[0]:null,
			semester: fetchSemesterValue(),
			student_id: rowKeysModal.map(id => parseInt(id)),
		}
		if(type===1){
			payload.mentor_id=rowKeys?rowKeys[0]:null;
		}else{
			payload.mentor_id=newMentorId;
		}
		fetchStaffApi(`/save-student-mentor`, 'POST', payload).then((res) => {
			if (res?.code === '200') {
				closeModal(type === 1 ? setShowAssignModal : setShowReAssignModal);
				message.success(constants.DataUpdated);
				form.resetFields();
				setNewMentorId(null);
				setRowKeys([]);
				setRowKeysModal([]);
				setRowDataModal([]);
				setNewMentorId(null);
				setRowData([]);
				setLoading(false);
				form.resetFields();
				getMentorDetails();
				setFormValues({ student_type: 0, student_year: 0 })
			} else {
				message.error(constants.Error);
				closeModal(type === 1 ? setShowAssignModal : setShowReAssignModal);
				form.resetFields();
				setRowKeys([]);
				setRowDataModal([]);
				setNewMentorId(null);
				setRowData([]);
				setNewMentorId(null);
				setLoading(false);
				getMentorDetails();
				form.resetFields();
				setFormValues({ student_type: 0, student_year: 0 })
			}
			setLoading(false);
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
	const changeStudentStatus = (currentTabId) => {
		setLoading(true);
		let payload = null;
		payload = {
			mentor_id: rowKeys&&rowKeys.map(int=>parseInt(int)),
			value: currentTabId === '2' ? 3 : 1,
		}
		fetchStaffApi(`/change-mentor-status`, 'POST', payload).then((res) => {
			if (res?.code === '200') {
				setLoading(false);
				message.success(constants.DataUpdated);
			} else {
				setLoading(false);
				message.error(constants.Error);
			}
			setLoading(false);
			closeModal(setShowChangeStatusModal);
			setRowKeys([]);
			setRowData([]);
			getMentorDetails();
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
	const changeStatus = (type) => {
		showModal(setShowChangeStatusModal);
	}
	const getModalTitle = (type) => {
		let title = '';
		let studentName = rowData&&rowData[0]?.student_name;
		switch (type) {
			case 1:
				title = `${constants.AssignStudentsToMentors} - ${studentName}`;
				break;
			case 2:
				title = `${constants.ReAssignStudentsFromMentors} - ${studentName}`;
				break;
			case 3:
				title = `${constants.ViewStudentsAssignedToMentors} - ${studentName}`;
				break;
			case 4:
				title = constants.Warning
				break;
			default:
				break;
		}
		return title;
	}

	const clearKeys = () => {
		setRowKeys([]);
		setRowData([]);
		form.resetFields();
		//setFormValues({ student_type: 0, student_year: 0 })
		setFormValues(prevState => ({ ...prevState, student_type: 0, student_year: 0 }));
		initialYearTypesRef.current = [];
		setModifiedYearTypes([]);
	}

	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.AssigningMentors} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom position-relative mentorCard">
				{
					semesterType === 2
						?
						<Collapse 
							onChange={getSemesterType} 
							size='small' 
							accordion 
							items={getItemsBasedOnSemester()} 
							expandIcon={({ isActive }) => <CaretDownOutlined className="text-light fs-6" rotate={isActive ? 180 : 0} />} 
						/>
						:
						getTabsAndTableData()
				}
			</Card>
			{
				(showAssignModal || showReAssignModal || showViewModal || showChangeStatusModal)
				&& getModal(
					getModalTitle(showAssignModal ? 1 : showReAssignModal ? 2 : showViewModal ? 3 : 4),
					<ModalContent
						type={showAssignModal ? 1 : showReAssignModal ? 2 : showViewModal ? 3 : 4}
						form={form}
						closeModal={closeModal}
						showAssignModal={showAssignModal}
						handleAssignSectionFormChange={handleAssignSectionFormChange}
						tableColumns={tableColumns}
						saveMentorDetails={saveMentorDetails}
						showReAssignModal={showReAssignModal}
						handleReAssignSectionFormChange={handleReAssignSectionFormChange}
						allMentorsExceptSelectedOne={allMentorsExceptSelectedOne}
						showViewModal={showViewModal}
						handleViewSectionFormChange={handleViewSectionFormChange}
						studentsAssigned={studentsAssigned}
						id={tabId}
						changeStudentStatus={changeStudentStatus}
						setShowChangeStatusModal={setShowChangeStatusModal}
						selectedRecord={rowData&&rowData[0]}
						filteredStudentsAssigned={filteredStudentsAssigned}
						newSearchText={newSearchText}
						setNewSearchText={setNewSearchText}
						setFilteredStudentsAssigned={setFilteredStudentsAssigned}
						setSelectedRowKeys={setRowKeys}
						modifiedYearTypes={modifiedYearTypes}
						rowKeysModal={rowKeysModal}
						setRowKeysModal={setRowKeysModal}
						setIsMentorchanged={setIsMentorChanged}
						setmentorId={setNewMentorId}
						newMentorId={newMentorId}
						rowDataModal={rowDataModal}
						setRowDataModal={setRowDataModal}
						loading={loading}
					/>,
					(showAssignModal || showReAssignModal || showViewModal || showChangeStatusModal),
					closeModal,
					(showAssignModal ? setShowAssignModal : showReAssignModal ? setShowReAssignModal : showViewModal ? setShowViewModal : setShowChangeStatusModal), () => clearKeys(),
				)
			}
		</div>
	)
}
export default AssigningMentor