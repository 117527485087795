import { Button } from "antd";
import constants from "../../../Constants/constants";
import printJS from "print-js";

const gridHeaderStyle = 'color: black;  border: 1px solid #D3D3D3; font-weight:bold; font-size:10px;'
const gridStyle = 'border: 1px solid #D3D3D3; text-align:center; padding: 5px; font-size:x-small; white-space: nowrap;'
const style = '@page { size: landscape; }'

function printHeader(text) {
  return `<h3 class="custom-h3">${text}</h3>`
}

//disabled: TRUE OR FALSE
//data: MAKE SURE THE DATA PROP SENDS THE DATA IN SORTED ORDER AFTER USING FILTERS/SORTERS
//columns: COLUMNS ARRAY MAKING SURE IT HAS PROPERTIES -> columnName(label of the column) and dataIndex(key of the column)
//currentPage: CURRENT PAGE(for now optional)
const Print = ({ disabled, data, columns, currentPage, type, headerTitle }) => {
  const handlePrint = () => {
    let columnNames = columns?.map(({ columnName, dataIndex }) => ({ displayName: columnName, field: dataIndex }))
    let filteredColumnNames = columnNames?.find((col) => col.field === 'student_image') ? columnNames?.slice(1, columnNames?.length) : columnNames

		printJS({
			printable: data,
			properties: filteredColumnNames,
			gridHeaderStyle: gridHeaderStyle,
			gridStyle: gridStyle,
			type: type,
			style: style,
      header: printHeader(headerTitle),
		})
  }

  return (
    <Button
      className="text-capitalize btn-info"
      disabled={disabled}
      onClick={() => handlePrint()}
    >{constants.Print}
    </Button>
  );
}
 
export default Print;