import React,{useState} from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import { Card,Space,Button,Collapse,Table} from 'antd'
import constants from '../../Constants/constants';
import {getNextAndPreviousButtons} from '../../GlobalFunctions/GlobalFunctions';

function Courses() {

    const breadcrumbItems = [
        { label: 'Student Course List', href: '/student-course-list', active: false },
        { label: 'Courses', href: '/', active: true },]

    const [dataSource,] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        type: 'radio',
        selectedRowKeys,
        columnWidth: '5%',
        onChange: (selectedRowKeys) => {
          setSelectedRowKeys(selectedRowKeys);
        },
      };
    
    const CoreCurColumns =[
        {
            title: 'Area',
            dataIndex: 'area',
        },
        {
            title: 'Course',
            dataIndex: 'course',
        },
        {
            title: 'Credits',
            dataIndex: 'credits',
        },
        {
            title: 'Semester Schedule',
            dataIndex: 'semester_schedule',
        },
    ]
    const CoreCurriculum=()=>{
        <Table rowSelection={{
            ...rowSelection,
        }} 
        columns={CoreCurColumns} 
        dataSource={dataSource} 
        bordered size="small" 
        className="records-table data_table" rowSelectedBg='#e6f4ff' 
        pagination={{
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
        itemRender: getNextAndPreviousButtons,
        }} 

        scroll={{x:'50vh'}}
        />
    }
    

  return (
    <div>
        <BreadcrumbHeader pageTitle="Courses" breadcrumbItems={breadcrumbItems} />
         
        <Card className="container-border-custom">
        
        <div className="box-color adjust-margin d-flex justify-content-between">
            <Space wrap>
                <div className=' adjust-margin d-flex flex-column'>
                    <div >Student Name:</div>  
                    <div>{constants.MuEmail}:</div> 
                </div>
            </Space>

            <Button className="text-capitalize btn-info w-10" >Export PDF</Button>
        </div>

        
        <Collapse className='collapse-color adjust-margin'
        collapsible="header"
        items={[
            {
            key: '1',
            label: 'Core Curriculum',
            children: <div className="mt-2">{CoreCurriculum}</div>
            },
        ]}
        />
        <Collapse className='collapse-color adjust-margin'
        collapsible="icon"
        items={[
            {
            key: '2',
            label: 'SLP Undergraduate Requirements',
            children: <p>{}</p>,
            },
        ]}/>
        <Collapse className='collapse-color adjust-margin'
        collapsible="icon"
        items={[
            {
            key: '3',
            label: 'Free Electives',
            children: <p>{}</p>,
            },
        ]}/>
        <Collapse className='collapse-color adjust-margin'
        collapsible="icon"
        items={[
            {
            key: '4',
            label: 'SLP Graduate Requirements',
            children: <p>{}</p>,
            },
        ]}/>
        <Collapse className='collapse-color adjust-margin'
        collapsible="icon"
        items={[
            {
            key: '5',
            label: 'Assessments',
            children: <p>{}</p>,
            },
        ]}/>
        
       
        </Card>
    </div>
  )
}

export default Courses