import React, { useState, useEffect } from 'react'
import { Col, Button, Table, Flex, message, Tooltip } from 'antd'
import constants from '../../../Constants/constants'
import { fetchStaffApi, fetchStaffDownloadApi } from '../../../Services/Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs'
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import { getNextAndPreviousButtons, showTotalItems } from '../../../GlobalFunctions/GlobalFunctions'
import PropTypes from 'prop-types';

const OtherHistoryArchived = ({ displayModal, closeModal, studentId, isActive }) => {
	const [listData, setListData] = useState([])
	const [,setLoading] = useState(false)
	const getListData = () => {
		setLoading(true)
		fetchStaffApi(`/other-history-notes-list/${studentId}`, 'GET')
			.then((res) => {
				if (res?.data) {
					setListData(res.data)
				} else {
					setListData([]);
				}
				setLoading(false)
			})
			.catch((error) => {
			});
	}
	const downloadData = (download) => {
		fetchStaffDownloadApi(`/download-notes-documents/${download.id}`, 'GET')
			.then((res) => {
				if (res) {
					let fileExtension = download.upload_name.split('.').pop().toLowerCase();
					// Determine the appropriate MIME type based on the file extension
					let mimeType;
					switch (fileExtension) {
						case 'png':
							mimeType = 'image/png';
							break;
						case 'jpg':
						case 'jpeg':
							mimeType = 'image/jpeg';
							break;
						case 'pdf':
							mimeType = 'application/pdf';
							break;
						case 'doc':
						case 'docx':
							mimeType = 'application/msword';
							break;
						case 'xls':
						case 'xlsx':
							mimeType = 'application/vnd.ms-excel';
							break;
						default:
							mimeType = 'application/octet-stream';
					}
					let url = window.URL.createObjectURL(new Blob([res], { type: mimeType }));
					let a = document.createElement("a");
					a.download = download?.upload_name;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
					URL.revokeObjectURL(url);
				} else {
					message.error("Failed to download");
				}
			})
			.catch((error) => {
				message.error("Failed to download");
			});
	};
	const columnsHistoryTable = [{
		title: constants.FileName,
		dataIndex: 'upload_name',
    ellipsis:true,
		width: '40%',
    render:(text)=>(     
      <Tooltip title={text}>{text}</Tooltip>
    ),
	},
	{
		title: constants.Date,
		dataIndex: 'dt_created',
		render: (dt_created) => <>{dayjs(dt_created).format(constants.DateFormat)}</>,
		width: '30%'
	},
	{
		title: constants.UploadedBy,
		dataIndex: `uploaded_by_faculty`,
		width: '30%'
	},
	{
		title: constants.Actions,
		render: (record) => (
			<Flex align='center' justify='center'>
				<Tooltip title={constants.Download}>
          <Button type="text" icon={<FontAwesomeIcon icon={faDownload} className='text-primary-pale' />} onClick={() => downloadData(record)} />
        </Tooltip>
			</Flex>
		),
		width: '10%'
	},
	]
	useEffect(() => {
		getListData()
	}, [])
	const otherHistoryTable=()=>(
		<Col span={24}>
		<Table
			columns={columnsHistoryTable} dataSource={listData} bordered size="small" rowKey={(record) => record.key}
			className="records-table data_table" rowSelectedBg='#e6f4ff' searchableProps={{ fuzzySearch: true }}
			scroll={{ x: '50vh' }}
			pagination={{
				pageSize: 10,
				showTotal: (total, range) => showTotalItems(total, range, listData),
				itemRender: getNextAndPreviousButtons,
			}}
		/>
	</Col>
	)
	return (
			otherHistoryTable()
	)
}
OtherHistoryArchived.propTypes = {
	studentId: PropTypes.number.isRequired,
}
export default OtherHistoryArchived