import React, { useState, useEffect } from 'react';
import { Card, Table, Tabs, Input, Tooltip, Spin } from 'antd';
import constants from '../../Constants/constants';
import { getGpaLevelsAndModifyDataFormat, CustomSortIcon, getNextAndPreviousButtons, showTotalItems, performSearch, HighlightedText } from '../../GlobalFunctions/GlobalFunctions';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { fetchStaffApi } from '../../Services/Api';
import { createCookie } from '../../Services/Cookie';
import { useNavigate } from 'react-router-dom';

const GpaHistory = () => {
	const [tabId, setTabId] = useState('1');
	const [gpaData, setGpaData] = useState([]);
	const [gpaValues,setGpaValues] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [gpaSearchData, setGpaSearchData] = useState([]);
	const [maxPartTimeYear, setMaxPartTimeYear] = useState("");
	const [partTimeGpaWithoutYear, setPartTimeGpaWithoutYear] = useState([]);
	const [filteredPartTimeGpaWithoutYear, setFilteredPartTimeGpaWithoutYear] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const breadcrumbItems = [
		{
			label: constants.GpaHistory,
			active: true
		}
	];
	useEffect(()=>{
		getGpaLevelsAndModifyDataFormat(setGpaValues);
	},[])
	useEffect(() => {
		getYearwiseGpaData();
	}, [tabId])
	const getYearwiseGpaData = () => {
		setLoading(true);
		let payload = null;
		payload = { semester:0 }
		if(tabId === "6"){
			payload.is_part_time = true;
		}else{
			payload.year = Number(tabId);
		}
		fetchStaffApi(`/gpa-history`, 'POST', payload).then((res) => {
			if (res?.data) {
				setLoading(false);
				let data;
				if(tabId === "6"){
					data = res?.data?.withYearInProgram?.map((item) => ({
						...item,
						statusText:statusTypes[item?.status]
					}));
					setMaxPartTimeYear(res?.data?.max_year);
					const modifiedResponse = res?.data?.withoutYearInProgram?.map((item) => ({
						...item,
						statusText:statusTypes[item?.status]
					}));
					setPartTimeGpaWithoutYear(modifiedResponse);
					setFilteredPartTimeGpaWithoutYear(modifiedResponse);
				}else{
					data = res?.data;
					data = res?.data?.map((item) => ({
						...item,
						statusText:statusTypes[item?.status]
					}));
					setMaxPartTimeYear("");
					setPartTimeGpaWithoutYear([]);
					setFilteredPartTimeGpaWithoutYear([]);
				}
				setGpaData(data);
				setGpaSearchData(data); 
			}else{
				setLoading(false);
				setGpaData([]);
				setGpaSearchData([]);
				setMaxPartTimeYear("");
				setPartTimeGpaWithoutYear([]);
				setFilteredPartTimeGpaWithoutYear([]);
			}
		});
	}

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchText(search);
		const searchFieldNames = [
			'student',
			'fifth_fall_gpa',
			'fifth_spring_gpa',
			'fifth_summer_gpa_one',
			'fifth_summer_gpa_two',
			'fourth_fall_gpa',
			'fourth_spring_gpa',
			'fourth_summer_gpa_one',
			'fourth_summer_gpa_two',
			'third_fall_gpa',
			'third_spring_gpa',
			'third_summer_gpa_one',
			'third_summer_gpa_two',
			'second_fall_gpa',
			'second_spring_gpa',
			'second_summer_gpa_one',
			'second_summer_gpa_two',
			'first_fall_gpa',
			'first_spring_gpa',
			'first_summer_gpa_one',
			'first_summer_gpa_two',
			'statusText',
			"student_gpas",
		];
		let searchedValue = performSearch(search, gpaSearchData, searchFieldNames);
		let searchedValueWithoutYear = performSearch(search, partTimeGpaWithoutYear, searchFieldNames);
		setGpaData(searchedValue);
		setFilteredPartTimeGpaWithoutYear(searchedValueWithoutYear);
	};
	const getAdditionalColumns = (tabId) => {
		const columns = [];
		const tabNumber = tabId === "6" ? Number(maxPartTimeYear): Number(tabId);
		for (let i = 1; i <= tabNumber; i++) {
			let dataIndexPrefix;
			switch (i) {
				case 1:
					dataIndexPrefix = 'first';
					break;
				case 2:
					dataIndexPrefix = 'second';
					break;
				case 3:
					dataIndexPrefix = 'third';
					break;
				case 4:
					dataIndexPrefix = 'fourth';
					break;
				case 5:
					dataIndexPrefix = 'fifth';
					break;
				default:
					break;
			}
			const tabLabel = constants[`Tab${i}`];
			const children = [
				...(gpaData.some(data => data[`${dataIndexPrefix}_fall_gpa`] !== null && tabId === "6") || tabId !== "6"?[
				{
					title: <div style={{ textAlign: 'left' }}>Fall</div>,
					key: `fall_${i}`,
					align: 'right',
					dataIndex: `${dataIndexPrefix}_fall_gpa`,
					showSorterTooltip: { placement: 'bottom' },
					render: (text, record) => {
						// Compare with gpaValues based on year_in_program
						const correspondingGpa = gpaValues.find(item => item.year_in_program === i.toString());
						if (!correspondingGpa) return <span>{text?.trim() ? text : '-'}</span>; // Return original text if no corresponding GPA found
						const isLess = parseFloat(text) < parseFloat(correspondingGpa.fallGpa);
						return(
							<Tooltip title={tabId === "6" ? record?.[`${dataIndexPrefix}_fall_year`]:""}>
								<span style={{ color: isLess ? 'red' : 'black' }}>
									{text?.trim() ? <HighlightedText text={parseFloat(text).toFixed(2)} highlight={searchText} /> : '-'}
								</span>
							</Tooltip>
						)
					},
					sorter: (a, b) => a[`${dataIndexPrefix}_fall_gpa`] - b[`${dataIndexPrefix}_fall_gpa`],
					sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
				}]:[]),
				...(gpaData.some(data => data[`${dataIndexPrefix}_spring_gpa`] !== null && tabId === "6") || tabId !== "6" ? [
					{
						title: <div style={{ textAlign: 'left' }}>Spring</div>,
						key: `spring_${i}`,
						align: 'right',
						dataIndex: `${dataIndexPrefix}_spring_gpa`,
						showSorterTooltip: { placement: 'bottom' },
						render: (text, record) => {
							// Compare with gpaValues based on year_in_program
							const correspondingGpa = gpaValues.find(item => item.year_in_program === i.toString());
							if (!correspondingGpa) return <span>{text?.trim() ? text : '-'}</span>; // Return original text if no corresponding GPA found
							const isLess = parseFloat(text) < parseFloat(correspondingGpa.springGpa);
							return (
								<Tooltip title={tabId === "6" ? record?.[`${dataIndexPrefix}_spring_year`]:""}>
									<span style={{ color: isLess ? 'red' : '#000' }}>
										{text?.trim() ? <HighlightedText text={parseFloat(text).toFixed(2)} highlight={searchText} /> : '-'}
									</span>
								</Tooltip>
							) 
						},
						sorter: (a, b) => a[`${dataIndexPrefix}_spring_gpa`] - b[`${dataIndexPrefix}_spring_gpa`],
						sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
					}] : []),
				...(gpaData.some(data => data[`${dataIndexPrefix}_summer_gpa_one`] !== null ) ? [
					{
						title:<div style = {{ textAlign: 'left' }}>Summer Session 1</div>,
						key: `summer_session_1_${i}`,
						align: 'right',
						dataIndex: `${dataIndexPrefix}_summer_gpa_one`,
						showSorterTooltip: { placement: 'bottom' },
						render:(value,record)=><Tooltip title={tabId === "6" ? record?.[`${dataIndexPrefix}_summer_gpa_one_year`]:""}> <HighlightedText text={value?value:'-'} highlight={searchText} /></Tooltip>,
						sorter: (a, b) => a[`${dataIndexPrefix}_summer_gpa_one`] - b[`${dataIndexPrefix}_summer_gpa_one`],
						sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
					}
				] : []),
				...(gpaData.some(data => data[`${dataIndexPrefix}_summer_gpa_two`] !== null) ? [
					{
						title: <div style={{ textAlign:'left'}}>Summer Session 2</div>,
						key: `summer_session_2_${i}`,
						align: 'right',
						dataIndex: `${dataIndexPrefix}_summer_gpa_two`,
						showSorterTooltip: { placement: 'bottom' },
						sorter: (a, b) => a[`${dataIndexPrefix}_summer_gpa_two`] - b[`${dataIndexPrefix}_summer_gpa_two`],
						sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
						render:(value,record)=> (
							<Tooltip title={tabId === "6" ? record?.[`${dataIndexPrefix}_summer_gpa_two_year`]:""}>
								{value?.trim() ? <HighlightedText text={value?value:'-'} highlight={searchText} /> : '-'}
							</Tooltip>
						)
					}
				] : [])
			];
			columns.push({
				title:tabLabel,
				key: `${i}`,
				children,
			});
		}
		return columns;
	};
	const statusTypes = {
		'1': constants.Dismissed,
		'2': constants.Probation,
		'3': constants.LeftProgram,
		'4': constants.Break,
		'5': constants.InActive,
		'6': constants.SlpMajor,
		'9': constants.Undeclared,
		'10': 'Graduated',
	};
	const HighlightGPA = ({ input }) => {
		// Split the input string into parts where each part is either a semester or a GPA value
		const parts = input.split(/(\s*-\s*|\s*,\s*)/);
		return (
			<div>
				{parts.map((part, index) => {
					// Check if the part is a GPA value by matching the format
					const isGPA = /^\d+\.\d+$/.test(part);
					// Return the part with bold styling if it's a GPA value
					return (
						<span key={index} style={{ fontWeight: isGPA ? 'bold' : 'normal' }}>
							{part}
						</span>
					);
				})}
			</div>
		);
	};

	const columns = (column_type) => {
		// Define the base columns
		const baseColumns = [
			{
				title: constants.Student,
				key: '1',
				showSorterTooltip: { placement: 'bottom' },
				sorter: (a, b) => a?.student.localeCompare(b?.student),
				render: (record) => {
					const studentName = record?.student || '-';
					const theValue = statusTypes[record?.status] || '';
					return (
						<span style={{ color: theValue === 'Dismissed' || theValue === 'Probation' ? 'red' : 'black' }}>
							<HighlightedText text={studentName?studentName:'-'} highlight={searchText} />
						</span>
					);
				},
				sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
				onCell: (record) => ({
					onClick: () => redirectToStudentEdit(record),
				}),
			},
			{
				title: constants.Status,
				key: '3',
				showSorterTooltip: { placement: 'bottom' },
				sorter: (a, b) => statusTypes[a.status].localeCompare(statusTypes[b.status]),
				render: (record) => {
					const statusLabel = record?.statusText || '';
					const highlightedStatus = statusLabel.toLowerCase().includes(searchText.toLowerCase())
						? statusLabel.replace(new RegExp(`(${searchText})`, 'gi'), '<span class="highlight">$1</span>')
						: statusLabel;
					return (
						<span
							style={{ color: statusLabel === 'Dismissed' || statusLabel === 'Probation' ? 'red' : '#000' }}
							dangerouslySetInnerHTML={{ __html: highlightedStatus?highlightedStatus:'-' }}
						></span>
					);
				},
				sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			},
		];
		const studentGpa = {
			title: constants.Gpa,
			key: '2',
			dataIndex:"student_gpas",
			showSorterTooltip: { placement: 'bottom' },
			render:(_, record) => {
				return <HighlightedText text={record?.student_gpas?record?.student_gpas:"-"} highlight={searchText}>
					<HighlightGPA input={record?.student_gpas?record?.student_gpas:'-'} />
				</HighlightedText>
			}
		}
		// Conditionally add the additional columns if column_type is not "withoutYear"
		if (column_type !== "withoutYear") {
			baseColumns.splice(1, 0, ...getAdditionalColumns(tabId));
		}else{
			baseColumns.splice(1, 0, studentGpa);
		}
	
		return baseColumns;
	};
	const getTabId = (activeKey) => {
		setSearchText("");
		setTabId(activeKey);
	};

	const withoutYearInProgramTable = (
		<Table
			columns={columns("withoutYear")}
			dataSource={filteredPartTimeGpaWithoutYear}
			bordered
			className='records-table mt-2'
			scroll={{ x: 768, y: 'calc(100vh - 342px)' }}
			size="small"
			pagination={{
				pageSize: constants.TablePageSize,
				showTotal: (total, range) => showTotalItems(total, range, filteredPartTimeGpaWithoutYear),
				itemRender: getNextAndPreviousButtons,
			}}
		/>
	)

	const redirectToStudentEdit = (record) => {
		createCookie("facultyId", record?.faculty_id, null, "/"); //FIELD REQUIRED
		navigate("/student-information", {
			state: { 
				student_id: record?.id,
				student_semester: record?.semester, //FIELD REQUIRED
				from: constants.GpaHistory,
				from_route: "/gpa-history",
				tabkey: "StudentDetails",
				tab_title: constants.StudentDetails,
				student_name: record?.student,
				isArchived: record?.is_active === '3',
				year_in_program: record?.year_in_program,
			}
		});
	}

	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.GpaHistory} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom">
				<Spin tip={constants.PleaseWait} spinning={loading}>
					<Input
						onChange={onSearch}
						value={searchText}
						type='search'
						placeholder={constants.Search}
						className='w-full block lg:hidden mb-2'/>
					<Tabs
						bordered
						className='newTab'
						tabBarExtraContent={
							<Input
								onChange={onSearch}
								value={searchText}
								type='search'
								placeholder={constants.Search}
								className='w-48 hidden lg:block'/>
						}
						type="card"
						size='small'
						onChange={getTabId}
						items={new Array(6).fill(null).map((_, i) => {
							const id = String(i + 1);
							let label = '';
							switch (id) {
								case '1':
									label = constants.Freshman;
									break;
								case '2':
									label = constants.Sophomore;
									break;
								case '3':
									label = constants.Junior;
									break;
								case '4':
									label = constants.Senior;
									break;
								case '5':
									label = constants.Graduate;
									break;
								case '6':
									label = constants.PartTime;
									break;
								default:
									break;
							}
							return {
								label: label,
								key: id,
								children: gpaData.length > 0 ?
									<Table
										scroll={{ x: 768, y: 'calc(100vh - 342px)' }}
										columns={columns("withYear")}
										dataSource={gpaData}
										bordered
										className='records-table data_table gpaHistory'
										size="small"
										pagination={{
											pageSize: constants.TablePageSize,
											showTotal: (total, range) => showTotalItems(total, range, gpaData),
											itemRender: getNextAndPreviousButtons,
										}}
									/> : withoutYearInProgramTable
							};
						})}
					/>
					{
						filteredPartTimeGpaWithoutYear.length > 0 && gpaData.length > 0 ?
						withoutYearInProgramTable
							: null
					}
				</Spin>
			</Card>
		</div>
	)
}

export default GpaHistory
