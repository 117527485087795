import React, { useState, useEffect } from 'react'
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import { Modal, Card, Flex, Input, InputNumber, Table, Button, Checkbox, Col, Row, Form, Space, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import constants from '../../../Constants/constants';
import { fetchStaffApi } from '../../../Services/Api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CustomSortIcon, performSearch,removeExtraSpaces, HighlightedText,getNextAndPreviousButtons, showTotalItems, handleKeyPressHandler, handleRowSelect, checkEmptySpace} from '../../../GlobalFunctions/GlobalFunctions';

const ManageCourses = () => {
	const breadcrumbItems = [{ label: 'Areas - Courses', active: true },]

	const [dataSource, setDataSource] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDisconModal, setShowDisconModal] = useState(false);
	const [form] = Form.useForm();
	const [showDiscontinued, setShowDiscontinued] = useState(false);
	const [isDiscontinuedChecked, setIsDiscontinuedChecked] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [filterTableData, setFilterTableData] = useState([]);
	const [rowData,setRowData] = useState([]);
	const [noAshaReqCourse, setNoAshaReqCourse] = useState(0);
	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], recordData = null, index = null) => {
		if (newSelectedRowKeys?.length > 1) {
			newSelectedRowKeys = [newSelectedRowKeys[newSelectedRowKeys.length - 1]];
			selectedRowData = selectedRowData.slice(-1);
		}
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			recordData, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: selectedRowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setSelectedRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'single' //SELECTION TYPE
		)
  };

	const rowSelection = {
		type: 'checkbox',
		columnWidth: '5%',
		onChange: onSelectChange,
		selectedRowKeys: selectedRowKeys
	};
	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search)
		const searchFieldNames = [
			"area",
			"total_no_courses",
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setDataSource(searchedValue);
	};
	useEffect(() => {
		getAllAreaList();
	}, []);

	const formatData = (data) => {
		let arr = []

		data.forEach((item) => {
			let courseArr = []
			if (item.asha_required_courses && item.asha_required_courses !== "0") {
				courseArr = item.asha_course_name.split(",")
			}
			arr.push({ ...item, asha_course_name: courseArr })
		})
		return arr;

	}
	//fetching all area data list
	const getAllAreaList = (discontinue) => {
		setLoading(true)
		let payload = {
			type: "1",
			is_hidden: discontinue ? "1" : "0",
		}
		fetchStaffApi('/get-all-courses', 'POST', payload).then((res) => {
			if (res?.code !== "200") {
				message.error(res?.message)
				setDataSource([])
				setFilterTableData([]);
				setLoading(false)
				return
			}
			//adding key item for row selection
			const modifiedData = formatData(res?.data)
			setDataSource(modifiedData);
			setFilterTableData(modifiedData);
			setLoading(false)
		}).catch(() => setLoading(false));
	};

	const handleDoubleClick = (value) => {
		setRowData([value]);
		handleEdit(value);
	};
	
	const columns = [
		{
			title: constants.Areas,
			dataIndex: 'area',
			width:"50%",
			sorter: (a, b) => a.area.localeCompare(b.area),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (area,value) => <div onDoubleClick={()=>handleDoubleClick(value)}><HighlightedText text={area} highlight={searchValue} /></div>
		},
		{
			title: constants.TotalCourses,
			dataIndex: 'total_no_courses',
			width:"50%",
			sorter: (a, b) => a.total_no_courses - b.total_no_courses,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (total_no_courses) => (
				<Flex className="table-number-align numbers-alignment">
				  <HighlightedText text={total_no_courses} highlight={searchValue} />
				</Flex>
			)
		}
	]
	//Add 
	const handleAdd = () => {
		setRowData([]);
		form.resetFields();
		setShowAddModal(true);
	}
	const handleCloseAddModal = () => {
		setNoAshaReqCourse(0);
		setShowAddModal(false)
		form.resetFields();
	}
	const handleSaveRecord = (values) => {
		setButtonDisabled(true);
		const trimmedValues = values?.asha_req_course?.map((course) => (removeExtraSpaces(course)));
		const isAreaExists = dataSource.some(item => item.area.toLowerCase() === values.area.toLowerCase());
		if (isAreaExists) {
			setButtonDisabled(false);	
			message.error(constants.errorMessage)
			return;
		}

		const payload = {
			adminid: "1",
			type: "1",
			area: removeExtraSpaces(values.area),
			total_no_courses: values?.total_no_courses,
			asha_required_courses: values?.asha_req_course?.length,
			asha_course_name: trimmedValues
		};

		fetchStaffApi('/add-course', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setButtonDisabled(false);
				message.success(constants.successMessage)
				setShowAddModal(false);
				form.resetFields();
				getAllAreaList();
			}else{
				message.error(res?.message);
				setButtonDisabled(false);
			}
		});
	};

	//edit
	const handleEdit = (value) => {
		const isDiscontinued = value?.is_hidden === "1";
		setIsDiscontinuedChecked(isDiscontinued)
		setShowEditModal(true);
		form.setFieldsValue({
			area: value?.area,
			total_no_courses: value?.total_no_courses,
			asha_req_course: value?.asha_course_name,
		});
		setNoAshaReqCourse(value?.asha_required_courses ? value?.asha_required_courses: 0);
	}
	const handleCloseEditModal = () => {
		setNoAshaReqCourse(0);
		setShowEditModal(false);
		form.resetFields()
	};
	const handleDiscontinueCheckboxChange = (e) => {
		setIsDiscontinuedChecked(e.target.checked);
	}
	const handleUpdateRecord = (values, index) => {
		setButtonDisabled(true);
		const trimmedValues = values?.asha_req_course?.map((course) => (removeExtraSpaces(course)));
		const payload = {
			adminid: "1",
			type: "1",
			area_id: rowData?.[0].id ? rowData?.[0].id : rowData.area_id,
			total_no_courses: values.total_no_courses,
			asha_required_courses: values?.asha_req_course?.length,
			asha_course_name: trimmedValues,
			is_hidden: isDiscontinuedChecked ? "1" : "0",
		};

		fetchStaffApi('/edit-course', 'PUT', payload).then((res) => {
			if (res?.code === "200") {
				setButtonDisabled(false);
				message.success(constants.EditSuccMsg)
				handleCloseEditModal()
				setSelectedRowKeys([]);

				if (showDiscontinued) {
					getAllAreaList(true);
				} else {
					getAllAreaList();
				}
			}else{
				setButtonDisabled(false);
				message.error(res?.message)
			}
		});

	};
	//discontinue
	const handleDiscontinue = () => {
		setShowDisconModal(true)
	}
	const handleCloseDIsconModal = () => {
		setShowDisconModal(false)
	}
	const handleConfirmDiscontinue = () => {
		setButtonDisabled(true);
		const payload = {
			adminid: "1",
			type: "1",
			area_id: rowData?.[0].id,
		}
		// Call the API to discontinue the area
		fetchStaffApi('/delete-course', 'PUT', payload).then((res) => {
			if (res?.code === '200') {
				message.success(constants.DiscontinueSucessMessage);
				setButtonDisabled(false);
				setShowDisconModal(false);
				setSelectedRowKeys([]);
				getAllAreaList()

			} else {
				setButtonDisabled(false);
				message.error(constants.DiscontinueAPIError)
			}
		});
	}
	return (
		<Spin spinning={loading}>
			<div>
				<BreadcrumbHeader pageTitle={constants.AreasCourses} breadcrumbItems={breadcrumbItems} />
				<Card className="container-border-custom ">
					<Row gutter={[5, 5]}>
						<Col lg={12} xs={24} >
							<Space wrap>
								<Button className="text-capitalize btn-info"
									hidden={showDiscontinued}
									disabled={selectedRowKeys.length > 0}
									onClick={handleAdd}>{constants.Add}</Button>
								<Button className="text-capitalize btn-info"
									disabled={selectedRowKeys.length > 0 ? false : true}
									onClick={()=>handleEdit(rowData?.[0])}>{constants.Edit}</Button>
								<Button className="text-capitalize btn-info"
									hidden={ showDiscontinued}
									disabled={selectedRowKeys.length === 0 || rowData?.[0]?.area === "Core"}
									onClick={handleDiscontinue} >{constants.disconBtn}</Button>
							</Space>
						</Col>
						<Col lg={12} xs={24} className='d-flex justify-content-end'>
								<Row gutter={[5, 5]}>
									<Col className='checkbox-align' >
										<Checkbox onChange={(e) => {
											setShowDiscontinued(e.target.checked);
											setSelectedRowKeys([]);
											getAllAreaList(e.target.checked);
										}}>{constants.showDiscontinuedArea}
										</Checkbox>
									</Col>
									<Col>
										<Input className="w-48" placeholder="Search" onChange={onSearch} />
									</Col>
								</Row>
							</Col>
					</Row>
					<div className="mt-2">
						<Table rowSelection={{ ...rowSelection, hideSelectAll: true }}
							rowKey={(record) => record.id}
							hide
							columns={columns}
							dataSource={dataSource}
							bordered size="small"
							className="records-table data_table" rowSelectedBg='#e6f4ff'
							pagination={{
								className:"!mb-0",
								pageSize: constants.TablePageSize,
								showTotal: (total, range) => showTotalItems(total, range, dataSource),
								itemRender: getNextAndPreviousButtons,
							}}
							scroll={{ x: 768,y: 'calc(100vh - 295px)' }}
							rowClassName={(record) => record.area === 'Core' ? 'advisor-row' : ''}
							onRow={(record, rowIndex) => {
								return {
									onClick: () => onSelectChange(null, null, record, rowIndex),
								}
							}}
						/>
					</div>
				</Card>

				{/* Add edit modal */}
				<Modal open={rowData.length === 1 ? showEditModal : showAddModal}
					title={rowData.length === 1 ? `${constants.EditArea}${rowData?.[0]?.area}` : constants.AddNewArea} onCancel={rowData.length === 1 ? handleCloseEditModal : handleCloseAddModal}
					width={600}
					footer={null}>
					<Form form={form} layout="vertical" onFinish={rowData.length===1 ? handleUpdateRecord : handleSaveRecord}>
						<Row gutter={[16, 0]}>
							<Col xl={12} xs={24}>
								<Form.Item 
									name="area" 
									label={constants.Area} 
									rules={[{ 
										required: true, 
										message: constants.RequiredField,
										whitespace: true,
									}]}
								>
									<Input 
										className='w-100' 
										disabled={rowData.length === 1 ? rowData?.[0]?.area : false}
										onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('area'))}
									/>
								</Form.Item>
							</Col>

							<Col xl={12} xs={24}>
								<Form.Item name="total_no_courses" label={constants.TotalCourses} rules=
									{[{
										required: true,
										validator: (_, value) => {
											const totalAshaRequiredCourse = form.getFieldValue('asha_req_course');
											if (totalAshaRequiredCourse !== undefined) {
												if ((totalAshaRequiredCourse.length > value)) {
													return Promise.reject(constants.TotalNoCourseValMessage);
												}
											}
											if (value === undefined) {
												return Promise.reject(constants.RequiredField);
											}
											return Promise.resolve();
										},
									}]}
								>
									{
										rowData.length === 1 ? <InputNumber min={1} onKeyPress={(e) => handleKeyPressHandler(e, 2)} disabled={showDiscontinued} className='w-100'
											/> : <InputNumber onKeyPress={(e) => handleKeyPressHandler(e, 2)} min={1} className='w-100' />
									}
								</Form.Item>
							</Col>
							<Col xl={12} xs={24}>
								<Form.List
									name="asha_req_course"
									rules={[
										{
											validator: (_, value) => {
												const totalNoCourses = form.getFieldValue('total_no_courses');
												const area = form.getFieldValue('area');
												if ((!area && !totalNoCourses)) {
													return Promise.reject(constants.AshaMessage);
												}
												if (value !== undefined) {
													setNoAshaReqCourse(value.length);
												}
												return Promise.resolve();
											},
										},
									]}
								>
									{(fields, { add, remove }, { errors }) => (
										<>
											{fields.map((field, index) => (
												<Form.Item
													required={false}
													key={field.key}
												>
													<Space>
														<Form.Item
															{...field}
															validateTrigger={['onChange', 'onBlur']}
															rules={[
																{
																	required: true,
																	whitespace: true,
																	message: constants.FieldRequired,
																},
															]}
															noStyle
														>
															<Input
																placeholder={constants.AshaCourse}
																className='w-60'
																suffix={showEditModal && rowData?.[0]?.asha_course_name?.[field.name] ? "(ASHA)" : ""}
																readOnly={showDiscontinued}
															/>
														</Form.Item>
														<Button size='small' disabled={showDiscontinued} onClick={() => remove(field.name)} className='text-red-400 hover:!text-red-600 border-red-400 hover:!border-red-600' icon={<FontAwesomeIcon icon={faXmark} />} />
													</Space>
												</Form.Item>
											))}
											<Form.Item className='w-full'>
												<Button
													onClick={async () => {
														try {
															const totalNoCourses = form.getFieldValue('total_no_courses');
															const totalashaRequiredCourse = fields.length + 1;
															await form.validateFields();
															if (totalashaRequiredCourse <= totalNoCourses) {
																add();
															} else {
																message.error(constants.AshaMessage);
															}
														} catch (err) {
															// await form.validateFields(["asha_req_course"]);
															console.log('Validation failed:', err);
														}
													}}
													icon={<PlusOutlined />}
													block
													className='text-capitalize btn-info'
													disabled={showDiscontinued}
												>
													{constants.AddAshaCourse}
												</Button>
												<Form.ErrorList errors={errors} />
											</Form.Item>
										</>
									)}
								</Form.List>
							</Col>
							<Col xl={12} xs={24}>
								<b>{constants.NoAshaReqCourse} {noAshaReqCourse}</b>
							</Col>
						</Row>
						<div className='d-flex justify-content-between pb-2.5'>
							<div>
							{
								rowData.length === 1 && showDiscontinued ?
									<Checkbox checked={isDiscontinuedChecked}
										onChange={handleDiscontinueCheckboxChange} >{constants.discontinue}</Checkbox>
									: ""
							}
							</div>
							<Flex gap="small">
								<Button type="primary" className='action-btn' danger
									onClick={rowData.length === 1 ? handleCloseEditModal : handleCloseAddModal}>
									{constants.Cancel}</Button>
								<Button type="primary" className='action-btn'
									htmlType='submit'
									disabled={buttonDisabled}
								>{constants.Save} </Button>
							</Flex>
						</div>
					</Form>
				</Modal>

				{/* Discontinue Modal*/}
				<Modal open={showDisconModal} title={`${constants.DiscontinueArea}${rowData?.[0]?.area}`} onCancel={handleCloseDIsconModal} width={600} footer={null}>
					<b>{constants.DiscontinueModalBodyText}</b>
					<Flex gap="small" align="flex-end" className='action-button-container'>
						<Flex gap="small" wrap="wrap">
							<Button type="primary" className='action-btn' danger
								onClick={handleCloseDIsconModal} >
								{constants.Cancel}</Button>
							<Button type="primary" className='action-btn'
								htmlType='submit' disabled ={buttonDisabled} onClick={handleConfirmDiscontinue}>{constants.disconBtn}</Button>
						</Flex>
					</Flex>
				</Modal>
			</div>
		</Spin>
	)
}
export default ManageCourses