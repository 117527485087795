import React, { useState,useEffect } from 'react';
import { Select, Button, Form, Row, Col, Table, Checkbox, Space, Modal, Tag } from 'antd';
import constants from '../../../Constants/constants';
import { showTotalItems, getNextAndPreviousButtons, handleRowSelect } from '../../../GlobalFunctions/GlobalFunctions';

const ModalContent = ({ form, closeModal, selectedRecord, semesterData, setShowSent, showSent, sendSurveyTabColumns, setShowSendModal, surveyTypes, sendModalData, clearKeys, setIsPartTime, isPartTime, SendData,setSelectedSemesterId,getSurveyRecepientsData,setSelectedYearGroups,rowKeysModal, setRowKeysModal,rowDataModal, setRowDataModal,setRefresh, refresh,setSendModalData,selectedSurveyTypeSendTo,setCheckYearSelected,loading,getSemesterId,selectedYearGroups,loadingRecipients,selectedSemesterId,isTransfer,setIsTransfer }) => {
	const [surveyType, setSurveyType] = useState('');
	const [visibleMakeActiveModal,setVisibleMakeActiveModal] = useState(false);
	const [visibleResendShowConfirmModal,setVisibleResendShowConfirmModal] = useState(false);
	let selectedSurvey=null;
	useEffect(()=>{
		let selectedSurveyData = surveyTypes.filter((each) => (each?.id === selectedRecord?.survey_type ? each.content : ''))
		setSurveyType(selectedSurveyData[0]?.content);
		selectedSurvey=selectedSurveyData[0]?.content;
		if(semesterData?.length===1){
			if(selectedSurvey!=="Mentors"){
				getSurveyRecepientsData(0,false,0,selectedYearGroups);//parameters are semesterId value and showSent button value.
			}
		}else if(semesterData?.length>1){
			setSelectedSemesterId(semesterData[0]?.id);
			setRefresh(!refresh);
			if(selectedSurvey!=="Mentors"){
				getSurveyRecepientsData(0,false,0,selectedYearGroups);
			}
		}
		if(selectedSurvey==="Mentors"){
			getYearTypes();
		}else if(selectedSurvey!=="Mentors"||selectedSurvey!=="Instructor"||selectedSurvey!=="Courses"){
			if(selectedRecord?.recipients?.length>0){
				form.setFieldsValue({ Recipients:selectedRecord?.recipients })
			}
		}
	}, []);
	useEffect(() => {
		return () => {
			setShowSent(false);
			setIsPartTime(false);
			setSendModalData([]);
			setIsTransfer(false);
		};
	}, []);
	const getYearTypes = ()=>{
		form.setFieldsValue({ NotificationTo: ['1', '2', '3', '4'] })
		setSelectedYearGroups([1, 2, 3, 4])
		setCheckYearSelected([1, 2, 3, 4])
		let semesterId = getSemesterId(selectedSemesterId);
		getSurveyRecepientsData(semesterId,isPartTime,showSent,[1, 2, 3, 4],isTransfer);
	}
	const clearYearTypes = () => {
		form.setFieldsValue({ NotificationTo:[]});
		setSelectedYearGroups([]);
		setCheckYearSelected([]);
		let semesterId = getSemesterId(selectedSemesterId);
		getSurveyRecepientsData(semesterId,isPartTime,showSent,[],isTransfer);
	}
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null,selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeysModal, rowData: rowDataModal }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeysModal, setRowData: setRowDataModal }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)
  };
	const rowSelection = {
		fixed: 'left',
		columnWidth: '5%',
		selectedRowKeys: rowKeysModal,
		onSelect: (record, selected, selectedRows) =>onSelectChange(
			selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			record, //CURRENT SELECTED RECORD
			sendModalData?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? sendModalData : []
			onSelectChange(
				data?.map((item) => item.id),
				data,
				null,
				null,
				true
			)
		},
	};
	const handleSelectedSemesterId=(value)=>{
		clearRowDataStates();
		setSelectedSemesterId(value);
		let semesterId = getSemesterId(value);
		getSurveyRecepientsData(semesterId,isPartTime,showSent,selectedYearGroups,isTransfer);
	}
	const handleChangeShowSent=()=>{
		setShowSent(!showSent);
		clearRowDataStates();
		setRefresh(!refresh);
		let semesterId = getSemesterId(selectedSemesterId);
		getSurveyRecepientsData(semesterId,isPartTime,!showSent,selectedYearGroups,isTransfer);
	}
	const handleChangePartTime=(e)=>{
		setIsPartTime(e.target.checked);
		clearRowDataStates();
		setRefresh(!refresh);
		let semesterId = getSemesterId(selectedSemesterId);
		getSurveyRecepientsData(semesterId,e.target.checked,showSent,selectedYearGroups,isTransfer);
	}
	const onChangeYears=(values)=>{ // Convert to integers
		form.setFieldsValue({NotificationTo:values});
		clearRowDataStates();
		setSelectedYearGroups(values);
		setCheckYearSelected(values);
		setRefresh(!refresh);
		let semesterId = getSemesterId(selectedSemesterId);
		getSurveyRecepientsData(semesterId,isPartTime,showSent,values,isTransfer);
  }
	const handleChangeIsTransferStudents=(e)=>{
		setIsTransfer(e.target.checked)
		let semesterId = getSemesterId(selectedSemesterId);
		getSurveyRecepientsData(semesterId,isPartTime,showSent,selectedYearGroups,e.target.checked);
	}
	const buttonSubmitCall = (type) =>{
		SendData(type);
		clearKeys();
		clearRowDataStates();
		form.setFieldsValue({NotificationTo:null})
	}
	const clearRowDataStates = ()=>{
		setRowDataModal([]);
		setRowKeysModal([]);
	}
	const tagRender = (props) => {
    const {label} = props;
    return (
      <Tag
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        closable={false} // Set closable to false to hide remove icon
      >
        {label}
      </Tag>
    );
  };
	const sendAndCancelButtonCall =(type)=>{
		if(type==="submit"||type==="resend"){
			buttonSubmitCall(type === "resend" ? "resend" : null);
			setShowSent(false);
			setIsPartTime(false);
		}
		setVisibleResendShowConfirmModal(false);
		setVisibleMakeActiveModal(false);
	}
	return (
		<Form 
			form={form}
			id="myForm"
			layout='vertical'
		>
			<>
				<Row gutter={[8, 8]}>
					{(surveyType==="Mentors")?
						<Col span={24}>
							<Form.Item
								name="NotificationTo"
								className='w-full !mb-2'
								initialValue={null}
							>
								<Select
									mode="multiple"
									maxTagCount='responsive'
									placeholder='Year'
									onChange={(value)=>onChangeYears(value)}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									dropdownRender={(menu) => (
										<div className='w-full'>
											<Button
												type="text"
												className='w-50 border border-1 !important'
												onClick={getYearTypes}
											>
												Select All
											</Button>
											<Button
												type="text"
												className='w-50 border border-1 !important'
												onClick={clearYearTypes}
											>
												Deselect All
											</Button>
											{menu}
										</div>
									)}
								>
									{(surveyType==="Mentors"?
									constants.TypesForMentors:
									selectedSurveyTypeSendTo?.length>1?constants.TypesForCustom:selectedSurveyTypeSendTo?.includes("0")?
									constants.TypesForMentors:constants.TypesForCustom).map((option) => (
										<Select.Option
											key={option?.value}
											value={option?.value}
										>
											{option?.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						:
						(surveyType!=="Courses"&&surveyType!=="Instructor"&&surveyType!=="Mentors")&&
						<Col span={24}>
							<Form.Item
								name="Recipients"
								className='w-full !mb-2 pointer-events-none'
							>
								<Select
									mode="multiple"
									maxTagCount='responsive'
									placeholder='Year'
									tagRender={tagRender}
									open={false}
									suffixIcon={null}
									onClick={(e) => e.preventDefault()}  // Prevent clicking on the component
									value={selectedRecord?.recipients?.map(obj => parseInt(obj))}
								>
									{constants.RecipientTypes?.map((item)=>(
										<Select.Option key={item?.id} value={item?.id}>{item?.label}</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					}
					{semesterData?.length>1?
						<Col span={12}>
							<Form.Item label={constants.Semester} className='!mb-2'>
								<Select placeholder='Type' maxTagCount='responsive' onChange={handleSelectedSemesterId}defaultValue={semesterData[0]?.id}
								>
									{semesterData?.map((sem) => (
										<Select.Option 
											key={sem.semester} 
											value={sem.id}
										>
											{sem.semester}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						:
						<div></div>
					}
				</Row>
				<div className='flex justify-between items-center mb-2'>
					{(surveyType === 'Instructor' || surveyType === 'Mentors')?"":
						(surveyType === 'Courses')&&
						<Space>
							<strong>{constants.PartTime} :</strong>
							<Form.Item className='!mb-0'>
								<Checkbox onChange={handleChangePartTime} checked={isPartTime}></Checkbox>
							</Form.Item>
						</Space>
					}
					{surveyType==="Mentors"?
						<Space>
							<strong>{constants.TransferStudents} :</strong>
							<Form.Item className='!mb-0'>
								<Checkbox onChange={handleChangeIsTransferStudents} checked={isTransfer}></Checkbox>
							</Form.Item>
						</Space>
					:""}
					<Button
						className='btn-info'
						onClick={() => {
							handleChangeShowSent();
						}}>
						{showSent ? constants.ShowNotSent : constants.ShowSent}
					</Button>
				</div>
				<Table
					className='records-table data_table'
					bordered
					size="small"
					columns={sendSurveyTabColumns}
					dataSource={sendModalData}
					pagination={{
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, sendModalData),
						itemRender: getNextAndPreviousButtons,
					}}
					loading={loadingRecipients}
					scroll={{y: '40vh'}}
					rowKey={(record) => record?.id}
					onRow={(record, rowIndex) => {
						return {onClick: () => onSelectChange(null, null, record, rowIndex)}
					}}
					rowSelection={!showSent?rowSelection:null}
				>
				</Table>
				<div className="flex justify-end mt-3">
					<Button 
						type="primary"
						className='mb-3'
						onClick={()=>{
							closeModal(setShowSendModal);
							clearKeys();
							setCheckYearSelected([]);
							form.setFieldsValue({NotificationTo:null})
						}}
						danger>
							{constants.Cancel}
						</Button>
						{selectedRecord?.is_active&&selectedRecord?.is_active==="1"?
							<Button
								htmlType='submit' 
								type="primary" 
								className='ms-2 text-sm mb-3'
								disabled={(sendModalData?.length===0 || loading||(!showSent&&rowDataModal?.length===0))}
								onClick={()=>{
									let type = showSent?"resend":null;
									if(showSent){
										setVisibleResendShowConfirmModal(true);
									}else{
										buttonSubmitCall(type);
									}
								}}>
								{showSent?constants.Resend:constants.Send}
							</Button>:
							<Button
								type="primary" 
								className='ms-2 text-sm mb-3'
								disabled={(sendModalData?.length===0 || loading||(!showSent&&rowDataModal?.length===0))}
								onClick={()=>{
									setVisibleMakeActiveModal(true);
								}}>
								{showSent?constants.Resend:constants.Send}
							</Button>
						}
				</div>
				{visibleMakeActiveModal&&
					<Modal
						title={constants.Confirm}
						open={visibleMakeActiveModal}
						onCancel={()=>sendAndCancelButtonCall()}
						footer={false}
					>
						<>
							{showSent?
								<div className='text-sm mb-2 font-bold'>{constants.MakeSurveyActiveResendText}</div>:
								<div className='text-sm mb-2 font-bold'>{constants.MakeSurveyActiveText}</div>
							}
							<div className="mt-3 flex justify-end">
								<Button type='primary' danger onClick={()=>sendAndCancelButtonCall()}>Cancel</Button>
								<Button
									type='primary'
									onClick={()=>{showSent?sendAndCancelButtonCall("resend"):sendAndCancelButtonCall("submit");}}
									htmlType='submit'
									form='myForm'
									className='ms-2'
									disabled={loading}
								>
									{showSent?constants.Resend:constants.Send}
								</Button>
							</div>
						</>
					</Modal>}
					{visibleResendShowConfirmModal&&
						<Modal
							title={constants.Confirm}
							open={visibleResendShowConfirmModal}
							onCancel={()=>sendAndCancelButtonCall()}
							footer={false}
						>
							<>
								<div className='text-sm mb-2 font-bold'>{constants.ResendModalConfirmText}</div>
								<div className="mt-3 flex justify-end">
									<Button type='primary' danger onClick={()=>sendAndCancelButtonCall()}>Cancel</Button>
									<Button
										type='primary'
										onClick={()=>{sendAndCancelButtonCall("resend")}}
										htmlType='submit'
										form='myForm'
										className='ms-2'
										disabled={loading}
									>
										{constants.Resend}
									</Button>
								</div>
							</>
						</Modal>
					}
			</>
		</Form>
	)
}
export default ModalContent
