
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Card, Switch, Table, message, Select, Space, Tag, Input, Radio } from 'antd';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import constants from '../../Constants/constants';
import { fetchStaffApi, fetchApi } from '../../Services/Api';
import dayjs from 'dayjs';
import { ClockCircleOutlined } from "@ant-design/icons";
import { CustomSortIcon, getNextAndPreviousButtons, showTotalItems, getModal, closeModal, HighlightedHTML, performSearch, removeExtraSpaces, checkEmptySpace, handleValidateTextEditor } from '../../GlobalFunctions/GlobalFunctions';
import { useNavigate, useLocation } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { getCookie, COOKIE } from '../../Services/Cookie';
dayjs.locale('en');

const { Option } = Select;

const NotificationListing = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);
	const [selectedRow, setSelectedRow] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [showHiddenNotification, setShowHiddenNotification] = useState('1');
	const [showModal, setShowModal] = useState(false);
	const [form] = Form.useForm();
	const [visibility, setVisibility] = useState();
	const [visibleFromTime, setVisibleFromTime] = useState('');
	const [visibleToTime, setVisibleToTime] = useState('');
	const [notificationModalVisible, setNotificationModalVisible] = useState(false);
	const [notificationDetails, setNotificationDetails] = useState([]);
	const [selectedRecord, setSelectedRecord] = useState({});
	const [visibilityValue, setVisibilityValue] = useState("1");
	const [searchText, setSearchText] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const breadcrumbItems = [
		{
			label: constants.NotificationList,
			active: true
		}
	];
	const handleRadioChange = (e) => {
		setVisibilityValue(e.target.value);
	};
	useEffect(() => {

	}, [location?.state?.message]);
	useEffect(() => {
		getNotificationList();
	}, [showHiddenNotification]);
	const notificationDetailsTableColumns = [
		{
			title: constants.Name,
			align: 'left',
			width: '40%',
			dataIndex: 'name',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => { return a.name.localeCompare(b.name) },
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.ResourceRole,
			align: 'left',
			width: '40%',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => a.year.localeCompare(b.year),
			render: (text, record, index) => {
				return <span className="text-wrap">{record?.year}</span>
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.NotificationViewedDate,
			align: 'center',
			width: '20%',
			render: (record) => {
				return (
					record?.dt_viewed !== null ? dayjs(record?.dt_viewed).format(constants.DateFormat) : <Tag icon={<ClockCircleOutlined />} color="warning">
						Pending
					</Tag>
				)
			}
		}
	]
	const getNotificationDetails = () => {
		fetchStaffApi(`/get-notification-details?notification_id=${selectedRecord?.id}`, 'GET').then((res) => {
			if (res?.data) {
				setNotificationDetails(res?.data);
			} else {
				setNotificationDetails([]);
			}
		});
	}

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchText(search);
		const searchFieldNames = [
			'notification_text_details',
		];
		let searchedValue = performSearch(search, tableData, searchFieldNames);
		setFilteredTableData(searchedValue);
	};
	//fetching notification list data
	const getNotificationList = () => {
		let payload = {
			isAdmin: getCookie(COOKIE.UserId),
			value: showHiddenNotification
		}
		fetchApi('/get-all-notifications', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				//adding key item for row selection
				const modifiedData = res?.data.map((item, index) => ({
					...item,
					key: index + 1,
				}));
				setTableData(modifiedData);
				setFilteredTableData(modifiedData)
				sessionStorage.setItem('semType', res.data?.semester_type);
			} else {
				setTableData([]);
				setFilteredTableData([]);
			}
		}).catch(()=>{
      message.error(constants.ErrorMessage);
    });
	};
	useEffect(() => {
		if (notificationModalVisible === true) {
			getNotificationDetails();
		}
	}, [notificationModalVisible]);
	const rowSelection = {
		selectedRowKeys: selectedRow,
		//	selectedRowKeys,
		columnWidth: '5%',
		onSelect: (record, selected) => {
			const newRowKeys = selected ? [record.id] : []; // If a row is selected, set its id to selectedRowKeys, otherwise clear it
			setSelectedRow(newRowKeys);
			setSelectedRecord(selected ? record : {}); // Update selectedRecord if a row is selected
		},
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys);
		},
		hideSelectAll: true,
	};
	// const decodeHtmlEntities = (text) => {
	// 	const element = document.createElement('div');
	// 	if (text) {
	// 		element.innerHTML = text;
	// 		return element.textContent;
	// 	}
	// 	return '';
	// };
	const columns = [
		{
			title: constants.SentNotifications,
			key: "sent_notification",
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => a.notification_text_details.localeCompare(b.notification_text_details),
			width: showHiddenNotification === "2" ? "90" : '80%',
			dataIndex: 'notification_text',
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (notification_text) => <div className='noti-mb-0'><HighlightedHTML html={removeExtraSpaces(notification_text)} highlight={searchText} /></div>,
		},
		{
			title: constants.SelectStatus,
			key: "is_active",
			align: 'left',
			render: (record) => {
				let toDate = dayjs(record?.visible_to).format(constants.DateFormat);
				let currentDate = dayjs().format(constants.DateFormat);
				return <span>{record?.is_visible === "1" ? "Visible" : (record?.is_visible === "2" && currentDate <= toDate) ? 'Pending' : ""}</span>
			},
			width: "10%",
			hide: showHiddenNotification === "2" ? true : false,
			sorter: (a, b) => {
				const getStatus = (record) => {
					let toDate = dayjs(record?.visible_to).format(constants.DateFormat);
					let currentDate = dayjs().format(constants.DateFormat);
					return record?.is_visible === "1" ? "Visible" : (record?.is_visible === "2" && currentDate <= toDate) ? 'Pending' : "";
				};

				const statusA = getStatus(a);
				const statusB = getStatus(b);

				if (statusA < statusB) return -1;
				if (statusA > statusB) return 1;
				return 0;
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.StartDate,
			key: "Start Date",
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => new Date(a.visible_from) - new Date(b.visible_from),
			width: "10%",
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			hide: showHiddenNotification === "1" ? true : false,
			render: (record) => {
				const visibleFromDate = record.visible_from !== null ? dayjs(record.visible_from).format(constants.DateFormat) : '';
				const highlightedVisibleFromDate = visibleFromDate.toLowerCase().includes(searchText.toLowerCase()) ? visibleFromDate.replace(new RegExp(`(${searchText})`, 'gi'), '<span class="highlight">$1</span>') : visibleFromDate;
				return (
					<span dangerouslySetInnerHTML={{ __html: highlightedVisibleFromDate }} className="text-wrap"></span>
				);
			},
		},
		{
			title: constants.EndDate,
			key: "visible_to",
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => new Date(a.visible_to) - new Date(b.visible_to),
			width: "10%",
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			hide: showHiddenNotification === "1" ? true : false,
			render: (record) => {
				const visibleToDate = record.visible_to !== null ? dayjs(record.visible_to).format(constants.DateFormat) : '';
				const highlightedVisibleToDate = visibleToDate.toLowerCase().includes(searchText.toLowerCase()) ? visibleToDate.replace(new RegExp(`(${searchText})`, 'gi'), '<span class="highlight">$1</span>') : visibleToDate;
				return (
					<span dangerouslySetInnerHTML={{ __html: highlightedVisibleToDate }} className="text-wrap"></span>
				);
			},
		},
	].filter((item) => item.hide !== true);

	const handleNotification = (e) => {
		setSelectedRowKeys([]);
		if (e.target.checked === true) {
			setShowHiddenNotification("2");
			setIsChecked(true);
		} else {
			setShowHiddenNotification("1");
			setIsChecked(false);
		}
	};
	const displayEditModal = () => {
		setVisibilityValue(selectedRecord?.is_visible)
		setShowModal(true);
	}
	useEffect(() => {
		if (showModal) {
			setVisibility(selectedRecord?.is_visible === "1" ? true : false)
			handleEdit()
		}
	}, [showModal])

	const handleEdit = () => {
		setVisibleFromTime(selectedRecord?.visible_from !== null ? selectedRecord?.visible_from : null);
		setVisibleToTime(selectedRecord?.visible_from !== null ? selectedRecord?.visible_to : null);

		form.setFieldsValue({
			notification_visibility: selectedRecord?.is_visible === "1" ? true : false,
			priority: selectedRecord?.priority,
			visible_from: selectedRecord?.visible_from !== null ? dayjs(selectedRecord?.visible_from) : '',
			visible_to: selectedRecord?.visible_to !== null ? dayjs(selectedRecord?.visible_to) : '',
		});
		handleEditorChange(selectedRecord?.notification_text , 'notification_text', 'editor_text')
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const handleDoubleClick = (record) => {
		if (!isChecked) {
			handleRowClick(record);
			displayEditModal();
		}
	};
	const onFinishForm = (value) => {
		let payloadWithDates = null;
		let payload = null;
		const notificationTextHtml = value?.editor_text || form.getFieldValue('editor_text');
		const notificationTextPlainText = notificationTextHtml;
		payload = {
			notification_id: selectedRecord?.id,
			is_visible_val: value?.notification_visibility === true ? "1" : "2",
			priority: value?.priority,
			notification_text: removeExtraSpaces(notificationTextPlainText || ''),
			adminid: getCookie(COOKIE.UserId),
		};
		payloadWithDates = {
				...payload,
				visible_from: visibleFromTime,
				visible_to: visibleToTime,
		}
		fetchStaffApi('/edit-notification', 'PUT', value?.notification_visibility === true ? payload : payloadWithDates).then((res) => {
				if (res?.code === "200") {
					handleCloseModal();
					message.success(constants.UpdateMessage);
					getNotificationList();
					setSelectedRow([]);
					setSelectedRowKeys([]);
					setSelectedRecord({});
				} else {
					message.error(res?.message);
					setSelectedRow([]);
					setSelectedRowKeys([]);
					setSelectedRecord({});
				}
		});
	};
	const getVisibilityValue = (checked) => {
		setVisibility(checked);
	}
	const getVisibleFromValue = (value, dateString) => {
		setVisibleFromTime(dateString);
	}
	const getVisibleToValue = (value, dateString) => {
		setVisibleToTime(dateString);
	}
	const validateToDate = (_, value) => {
		const visibleFrom = form.getFieldValue('visible_from');
		if (visibleFrom && value && dayjs(value).isBefore(visibleFrom)) {
			const errorPromise = Promise.reject(new Error("Should be greater than start date."));
			return errorPromise;
		}
		return Promise.resolve();
	};
	const allNotificationDetails = () => {
		return (
			<div>
				<Table
					columns={notificationDetailsTableColumns}
					dataSource={notificationDetails}
					className="records-table notificationDetailsTable data_table mb-0 !pb-2"
					pagination={{
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, notificationDetails),
						itemRender: getNextAndPreviousButtons,
					}}
					size='small'
					scroll={{ y: '50vh' }}
				>
				</Table>
			</div>
		)
	}
	const disablePastDates = (current) => {
		return current && current < dayjs().startOf('day');
	};
	const handleEditorChange = (content, editField, hiddenField) => {
		form.setFieldsValue({ 
			[editField]: content || '',
			[hiddenField]: content || '',
		})
	};
	const toBeEditedNotificationContent = () => {
		return (
			<Form
				labelWrap
				form={form}
				onFinish={onFinishForm}
				layout='vertical'
			>
				<Space size={'large'} wrap>
					<Form.Item
						className="w-40"
						label={constants.Priority}
						name={"priority"}
					>
						<Select aria-label="Default select example" disabled={!visibility}>
							<Option value="1">{constants.High}</Option>
							<Option value="2">{constants.Medium}</Option>
							<Option value="3">{constants.Low}</Option>
						</Select>
					</Form.Item>
					<Space direction='vertical'>
						<Space>
							<label>{constants.NotificationVisibility}</label>
							<Form.Item
								className="!mb-0"
								name="notification_visibility"
							>
								<Switch
									checkedChildren={constants.Visible}
									value={visibility}
									onChange={getVisibilityValue}
									unCheckedChildren={constants.Hidden}
								/>
							</Form.Item>
						</Space>

						<Form.Item rules={[{ required: (visibilityValue === '1' || visibilityValue === '2'), message: constants.FieldRequired }]}>
							<div className='flex items-center border-1 border-gray-400 rounded-md py-3 ps-3'>
								<Radio.Group value={visibilityValue} onChange={handleRadioChange} disabled={!visibility}>
									<Radio value={"1"}>{constants.Always}</Radio>
									<Radio value={"2"} className='contents radio-top'>
										<Space className='items-start'>
											<Space className='items-baseline'>
												<label>{constants.VisibleFrom}</label>
												<Form.Item className="!mb-0" name="visible_from">
													<DatePicker
														showNow={false}
														onChange={getVisibleFromValue}
														format={constants.DateFormat}
														showTime={false}
														className="w-44"
														disabled={!visibility}
														disabledDate={disablePastDates}
													/>
												</Form.Item>
											</Space>
											<Space className='items-baseline'>
												<label>{constants.VisibleTo}</label>
												<Form.Item className="!mb-0" name="visible_to" rules={[{ validator: validateToDate }]}>
													<DatePicker
														showNow={false}
														onChange={getVisibleToValue}
														format={constants.DateFormat}
														showTime={false}
														className="w-44"
														disabled={!visibility}
														disabledDate={disablePastDates}
													/>
												</Form.Item>
											</Space>
										</Space>
									</Radio>
								</Radio.Group>
							</div>
						</Form.Item>
					</Space>
				</Space>
				<div className={!visibility ? "pointer-events-none opacity-50" : ""}>
					<Form.Item
						className="mb-3 addNotificationEditor"
						label={constants.NotificationText}
						name="notification_text"
						rules={[
							{ 
								required: true, 
								message: ' ',
							},
							{ validator: (rule, value, validator) => handleValidateTextEditor(rule, value, validator, 'editor_text', form) },
						]}
					>
						<Editor
							tinymceScriptSrc={constants.TextEditorSrcPath}
							init={{
								height: 300,
								menubar: false,
								branding: false,
								plugins: constants.TinyMcePlugins,
								toolbar: constants.TinyMceToolbar,
								content_style: constants.TinyMceContentStyle,
								placeholder: constants.TinyMcePlaceholder,
								toolbar_mode: 'sliding'
							}}
							onEditorChange={(e) => handleEditorChange(e, 'notification_text', 'editor_text')}
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('editor_text'))}
						/>
					</Form.Item>
					<Form.Item 
						name='editor_text'
						hidden>
					</Form.Item>
				</div>
				<div className="flex justify-end gap-3">
					<Form.Item className='!mb-0'>
						<Button
							type='primary'
							className="text-capitalize text-sm mb-3"
							htmlType="submit"
						>
							{constants.Save}
						</Button>
					</Form.Item>
				</div>
			</Form>
		)
	}
	const toggleNotificationModal = () => {
		setNotificationModalVisible(true);
	};
	const redirectToAddNotificationPage = () => {
		navigate('/notification-add');
	}
	const redirectToMyViewPage = () => {
		navigate('/notifications', { state: { user: "staff" } });
	}
	const clearKeys = () => {
		setSelectedRow([]);
		setSelectedRowKeys([]);
		setSelectedRecord({});
	}

	const handleRowClick = (record) => {
		const selectedKeys = selectedRowKeys.includes(record.id) ? [] : [record.id];
		setSelectedRow(selectedKeys);
		setSelectedRowKeys(selectedKeys);
		setSelectedRecord(record);
	};

	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.NotificationList} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom">
				<div className='mb-2 flex justify-between flex-wrap gap-3'>
					<Space wrap>
						<Button className="text-sm btn-info no-padding" disabled={selectedRowKeys.length > 0 || showHiddenNotification === "2"} onClick={redirectToAddNotificationPage}>{constants.Add}</Button>
						<Button className="text-sm btn-info no-padding" disabled={selectedRowKeys.length === 0 || showHiddenNotification === "2"} onClick={displayEditModal}>{constants.Edit}</Button>
						<Button className="text-sm btn-info no-padding" disabled={selectedRowKeys.length === 0} onClick={toggleNotificationModal}>{constants.Details}</Button>
						<Button className="text-sm btn-info no-padding" onClick={redirectToMyViewPage}>{constants.MyView}
						</Button>
					</Space>
					<Space wrap>
						<Checkbox onChange={handleNotification} className='fw-bold text-sm shwAllNotisChkbox'>{constants.ShowExpiredNotifications}</Checkbox>
						<Input
							onChange={onSearch}
							value={searchText}
							type='search'
							placeholder={constants.Search}
							className='w-48 ms-2'>
						</Input>
					</Space>
				</div>
				<Table
					className="records-table data_table"
					rowSelection={{
						type: "checkbox",
						...rowSelection,
					}}
					columns={columns}
					dataSource={filteredTableData}
					bordered
					size="small"
					pagination={{
						className: "!mb-0",
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, filteredTableData),
						itemRender: getNextAndPreviousButtons,
					}}
					rowKey={(record) => record?.id}
					scroll={{ y: 'calc(100vh - 295px)', x: 768 }}
					onRow={(record) => ({
						onClick: () => handleRowClick(record),
						onDoubleClick: () => {
							handleDoubleClick(record)
						},
					})}
				/>
				{
					(notificationModalVisible || showModal)
					&& getModal(notificationModalVisible ? constants.NotificationRecipients : constants.EditNotification, notificationModalVisible ? allNotificationDetails() : toBeEditedNotificationContent(),
						(notificationModalVisible ? notificationModalVisible : showModal),
						closeModal,
						(notificationModalVisible ? setNotificationModalVisible : setShowModal), () => clearKeys(),
					)
				}
			</Card>
		</div>
	)
}

export default NotificationListing;
