import React from 'react'
import { Flex } from 'antd'
import constants from '../../Constants/constants'
import { useNavigate } from 'react-router-dom'
import logo from '../../Assets/header-logo4.png'

const LinkExpired = () => {
  const navigate = useNavigate()

  const navigateToLogin = () => {
    navigate('/student-login')
  }
  return (
    <div className="mx-auto min-vh-100 d-flex justify-content-center align-items-center p-3 p-md-0">

      <div className="card shadow-lg login-card mb-5 z-2 rounded-0">
        <img src={logo} className="card-img-top rounded-0" alt="Logo" />
        <div className='p-4'>
          <span className='text-2xl'>Link Expired</span>
          <hr></hr>
          <Flex justify='flex-start' align='flex-start'>
                <p>{constants.ExpiryMsg}</p>
          </Flex>
        </div>
        <div className="d-grid gap-2 px-0 px-md-3">
          <button className="btn btn-primary text-capitalize" onClick={navigateToLogin}>Back To Login</button>
        </div>
      </div>
    </div>
  )
}

export default LinkExpired