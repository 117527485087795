import React, { useEffect, useState } from 'react';
import constants from '../../Constants/constants';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { Card, Select, Form, Button, DatePicker, Table, Input, message, Radio, Space, Spin } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { fetchStaffApi, fetchApi } from '../../Services/Api';
import dayjs from 'dayjs';
import { CustomSortIcon, getNextAndPreviousButtons, showTotalItems, getModal, closeModal,handleRowSelect, performSearch, HighlightedText, removeExtraSpaces, handleValidateTextEditor, checkEmptySpace } from '../../GlobalFunctions/GlobalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCookie, COOKIE } from '../../Services/Cookie';

function sorter(arr, field) {
	return arr.sort((a, b) => {
		if (a?.[field] < b?.[field]) return -1;
		if (a?.[field] > b?.[field]) return 1;
		return 0;
	});
}

const AddNotifications = () => {
	const [form] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();
	const [facultyTypeSelection, setFacultyTypeSelection] = useState(false);
	const [notificationToTypes, setNotificationToTypes] = useState([]);
	const [facultyTypes, setFacultyTypes] = useState([]);
	const [visibilityType, setVisibilityType] = useState(1);
	const [visibleFromDate, setVisibleFromDate] = useState('');
	const [visibleToDate, setVisibleToDate] = useState('');
	const [allTags, setAllTags] = useState([]);
	const [semFieldRequired, setSemFieldRequired] = useState(false);
	const [showRecipientsModal, setShowRecipientsModal] = useState(false);
	const [recepientsDetails, setRecepientsDetails] = useState([]);
	const [filteredRecipients, setFilteredRecipients] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const [semType, setSemType] = useState(null);
	const [success, setSuccess] = useState(false)
	let notificationState = location.state?.notificationState ? location.state.notificationState : null;
	const [mentorTypeNotification,] = useState(location?.state?.mentorDetails ? true : false)
	const [searchText, setSearchText] = useState('');
	const [disabledBtn, setDisabledBtn] = useState(false);
	const [rowKeys, setRowKeys] = useState([]);
	const [rowData,setRowData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [savedSelectedRowKeys, setSavedSelectedRowKeys] = useState([])
	const [refresh,setRefresh] = useState(false)
	const [semesterData, setSemesterData] = useState([])
	let studentCourseListState = location.state?.selectedRow ? location.state : null;

	const breadcrumbItems = [
		{
			label: constants.AddNotifications,
			active: true
		}
	];
	let lastindex = allTags.length > 0 ? allTags.length - 1 : 0;
	useEffect(() => {
		if (mentorTypeNotification) {
			const yearTypes = [...new Set(location?.state?.mentorDetails.map((record) => record.year_in_program))]
			form.setFieldsValue({ NotificationTo: yearTypes })
			setNotificationToTypes(yearTypes);
			setVisibilityType(1);
			let sortedNames = sorter(location?.state?.mentorDetails, 'student_name')
			setAllTags(sortedNames?.map((recipient) => (`${recipient.student_name}`)))
			setRecepientsDetails(sortedNames)
			setFilteredRecipients(sortedNames);
		}
		getSemesterDetails();
		form.setFieldsValue({
			visibilityfrom: '',
			visibilityto: '',
			notificationvisibility: 1,
		})
	}, [mentorTypeNotification])

	useEffect(() => {
		form.setFieldsValue({
			notificationvisibility: 1,
		})
	}, []);

	const onChange = (e) => {
		setVisibilityType(e.target.value);
		if (e.target.value === 1) {
			form.setFieldsValue({
				visibilityfrom: '',
				visibilityto: '',
				notificationvisibility: 1,
			})
		}
		if (e.target.value === 2) {
			form.setFieldsValue({
				notificationvisibility: 2,
			})
		}
	};

	const recepientsDetailsTableColumns = [
		{
			title: constants.Name,
			key: '1',
			align: 'left',
			sorter: (a, b) => a.full_name.localeCompare(b.full_name),
			render: (record) => {
				const studentName = record?.full_name || '';
				return <HighlightedText text={studentName} highlight={searchText} />
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.ResourceRole,
			key: '2',
			align: 'left',
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			sorter: (a, b) => a.year.localeCompare(b.year),
			render: (record) => {
				let yearType = record?.year || '';
				return <HighlightedText text={yearType} highlight={searchText} />
			}
		},
	]
	useEffect(() => {
		if (location?.state === null) {
			let notiToType = false;
			for (let i of notificationToTypes) {
				if (i === '7') {
					notiToType = true;
				}
			}
			if (notiToType === false) {
			} else {
				if (facultyTypes.length === 0) {
					setDisabledBtn(true);
				} else {
					setDisabledBtn(false);
				}
			}
			if (notificationToTypes.includes('7') && facultyTypes.length === 0) {
				setDisabledBtn(true);
			} else if (!notificationToTypes.includes('7') && facultyTypes.length === 0) {
				setDisabledBtn(false);
			}
			if (notificationToTypes.length === 0) {
				form.setFieldsValue({
					notirecipients: [],
				});
			}
		}
	}, [notificationToTypes, facultyTypes])
	//Get onchange value NotificationTo field
	const getNotificationToOptions = (value, option) => {
		setNotificationToTypes(value);
		if (value.includes('7')) {
			setFacultyTypeSelection(true);
		} else {
			setFacultyTypeSelection(false);
			setFacultyTypes([]);
			form.setFieldsValue({ FacultyType: [] })
		}
		setRowData([]);
		setRowKeys([]);
	}
	const generateSelectDropdown = (data) => {
		let filteredData = data?.map((item) => {
			const semesterObj = constants.StudentSemList?.find((sem) => sem.key === item.semester_type)
			return {
				...item,
				semester: item?.semester_is_ended === '0' ? `Current(${semesterObj?.value})` : semesterObj?.value
			}
		}).sort((a, b) => { return a.semester_is_ended - b.semester_is_ended })
		return filteredData
	}
	const getSemesterDetails = () => {
		fetchApi(`/semester-data`, 'GET').then((res) => {
			if (res?.data?.length>0) {
				setSemesterData(generateSelectDropdown(res.data));
				if (res.data.length > 1) {
					setSemFieldRequired(true);
				} else {
					setSemFieldRequired(false);
				}
			}
		});
	}

	useEffect(() => {
		if (location?.state === null) {
			if (notificationToTypes || facultyTypes) {
				const allTagsSet = new Set();
				notificationToTypes.forEach(type => {
					if (type in constants.YeartypeCourses) {
						allTagsSet.add(constants.YeartypeCourses[type]);
					}
				});
				if (notificationToTypes.length === 0) {
					form.setFieldsValue({
						notirecipients: [],
					});
				}
				facultyTypes.forEach(type => {
					if (type in constants.facultyMapping) {
						allTagsSet.add(constants.facultyMapping[type]);
					}
				});
				// Convert the Set back to an array
				const allTags = Array.from(allTagsSet);
				setAllTags(allTags);
			}
		}
	}, [notificationToTypes, facultyTypes])

	const getFacutyTypeOptions = (value) => {
		setFacultyTypes(value);
		// Retrieve the recipients based on the notification and faculty types
	};

	//Get onChange values of VisibleFromTo 
	const getVisibleFromDate = (date, dateString) => {
		setVisibleFromDate(dateString);
	}
	const getVisibleToDate = (date, dateString) => {
		setVisibleToDate(dateString);
	}
	useEffect(() => {
		if (visibleFromDate || visibleToDate) {
				setVisibilityType(2);
				form.setFieldsValue({
					notificationvisibility: 2,
				})
		
		} else if (!visibleFromDate || !visibleToDate) {
			setVisibilityType(1);
			form.setFieldsValue({
				visibilityfrom: '',
				visibilityto: '',
				notificationvisibility: 1,
			})
			//form.resetFields();
		}
	}, [visibleFromDate, visibleToDate])
	const getRecipients = (toType, facType,semType) => {
		let recipientsRelatedPayload = null;
		recipientsRelatedPayload = {
			adminid: getCookie(COOKIE.UserId),
			recipients: toType,
			faculty_type: facType,
			semester_type: !semFieldRequired ? 1 : semType,
		}
		fetchStaffApi('/get-all-recipients', 'POST', recipientsRelatedPayload).then((res) => {
			if (res?.data&&res?.data?.length>0) {
				setRecepientsDetails(res?.data);
				setFilteredRecipients(res?.data);
				const allKeys = res.data.map((item) => item?.id);
				if(rowKeys?.length>0){
					//DO NOTHING					
				}else{
					setRowKeys(allKeys);
					setRowData(res?.data)
					setSavedSelectedRowKeys(res?.data);
				}
				// if (checkedKeys) {

				// }
			} else {
				setRecepientsDetails([]);
				setFilteredRecipients([]);
			}
		});
	}
	const getSelectedRecepients = () => {
		const sortedRecipients = rowData && rowData.sort((a, b) => a?.full_name?.localeCompare(b?.full_name)).map((each) => `${each?.full_name}`);
		setAllTags(sortedRecipients);
		form.setFieldsValue({ notirecipients: sortedRecipients })
		setSavedSelectedRowKeys(rowData)
		
		if (showRecipientsModal) {
			setShowRecipientsModal(false);
		}
	}
	const getAllTheRecipients = () => {
		getRecipients(notificationToTypes, facultyTypes,semType);
		setShowRecipientsModal(true);
	}
	const rowSelection = {
		// onChange: handleRowSelect,
		selectedRowKeys: rowKeys,
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? recepientsDetails:[]
			onSelectChange(
			data?.map((item) => item),
			data,
			null,
			null,
			true
		)},
		onSelect: (record, selected, selectedRows) => onSelectChange( 
			selectedRows?.map((item) => item), //RETRIEVE ONLY IDs
			selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			record, //CURRENT SELECTED RECORD
			recepientsDetails?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
	};
	// const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
	// 	handleRowSelect(
	// 		newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
	// 		selectedRowData, //RECORDS FROM ON SELECT CHANGE
	// 		record, //CURRENT SELECTED RECORD
	// 		index, //CURRENT SELECTED RECORD INDEX
	// 		{ rowKeys: selectedRows, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
	// 		{ setRowKeys: setSelectedRows, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
	// 		'multi', //SELECTION TYPE
	// 		selectAll,
	// 	)
	// 	setSelectedRows(selectedRowData)
	// };
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null,selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)
  };

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchText(search);
		const searchFieldNames = [
			'full_name',
			'year',
		];
		let searchedValue = performSearch(search, recepientsDetails, searchFieldNames);
		setFilteredRecipients(searchedValue);
	};
	const recipientRelatedData = () => {
		return (
			<div>
				<div className="flex justify-end">
					<Input className='w-48 mb-2' placeholder={constants.Search} onChange={onSearch} value={searchText}>
					</Input>
				</div>
				<Table
					size='small'
					columns={recepientsDetailsTableColumns}
					dataSource={filteredRecipients}
					className="records-table data_table mb-0 !pb-2"
					rowSelection={{
						type: 'checkbox',
						...rowSelection,
					}}
					scroll={{ y: '50vh' }}
					rowKey={(record) => record?.id}
					pagination={{
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, filteredRecipients),
						itemRender: getNextAndPreviousButtons,
					}}
					onRow={(record, rowIndex) => {
						return {
							onClick: () => {onSelectChange(null, null, record, rowIndex)
								setRefresh(!refresh)},
						}
					}}
				>
				</Table>
				<div className="flex justify-end pb-3">
					<Button htmlType='submit' type='primary' onClick={getSelectedRecepients} disabled={rowKeys.length === 0 || filteredRecipients.length === 0}>{constants.Save}</Button>
				</div>

			</div>
		)

	}
	useEffect(() => {
		let timeoutId;
		if (showAlert) {
			timeoutId = setTimeout(() => {
				navigate('/notification-list');
			}, 2000);
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [showAlert]);
	const submitNotificationDetails = (values) => {
		let payload = null;
		let payloadWithoutFacultyAndWithoutVisibility = null;
		let payloadWithFacultyAndWithVisibility = null;
		let payloadWithoutFacultyAndWithVisibility = null;
		let payloadWithFacultyAndWithoutVisibility = null;
		const notificationText = values?.notitext?.level?.content || form.getFieldValue('notitext');
		payload = {
			adminid: getCookie(COOKIE.UserId),
			notification_to: values?.NotificationTo,
			priority: values?.Priority,
			notification_text: removeExtraSpaces(notificationText || ''),
			noti_recipients: mentorTypeNotification ? location?.state?.mentorDetails : rowData,
			semester_type_noti: !semFieldRequired ? 1 : values?.Semester
		}
		payloadWithoutFacultyAndWithoutVisibility = {
			...payload,
			visible_from: dayjs(visibleFromDate).format("YYYY-MM-DD 00:00:00"),
			visible_to: dayjs(visibleToDate).endOf('day').set('hour', 12).set('minute', 0).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
		};

		payloadWithFacultyAndWithVisibility = { ...payload, faculty_type_noti: values?.FacultyType }

		payloadWithoutFacultyAndWithVisibility = payload;

		payloadWithFacultyAndWithoutVisibility = {
			...payload,
			faculty_type_noti: values?.FacultyType,
			visible_from: dayjs(visibleFromDate).format("YYYY-MM-DD 00:00:00"),
			visible_to: dayjs(visibleToDate).endOf('day').set('hour', 12).set('minute', 0).set('second', 0).format("YYYY-MM-DD HH:mm:ss")
		}
		fetchStaffApi('/add-notification', 'POST', (facultyTypeSelection && facultyTypes.length !== 0) && visibilityType === 1 ? payloadWithFacultyAndWithVisibility : (facultyTypeSelection && facultyTypes.length !== 0) && visibilityType === 2 ? payloadWithFacultyAndWithoutVisibility : (!facultyTypeSelection && visibilityType === 2) ? payloadWithoutFacultyAndWithoutVisibility : (!facultyTypeSelection && visibilityType === 1) ? payloadWithoutFacultyAndWithVisibility : '').then((res) => {
			if (res?.code === '200') {
				message.success(constants.NotificationSent);
				setShowAlert(true);
				setAllTags([]);
			} else {
				setShowAlert(true);
				message.error(constants.Error);
			}
		});
	}
	const validateAllFields = async (values) => {
		try {
			await form.validateFields();
			if (rowKeys?.length === 0 && filteredRecipients?.length === 0) {
				message.error('There are no recipients.');
				setLoading(false); // Stop loading if there are no recipients
				return false;
			}
			submitNotificationDetails(values);
		} catch (errorInfo) {
			// Handle the validation error if needed
			console.error('Validation failed:', errorInfo);
			setLoading(false); // Stop loading if validation fails
		}
	};

	const handleFinish = (values) => {
		setLoading(true); // Start loading when the form is submitted
		validateAllFields(values);
	};

	useEffect(() => {
		if (notificationState) {
			if (notificationState[0]?.faculty) {
				const facultyName = notificationState.map((record) => record.faculty);
				setAllTags(facultyName.slice().sort())
				setVisibilityType(1)
				//setFacultyTypeSelection(true)
				const transformedData = notificationState.map(record => ({
					recipient_id: `faculty_${record?.id}`,
					id: record?.id,
					first_name: record?.faculty.split(' ')[0],
					middle_name: record?.faculty.split(' ')[1] || '',
					last_name: record?.faculty.split(' ')[2] || '',
					personal_email: record?.faculty_personal_email,
					university_email: record?.faculty_university_email,
					permanent_country: record?.faculty_permanent_country,
					permanent_state: record?.faculty_permanent_state,
					permanent_city: record?.faculty_permanent_city || record?.faculty_current_city,
					status: 'Faculty',
				}))
				setRowKeys(transformedData)
				setFilteredRecipients(transformedData)
			} else if (notificationState[0]?.student_first_name) {
				const studentName = notificationState.map((record) => record.name);
				setAllTags(studentName)
				setVisibilityType(1)
				const transformedData = notificationState.map(record => ({
					recipient_id: `student_${record?.id}`,
					id: record?.id,
					first_name: record?.student_first_name,
					middle_name: record?.student_middle_name,
					last_name: record?.student_last_name,
					personal_email: record?.student_personal_email,
					university_email: record?.student_university_email,
					permanent_country: record?.student_permanent_country,
					permanent_state: record?.student_permanent_state,
					permanent_city: record?.student_permanent_city_text,
					status: 'Student',
				}))
				setRowKeys(transformedData)
				setFilteredRecipients(transformedData)
			}
		} else if (studentCourseListState) {

			const selectedStudent = studentCourseListState?.selectedRow?.map((record) => record.student);
			setAllTags(selectedStudent)
			setVisibilityType(1)
			const selectedData = studentCourseListState?.selectedRow?.map(record => ({
				recipient_id: `student_${record?.student_id}`,
				id: record?.student_id,
				first_name: record?.student.split(' ')[0],
				middle_name: record?.student.split(' ')[1] || '',
				last_name: record?.student.split(' ')[2] || '',
				status: 'Student',
			}))
			setRowKeys(selectedData)
			setFilteredRecipients(selectedData)
		} else {
			if (!mentorTypeNotification) {
				setAllTags([])
			} else {
				return;
			}
		}

	}, [notificationState, studentCourseListState, form]);
	useEffect(() => {
		if (success) {
			setTimeout(() => {
				setSuccess(false)
				if (notificationState) {
					window.history.replaceState({}, '')
					setFacultyTypeSelection(false);
					window.location.reload();
				}
			}, 5000)
		}
	}, [success])
	const selectAllOptions = () => {
		setNotificationToTypes(['1', '2', '3', '4', '5', '6', '7']);
		//	setAllTags(["Freshman", "Sophomore", "Junior", "Senior", "Graduate", "Part Time"]);
		setFacultyTypeSelection(true);
		form.setFieldsValue({ NotificationTo: ['1', '2', '3', '4', '5', '6', '7'] })
	}
	const deselectAllOptions = () => {
		setNotificationToTypes([]);
		//setAllTags([]);
		setFacultyTypeSelection(false);
		form.setFieldsValue({ NotificationTo: [] })
		setFacultyTypes([]);
		form.setFieldsValue({ FacultyType: [] })
	}
	const selectAllFaculties = () => {
		const allFaculties = ['1', '4', '5', '2', '3'];
		setFacultyTypes(allFaculties);
		// Concatenate the new faculty tags with the existing tags and filter out duplicates
		setAllTags(prevTags => {
			const newTags = ["Admin", "Adjunct Faculty", "Clinical Supervisor", "Faculty", "System Administrator"];
			const concatenatedTypes = [...prevTags, ...newTags];
			return [...new Set(concatenatedTypes)];
		});
		form.setFieldsValue({ FacultyType: allFaculties });
	};
	const DeSelectAllFaculties = () => {
		setFacultyTypes([]);
		form.setFieldsValue({ FacultyType: [] })
	}
	const getChangedSemvalue = (value) => {
		setSemType(value);
		setRefresh(!refresh);
	}
	const clearKeys = () => {
		// getRecipients(notificationToTypes, facultyTypes);
		// setSearchText("");
		setRowData(savedSelectedRowKeys)
		setRowKeys(savedSelectedRowKeys)
	};

	const validateToDate = (_, value) => {
		if (visibleFromDate && value && value.isBefore(visibleFromDate, 'day')) {
			return Promise.reject(new Error("From date should be less than To date"));
		}
		if (visibleFromDate && value && dayjs(value).isSame(visibleFromDate, 'day')) {
			return Promise.resolve();
		}
		return Promise.resolve();
	};

	const validateFromDate = (_, value) => {
		if (visibleToDate && value && value.isAfter(visibleToDate, 'day')) {
			return Promise.reject(new Error("From date should be less than To date"));
		}
		if (visibleToDate && value && dayjs(value).isSame(visibleToDate, 'day')) {
			return Promise.resolve();
		}
		return Promise.resolve();
	};

	const disablePastDates = (current) => {
		return current && current < dayjs().startOf('day');
	};

	const handleEditorChange = (content, editField, hiddenField) => {
		form.setFieldsValue({ 
			[editField]: content || '',
			[hiddenField]: content || '',
		})
	};

	return (
		<Spin tip={constants.PleaseWait} spinning={loading}>
		<div>
			<BreadcrumbHeader pageTitle={constants.AddNotifications} breadcrumbItems={breadcrumbItems} />
			<Card className="mb-3 container-border-custom">
				<Form layout='vertical' form={form} onFinish={handleFinish} initialValues={{ visibilitytype: 1 }}>
					{!notificationState && !studentCourseListState ?
						<div className="d-flex md:flex-wrap sm:flex-wrap gap-2">
							<Form.Item
								hidden={mentorTypeNotification}
								label={constants.NotificationTo}
								name="NotificationTo"
								rules={[
									{
										required: true,
										message: 'Please select at least one option',
									},
								]}
							>
								<Select
									mode="multiple"
									placeholder='Notification to'
									onChange={getNotificationToOptions}
									disabled={location?.state?.mentorDetails}
									className='min-w-60 max-w-sm'
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									dropdownRender={(menu) => (
										<div className="w-full">
											<Button
												type="text"
												className='w-50 border border-1 rounded-none !important'
												onClick={selectAllOptions}
											>
												Select All
											</Button>
											<Button
												type="text"
												className='w-50 border border-1 rounded-none !important'
												onClick={deselectAllOptions}
											>
												Deselect All
											</Button>
											{menu}
										</div>
									)}
								>
									{constants.NotificationToOptions.map((option, index) => (
										<Select.Option key={option} value={(index + 1).toString()}>
											{option}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							{
								facultyTypeSelection ?
									<Form.Item
										label={constants.FacultyType}
										name="FacultyType"
										rules={[
											{
												required: true,
												message: constants.FieldRequired,
											},
										]}
									>
										<Select
											mode="multiple"
											placeholder='Staff Type'
											onChange={getFacutyTypeOptions}
											className='min-w-60 max-w-sm'
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().includes(input.toLowerCase())
											}
											dropdownRender={(menu) => (
												<div className="w-full">
													<Button
														type="text"
														className='w-50 border border-1 rounded-none !important'
														onClick={selectAllFaculties}
													>
														Select All
													</Button>
													<Button
														type="text"
														className='w-50 border border-1 rounded-none !important'
														onClick={DeSelectAllFaculties}
													>
														Deselect All
													</Button>
													{menu}
												</div>
											)}
										>
											{constants.facultyType.map((option, index) => (
												<Select.Option key={option?.key} value={(option?.key).toString()}>
													{option?.option}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									:
									''
							}
							<Form.Item
								label={constants.Semester}
								name="Semester"
								rules={[
									{
										required: !semFieldRequired ? false : true,
										message: constants.FieldRequired,
									},
								]}
								hidden={!semFieldRequired}
								className='w-40 ms-2'
							>
								<Select placeholder='Semester' onChange={getChangedSemvalue}>
									{semesterData?.map((sem) => (
										<Select.Option
											key={sem.semester} 
											value={sem.semester_is_ended}
										>
											{sem.semester}
										</Select.Option>))
									}
								</Select>
							</Form.Item>
							<Form.Item
								name='recipientBtn'
								hidden={location?.state}
								className='ms-2'
							>
								<Button
									type='primary'
									onClick={getAllTheRecipients}
									style={{ marginTop: '30px' }}
									className='btn-info'
									disabled={notificationToTypes.length === 0 || disabledBtn || (semFieldRequired?!semType?true:false:false)}
								>
									{constants.SelectRecipients}
								</Button>
							</Form.Item>
						</div>

						:
						''
					}
					<>
						<Space size={'large'} wrap>
							<Form.Item
								label={constants.Priority}
								name="Priority"
								rules={[
									{
										required: true,
										message: constants.FieldRequired,
									},
								]}
							>
								<Select
									className='!w-40'
									placeholder='Priority'
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									>
									{constants.PriorityOptions.map((option, index) => (
										<Select.Option key={option} value={index + 1}>
											{option}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item label='Visibility' name='visibilitytype' rules={[{ required: (visibilityType === 1 || visibilityType === 2), message: constants.FieldRequired }]}>
								<div className='flex items-center border-1 border-gray-400 rounded-md py-3 ps-3'>
									<Form.Item
										name='notificationvisibility'
										className='!mb-0'
									>
										<Radio.Group onChange={onChange} value={visibilityType} className='flex !items-center flex-wrap gap-3'>
											<Radio value={1}>{constants.Always}</Radio>
											<Radio value={2} disabled={location?.state}>
												<Space>
													<Space>
														<label>{constants.VisibleFrom}</label>
														<Form.Item
															name="visibilityfrom"
															rules={[{ required: visibilityType === 2 ? true : false, message: constants.FieldRequired }, { validator: validateFromDate }]}
															className='!mb-0'
														>
															<DatePicker showTime={false} format="MMM DD, YYYY" onChange={getVisibleFromDate} className='w-44' disabled={location?.state} disabledDate={disablePastDates} />
														</Form.Item>
													</Space>
													<Space>
														<label>{constants.VisibleTo}</label>
														<Form.Item
															name="visibilityto"
															className='!mb-0'
															rules={[{ required: visibilityType === 2 ? true : false, message: constants.FieldRequired }, { validator: validateToDate }]}
														>
															<DatePicker
																showTime={false}
																format="MMM DD, YYYY"
																onChange={getVisibleToDate}
																className='w-44'
																disabled={location?.state}
																disabledDate={disablePastDates}
															/>
														</Form.Item>
													</Space>
												</Space>
											</Radio>
										</Radio.Group>
									</Form.Item>
								</div>
							</Form.Item>
						</Space>
						<Form.Item
							label={constants.NotificationRecipients}
							name='notirecipients'
							key='notirecipients'
						>
							<div className={`border rounded ${(allTags.length === 0) ? 'py-3' : 'py-1'} px-1`}>
								{allTags.map((option, index) => {
									if (index === lastindex) {
										return <span key={index} className='text-sm border border-0'>{option}</span>
									} else {
										return <span key={index} className='text-sm border border-0'>{option}, </span>
									}
								})
								}
							</div>
						</Form.Item>
						<Form.Item
							label={constants.NotificationText}
							name="notitext"
							rules={[
								{
									required: true,
									message: ' ',
								},
								{ validator: (rule, value, validator) => handleValidateTextEditor(rule, value, validator, 'editor_text_note', form) },
							]}
							className="addNotificationEditor"
						>
							<Editor
								tinymceScriptSrc={constants.TextEditorSrcPath}
								init={{
									height: 170,
									menubar: false,
									branding: false,
									plugins: constants.TinyMcePlugins,
									toolbar: constants.TinyMceToolbar,
									content_style: constants.TinyMceContentStyle,
									placeholder: constants.TinyMcePlaceholder,
									toolbar_mode: "sliding",
								}}
								onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('editor_text_note'))}
								onEditorChange={(e) => handleEditorChange(e, 'notitext', 'editor_text_note')}
							/>
						</Form.Item>
						<Form.Item 
							name='editor_text_note'
							hidden>
						</Form.Item>
						<div className='flex justify-end'>
							<Space>
								<Form.Item>
									<Button className='text-sm bg-white backButton' onClick={() => navigate('/notification-list')}>{constants.Back}
									</Button>
								</Form.Item>
								<Form.Item>
									<Button type='primary' htmlType='submit' className='text-sm'>{constants.Send}
									</Button>
								</Form.Item>
							</Space>
						</div>
					</>
				</Form>
			</Card>
			{
				showRecipientsModal
				&& getModal(constants.SelectRecipients, recipientRelatedData(),
					showRecipientsModal,
					closeModal,
					setShowRecipientsModal, () => clearKeys(),
				)
			}
		</div>
		</Spin>
	)
}
export default AddNotifications;
