import React, { useEffect, useState, useRef } from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import { Card, Row, Col, Form, Input, Select, Flex, Table, Button as AntBtn, Modal, Spin, message, Space } from 'antd'
import constants from '../../Constants/constants'
import { Editor } from '@tinymce/tinymce-react';
import { fetchStaffApi, fetchApi } from '../../Services/Api';
import DOMPurify from 'dompurify';
import {
	UploadOutlined
} from '@ant-design/icons';
import { performSearch, beforeUploadCheckFileType, showTotalItems, HighlightedText, getNextAndPreviousButtons, removeExtraSpaces, handleRowSelect } from '../../GlobalFunctions/GlobalFunctions';
import { scrollUp } from '../Component/FacultyComponents/FacultyFunctions';
import FileUpload from '../Component/FileUpload';
import { useLocation,useNavigate } from 'react-router-dom';
import { sendEmail } from '../Component/MailComponents/SendEmailApi';
import Cookie, { getCookie, COOKIE } from '../../Services/Cookie';
const { TextArea } = Input;

function sorter(arr) {
	return arr.sort((a, b) => {
		if (a < b) return -1;
		if (a > b) return 1;
		return 0;
	});
}
const SendEmail = () => {
	const pageTitle = constants.SendEmail
	const breadcrumbItems = [
		{ label: constants.SendEmail, active: true },]
	const [typeId, setTypeId] = useState('')
	const [isMultiple, setIsMultiple] = useState(false)
	const [toOption, setToOption] = useState(constants.ToList);
	const [facultyType, setFacultyType] = useState(constants.facultyType);
	const [to, setTo] = useState('')
	const [from, setFrom] = useState('')
	const [showRecipientModal, setShowRecipientModal] = useState(false)
	const [recipient, setRecipient] = useState([])
	const [semester, setSemester] = useState(0)
	const [faculty, setFaculty] = useState(0)
	const editorRef = useRef(null);
	const [recipientData, setRecipientData] = useState([])
	const [totalRecords, setTotalRecords] = useState(10)
	const [searchText, setSearchText] = useState('')
	const [filteredData, setFilterTableData] = useState([])
	const [selectedRecord, setSelectedRecord] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [savedSelectedRowKeys, setSavedSelectedRowKeys] = useState([])
	const [selectedEmail, setSelectedEmail] = useState('')
	const [loading, setLoading] = useState(false)
	const [selectedAll, setSelectedAll] = useState(false)
	const [otherTo,] = useState('')
	const [recipientId, setRecipientId] = useState([])
	const [chooseFaculty, setChooseFaculty] = useState(false)
	const [semesterInput, setSemesterInput] = useState(false)
	const [file, setFile] = useState([])
	const [isAdvisor,] = useState(getCookie(COOKIE.IsAdvisor) === '1' && (getCookie(COOKIE.Role) === '2' || getCookie(COOKIE.Role) === '5'))
	const [fromOption, setFromOption] = useState(constants.NewFromList);
	const [semesterDisplay, setSemesterDisplay] = useState(false)
	const [staffLinked,setStaffLinked] = useState(false)
	const [yearInProgramArray, setYearInProgramArray] = useState([])
	const [rowData, setRowData] = useState([])
	const [emailIdType,setEmailIdType] = useState(2)
	const userId = Cookie.getCookie('userId')
	
	// const [columns, setColumns] = useState([
		const columns=[{
			title: constants.Name,
			dataIndex: isAdvisor ? 'student_name' : 'full_name',
			render: (full_name, record) => <HighlightedText text={full_name} highlight={searchText} />
		},
		{
			title: `${constants.ResourceRole}s`,
			dataIndex: isAdvisor?'class' :'year',
			render:(_, record)=>	{
				return <HighlightedText text={isAdvisor?record.class:record.year} highlight={searchText} />
			},
			hidden:Array.isArray(to) ?(to.includes(12) && to.length === 1?true:false):false
		},
		{
			title: 'GPA',
			dataIndex: 'gpa',
			hidden:from===constants.NewFromList[1].options[4].value?false:true,
			render:(record)=>{
				return record ? <HighlightedText text={record} highlight={searchText} />: '-'
			}
		},
		{
			title: constants.MenteeAssigned,
			dataIndex: 'student_assigned_status',
			render:(students_assigned)=><>{students_assigned==='1'?'Yes':'No'}</>,
			hidden:from===constants.NewFromList[1].options[2].value?false:true,
		},
		{
			title: constants.Status,
			dataIndex: 'status',
			hidden:Array.isArray(to) ? (to.includes(9) || (to && to.includes(12)) ? false : true) : true,
			render:(record)=>{
				return record ? <HighlightedText text={record} highlight={searchText} />: '-'
			}
		}
	].filter((val)=>!val?.hidden)
	
	const formRef = useRef(null)
	const errorRef = useRef(null)
	const [form] = Form.useForm()
	const [semesterOption, setSemesterOption] = useState([]);
	const entries = constants.TotalEntries;
	const location = useLocation()
	const emailType = constants.emailType;
	const emailSubject = constants.SubjectList;
	const [toSpan,setToSpan] = useState({toSize:6,staffSpan:5})
	let emailState = location.state?.emailState ? location.state.emailState : null;
	let studentState = location.state?.selectedRow ? location.state : null;
	const semesterCheck = from === 'Staff - Advising Students' || from === 'Staff - Username/Password' || (emailState && emailState[0]?.faculty)
	let mentorTypeEmail = location?.state?.mentorDetails;
	const navigate = useNavigate()
	//Setting the values from emailState to Form if it exist
	useEffect(() => {
		if (emailState) {
			if (emailState[0]?.faculty) {
				for (const record of emailState) {
					if (record?.is_advisor === 1) {
						setFromOption(constants.advisorFromOptions);
						break; // This will exit the loop early
					} else {
						setFromOption(constants.facultyFromOption);
					}
				}
				for (const record of emailState) {
					if (record?.email_type === '1' && record.faculty_roles === '4') {
						setEmailIdType(1)
					}
				}

				const recipients = emailState.map((record) => record.id);
				const facultyName = emailState.map((record) => record.faculty);
				const roles = [...new Set(emailState.map((record) => parseInt(record.faculty_roles, 10)))];
				const facultyString = facultyName.slice().sort().toString();
				const afterAddSpace = facultyString.replace(/,/g, ", ")
				setSelectedRowKeys(recipients)
				setRecipientId(recipients)

				let obj = constants.ToList?.find((item) => item.key === 7)		
				setTo([obj?.key])
				generateMailToArray(obj?.key, { name: obj?.option })
				form.setFieldsValue({
					faculty_type: roles,
					mail_recipient: afterAddSpace.replace(/\s+/g, ' '),
					mail_to: [obj?.key]
				})
				setStaffLinked(true)
			} else if (emailState[0]?.student_first_name) {
				const recipients = emailState.map((record) => record.id);
				const StudentName = emailState.map((record) => record.name);
				const studentRoles = [...new Set(emailState.map((record) => {
							switch (record.is_active) {
								case '1':
									return parseInt(record.year_in_program, 10);
								case '2':
									return 9;
								case '3':
									return 12;
								case '0':
									return 10;
								default:
									return null; // or handle the default case as needed
							}
						}).filter((value) => value !== null) // Remove any null values
					)
				];				
				const studentString = StudentName.slice().sort().toString()
				const afterAddSpaceName = studentString.replace(/,/g, ", ")
				const isActive = [...new Set(emailState.map(record => record.is_active))];
				if(isActive.length>1){
					setFromOption(constants.OnlyOtherOption)
					form.setFieldsValue({
						semester_type: 0
					})
				}
				else if(isActive.toString()==='2' || isActive.toString()==='3'){
					setFromOption(constants.OnlyOtherOption)
					form.setFieldsValue({
						semester_type: 0
					})
				}else if(isActive.toString()==='0'){
					setFromOption(constants.NonLoggedInTypeOptions)
					form.setFieldsValue({
						semester_type: 0
					})
				}else{
					setFromOption(constants.StudentFromList)
					form.setFieldsValue({
						semester_type: parseInt(emailState[0]?.semester)
					})
				}
				setToOption(constants.StudentToList)
				setRecipientId(recipients)
				setSelectedRowKeys(recipients)
				
				let IDs = isActive?.toString() === '3' ? 12 : studentRoles	
				setTo(IDs)
				setIsMultiple(true)
				form.setFieldsValue({
					mail_to: studentRoles,
					mail_recipient: afterAddSpaceName.replace(/\s+/g, ' '),
				})
			}
		} else if (mentorTypeEmail) {//Condition check if redirected from Assigning mentors page.
			if(location?.state?.type==="Pending"||location?.state?.type==="Rejected"){
				setFromOption(constants.AssignMentorsPendingRejectedList)
			}else if(location?.state?.type==="Accepted"){
				setFromOption(constants.AssignMentorsAcceptedList)
			}else{
				setFromOption(constants.StudentFromList)
			}
			if(location?.state?.semester){
				form.setFieldsValue({
					semester_type: parseInt(location?.state?.semester)
				})
			}
			setFaculty(0);
			let mentorStudents = '';
			let mentorRoles = '';
			const mentors = location?.state?.mentorDetails.map((mentor) => mentor.student_name);
			const mentorIds = location?.state?.mentorDetails.map((mentor) => mentor.id);
			const yearWiseStudents = [...new Set(mentorTypeEmail.map((record) => {
				let studentRole;
				if (record?.year_in_program === '3') {
					studentRole = 'Junior'
				} else if (record?.year_in_program === '4') {
					studentRole = 'Senior'
				}
				return studentRole
			}))
			]
			mentorStudents = Object.values(sorter(mentors)).join(', ');
			mentorRoles = Object.values(yearWiseStudents).join(', ');
			form.setFieldsValue({ mail_recipient: mentorStudents, mail_to: mentorRoles })
			setRecipientId(mentorIds);
			setSelectedRowKeys(mentorIds);
		} else if (studentState) {
			setFromOption(constants.StudentFromList)
			const studentId = studentState.selectedRow.map((record) => record.student_id);
			setSelectedRowKeys(studentId)
			setRecipientId(studentId)
			const student = studentState.selectedRow.map((student) => student.student);
			const roles = [...new Set(studentState.selectedRow.map((record) => parseInt(record.year_in_program, 10)))];
			setRecipient(student)
			setTo(roles)
			setIsMultiple(true)
			form.setFieldsValue({ 
				mail_recipient: student.slice().sort().toString().replace(/,/g, ", ").replace(/\s+/g, ' '),
				mail_to: roles,
				semester_type:0
			})
		} else {
			setChooseFaculty(false)
			form.setFieldsValue({
				faculty_type: null,
				mail_recipient: null,
				mail_to: [7]
			})
			setTo('')
			setSelectedRowKeys([])
			setFromOption(constants.NewFromList)
			setToOption(constants.ToList)
			window.history.replaceState({}, '')
			form.resetFields()
			setStaffLinked(false)
		}
		if (isAdvisor) {
			setFromOption(constants.OnlyOtherOption)
		}
		//Getting semester
		getSemester()
	}, [emailState, mentorTypeEmail, studentState])	

	useEffect(() => {
		// When selecting 'Other' as the type, if multiple 'to' values are chosen, we need to properly filter out the year values.
		if (from === 'General - Other') {
			// const yearsToAdd = !isAdvisor ?
			// 	selectedRecord.map(record => record.year).filter(year => year)
			// 	: selectedRecord.map(record => record.class).filter(recordClass => recordClass);// Filter out empty or undefined values
			const ids = selectedRecord.map(record => record.recipient_id);
			//const existingYears = otherTo.split(',').filter(year => year); // Filter out empty or undefined values
			//const newYears = yearsToAdd.filter(year => !existingYears.includes(year))
			//const newYearsString = newYears.join(',');
			//setOtherTo(prevData => prevData ? (prevData.endsWith(',') ? prevData + newYearsString : prevData + ',' + newYearsString) : newYearsString);
			setRecipientId(ids)
		}
	}, [selectedRecord]);

	const handleFromChange = (e) => {
		if (!emailState && !mentorTypeEmail && !studentState) {
			setToSpan({staffSpan:5,toSize:6})
			setFaculty(0)
			setSemester(0)
			setTo('')
			setSemesterDisplay(true)
			form.setFieldsValue({
				mail_to: undefined,
				mail_recipient: undefined,
				mail_semester: undefined
			});
		
			const mapping = {
				'General - Other': 'Other',
			};
			setRecipient('Other');
			form.setFieldsValue({
				mail_recipient: mapping[e] || '',
			});
		}
		setFrom(e)
		switch (e) {
			case 'Student - Username/Password':
				setToOption(constants.studentToOptions);
				setChooseFaculty(false);
				setTypeId(1)
				getHtmlContent(1)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
			case 'Staff - Username/Password':
				setChooseFaculty(true);
				setToOption(constants.facultyToOption);
				setFacultyType(constants.facultyType)
				setTypeId(10)
				getHtmlContent(10)
				setTo(7)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				if (!emailState) {
					form.setFieldsValue({
						faculty_type: undefined
					})
				}
				form.setFieldsValue({
					mail_to: [7]
				})
				break;
			case 'Staff - Advising Students':
				setChooseFaculty(true);
				setToOption(constants.facultyToOption);
				setFacultyType(constants.advisorFacultyOptions)
				setTypeId(3)
				getHtmlContent(3)
				setTo(7)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				if (!emailState) {
					form.setFieldsValue({
						faculty_type: undefined
					})
				}
				form.setFieldsValue({
					mail_to: [7]
				})
				break;
			case 'Student - Dismissal':
				setToOption(constants.probationToOption);
				setChooseFaculty(false);
				setTypeId(2)
				getHtmlContent(2)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
			case 'Student - Probation':
				setToOption(constants.probationToOption);
				setChooseFaculty(false);
				setTypeId(5)
				getHtmlContent(5)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
			case 'Student - Advisor Details':
				setToOption(constants.studentToOptions);
				setChooseFaculty(false);
				setTypeId(7)
				getHtmlContent(7)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
			case 'Student - Mentee Assigned':
				setToOption(constants.mentorToOption);
				setChooseFaculty(false);
				setTypeId(4)
				getHtmlContent(4)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
			case 'Student - Mentor Request':
				setToOption(constants.mentorToOption);
				setChooseFaculty(false);
				setTypeId(8)
				getHtmlContent(8)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
			case 'Student - Mentor Details':
				setToOption(constants.studentAdvisorToOption);
				setChooseFaculty(false);
				setTypeId(6)
				getHtmlContent(6)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
			case 'General - Other':
				setToOption(constants.ToList);
				setChooseFaculty(false);
				setFacultyType(constants.facultyType)
				setTypeId(9)
				setIsMultiple(true)
				getHtmlContent(isAdvisor ? 11 : 9)
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
				}
				break;
			default:
				setToOption(constants.ToList);
				setChooseFaculty(false);
				setFacultyType(constants.facultyType)
				setTypeId(null)
				getHtmlContent('')
				if (!emailState && !mentorTypeEmail && !studentState) {
					setSelectedRowKeys([])
					setIsMultiple(false)
				}
				break;
		}
		handleSubjectMapping(e)
		setYearInProgramArray([])
	}

	const handleSubjectMapping = (e) => {
		//Mapping of subject
		const subjectMapping = {
			'Student - Username/Password': emailSubject?.[0]?.subject,
			'Student - Dismissal': emailSubject?.[1]?.subject,
			'Staff - Advising Students': emailSubject?.[2]?.subject,
			'Student - Mentee Assigned': emailSubject?.[3]?.subject,
			'Student - Probation': emailSubject?.[4]?.subject,
			'Student - Mentor Details': emailSubject?.[5]?.subject,
			'Student - Advisor Details': emailSubject?.[6]?.subject,
			'Student - Mentor Request': emailSubject?.[7]?.subject,
			'General - Other': emailSubject?.[8]?.subject,
			'Staff - Username/Password': emailSubject?.[0]?.subject
		};
		const defaultSubject = '';
		form.setFieldsValue({
			mail_subject: subjectMapping[e] || defaultSubject,
		});
	}
	const generateMailToArray = (e, options) => {
		let arr = [...yearInProgramArray]
		let index = arr.findIndex((item) => item === options.name)
		if(index > -1) {
			arr.splice(index, 1)
		} else {
			if(e === 7) return
			arr.push(options.name)
		}
		setYearInProgramArray(arr)
	}
	const handleSetTo = (e) => {
		setTo(e)
		form.setFieldsValue({
			email_id_type:undefined
		})
		if (Array.isArray(e)) {
			if(e.length>2){
				setToSpan({...toSpan,toSize:9})
			}else{
				setToSpan({...toSpan,toSize:6})
			}
			//Checking to value contains faculty when other type is choosen
			if (e.includes(7)) {
				setChooseFaculty(true);
			} else {
				setChooseFaculty(false);
			}
			if (e.includes(7) && e.length === 1) {
				setSemesterDisplay(false)
			} else {
				setSemesterDisplay(true)
			}
		}

		//Mapping of mail recipient field when to value is choosen
		if (!emailState && from !== 'General - Other') {
			const mapping = {
				1: 'Freshman Class',
				2: 'Sophomore Class',
				3: 'Junior Class',
				4: 'Senior Class',
				5: 'Graduate Class',
				6: 'Part Time',
				10: 'Non-Logged in Students',
				11: 'Transfer Students',
			};
			setRecipient(mapping[e] || '');
			form.setFieldsValue({
				mail_recipient: mapping[e] || '',
			});
		}
	}
	//Mapping of recipient field if type choosen is faculty
	const handleChangeFaculty = (e) => {
		setFaculty(e)
		if (!emailState && !mentorTypeEmail && !studentState) {
			setSelectedRowKeys([])
		}
		if (!emailState && to === 7) {
			const mapping = {
				1: 'Admin',
				2: 'Faculty',
				3: 'System Administrator',
				4: 'Adjunct Faculty',
				5: 'Clinical Supervisor',
			};
			setRecipient(mapping[e] || '');
			form.setFieldsValue({
				mail_recipient: mapping[e] || '',
			});
		}
		if (Array.isArray(e)) {
			if(e.length>2){
				setToSpan({...toSpan,staffSpan:9})
			}else{
				setToSpan({...toSpan,staffSpan:5})

			}
		}
	}
	//A function to handle recipient
	const handleRecipients = () => {
		if (!from && from.trim() === '') {
			message.error(`Select the "type" option `)
			scrollUp()
		} else if (!to && to.trim() === '' && isAdvisor === false) {
			message.error(`Select the "to" option `)
			scrollUp()
		} else if (from === 'Staff - Username/Password' && faculty === 0) {
			message.error(`Select the "staff type" option`)
			scrollUp()
		} else if (chooseFaculty && faculty === 0) {
			message.error(`Select the "staff type" option "`)
		} else if ((!semesterCheck && semesterInput && semesterDisplay) && form.getFieldValue('semester_type') === undefined) {
			message.error(`Select the "semester" option`)
		} else {
			handleShowRecipientModal()
			let advisorPayload = {
				faculty_id: userId,
				semester_type: !semesterCheck && semesterInput && semesterDisplay ? semester : 0,
				mail_type: typeId,
			}
			let notAdvisorPayload = {
				mail_type: typeId,
				mail_to: to,
				faculty_type: faculty,
				semester_type: semester
			}
			let payload = isAdvisor ? advisorPayload : notAdvisorPayload
			const url = isAdvisor ? '/get-advisor-student-assign-details' : `/get-all-recipients`
			fetchStaffApi(url, 'post', payload)
				.then((res) => {
					if (res.code === '200') {
						const emailDataWithKeys = res.data.map((record, index) => {
							return {
								...record,
								key: index, // or you can use any unique identifier if available in your data
							};
						});
						
						setRecipientData([])
						setFilterTableData([])
						setRecipientData(emailDataWithKeys)
						setFilterTableData(emailDataWithKeys)
					} else {
						setRecipientData([])
						setFilterTableData([])
					}
				})
				.catch((error) => {
				});
		}
	}
	const handleShowRecipientModal = () => {
		setShowRecipientModal(true);
	};
	const handleCloseRecipientModal = () => {
		setSelectedRowKeys(savedSelectedRowKeys)
		setSelectedEmail('')
		setShowRecipientModal(false);
		setSearchText('');
		setTotalRecords(10);
	};

	//Assigning multiple records to a single record
	if (recipientData && recipientData.length > 0) {
		recipientData.forEach(record => {
			if (record.student_name) {
				record.full_name = `${record.student_name}`;
			}
			else {
				record.full_name = `${record.faculty_name}`;
			}
		});
	}
	//Search functionality
	const onSearch = (event) => {
		const search = event.target.value;
		const fieldNames = [isAdvisor ? 'student_name' : 'full_name', 'year','gpa']
		setSearchText(search);
		let searchedValue = performSearch(search, filteredData, fieldNames);
		setRecipientData(searchedValue);
	};
	//Function to get htmlData
	const getHtmlContent = async (id) => {
		let url = `/mail-type?value=${id}`
		if(parseInt(id) === 11) {
			url+=`&id=${userId}`
		}
		await fetchStaffApi(url, 'GET')
			.then(res => {
				if (res) {
					let content = DOMPurify.sanitize(res);
					const defaultContent = '';
					form.setFieldsValue({
						mail_content: content || defaultContent,
					});
				} else {
					message.error(constants.ContentNotFound)
				}
			})
			.catch(error => {
			});
	};
	//Getting semester data
	const getSemester = async () => {
		await fetchApi(`/semester-data`, 'GET')
			.then(res => {
				if (res?.code === '200') {
					setSemesterOption(res?.data)
					if (res.data?.length === 2) {
						setSemesterInput(true)
						setSemesterDisplay(true)
					}
				}
			})
			.catch(error => {
			});
	};

	const handleRow = (selectedRowKeys, selectedRows) =>{
		const fullNames = selectedRows.map(row => {
			if (selectedRowKeys.length === filteredData.length) {	
				setSelectedAll(true)
				if(faculty > 0) {
					const mapping = {
						1: 'Admin',
						2: 'Faculty',
						3: 'System Administrator',
						4: 'Adjunct Faculty',
						5: 'Clinical Supervisor',
					};
					setRecipient(mapping[faculty] || '');
					form.setFieldsValue({
						mail_recipient: mapping[faculty] || '',
					});
				} else if(to > 0) {
					const mapping = {
						1: 'Freshman Class',
						2: 'Sophomore Class',
						3: 'Junior Class',
						4: 'Senior Class',
						5: 'Graduate Class',
						6: 'Part Time',
						10: 'Non-Logged in Students',
						11: 'Transfer Students',
					};
					setRecipient(mapping[to] || '');
					form.setFieldsValue({
						mail_recipient: mapping[to] || '',
					});
				}		
			} else {
				setSelectedAll(false)
			}
			if (row.faculty_name !== undefined) {
				return `${row.faculty_name}`;
			} else {
				return `${row.student_name}`;
			}
		});
		setSelectedRecord(selectedRows)
		const selectedRowId = selectedRows.map((record) => record.id)
		setSelectedRowKeys(selectedRowId)	
		fullNames.sort();
		const NamesString = fullNames.toString()
		const afterAddSpace = NamesString.replace(/,/g, ", ")
		setSelectedEmail(afterAddSpace.replace(/\s+/g, ' '))
	}
	const rowSelectionCheckbox = {
		type: 'checkbox',
		columnWidth: '5%',
		selectedRowKeys: 
			selectedRowKeys?.length > 0 ? selectedRowKeys : 
			selectedRowKeys?.length === 0 ? [] : 
			savedSelectedRowKeys,
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? filteredData?.filter((item) => item?.student_assigned_status !== '0') : []
			onSelectChange(
			data?.map((item) => item.id),
			data,
			null,
			null,
			true
		)},
		onSelect: (record, selected, selectedRows) => {
			const isSelected = selectedRowKeys.includes(record.id);
			const newSelectedRowKeys = isSelected
				? selectedRowKeys.filter(key => key !== record.id)
				: [...selectedRowKeys, record.id];
			
			const newSelectedRowData = filteredData.filter(data => newSelectedRowKeys.includes(data.id));

			onSelectChange(
				newSelectedRowKeys, // Update with new selected row keys
				newSelectedRowData, // Update with new selected row data
				record, // Current selected record
				filteredData.findIndex(item => item.id === record.id) // Index of the current record
			);
		},
	};
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: selectedRowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setSelectedRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)
		handleRow(newSelectedRowKeys, selectedRowData)
  };
	//Setting recipient to input field when they are choosen
	const saveRecipient = () => {
		if (!selectedAll) {
			form.setFieldsValue({
				mail_recipient: selectedEmail
			})
		}
		if (selectedRecord.length > 0) {
			setSavedSelectedRowKeys(selectedRowKeys)
			setShowRecipientModal(false);
		} else {
			setSavedSelectedRowKeys([])
			message.error('Select at least one recipient')
		}
		setSearchText('');
	}
	//Function to handle submission of email
	const handleEmail = (formData) => {
		setLoading(true)
		formData.mail_type = typeId
		if (formData.mail_attachment !== null && formData.mail_attachment !== undefined) {
			formData.mail_attachment = file[0].originFileObj;
		}
		if (from === 'General - Other') {
			formData.mail_recipient = recipientId
		} else {
			formData.mail_recipient = selectedRowKeys
		}
		if (emailState || mentorTypeEmail || studentState) {
			let toList
			if (emailState) {
				if (emailState[0]?.faculty) {
					toList = [...new Set(emailState && emailState.map((record) => record?.user_role))];
				} else {
					toList = [...new Set(emailState && emailState.map((record) => record?.year_in_program_text))];
				}
			}
			const yearWiseStudents = [...new Set(mentorTypeEmail && mentorTypeEmail.map((record) => {
				let studentRole;
				if (record.year_in_program === '3') {
					studentRole = 'Junior'
				} else if (record.year_in_program === '4') {
					studentRole = 'Senior'
				}
				return studentRole
			}))
			];
			const roleNames = [...new Set(studentState && studentState.selectedRow.map((record) => {
				let studentRole;
				if (record.year_in_program === '3') {
					studentRole = 'Junior'
				} else if (record.year_in_program === '1') {
					studentRole = 'Freshman'
				} else if (record.year_in_program === '2') {
					studentRole = 'Sophomore'
				} else if (record.year_in_program === '5') {
					studentRole = 'Graduate'
				} else if (record.year_in_program === '6') {
					studentRole = 'Part Time'
				} else if (record.year_in_program === '4') {
					studentRole = 'Senior'
				}
				return studentRole
			}))
			];
			formData.mail_to = emailState ? toList?.toString() : mentorTypeEmail ? yearWiseStudents?.toString() : studentState ? roleNames : '';
		} else {
			formData.mail_to = recipient
		}

		if (!emailState || !mentorTypeEmail || !studentState) {
			if (formData.mail_to.includes('Class')) {
				formData.mail_to = formData.mail_to.replace(/\bClass\b/g, '');
			}
		}
		if (formData.mail_content && formData.mail_content.level && formData.mail_content.level.content) {
			formData.mail_content = formData.mail_content.level.content;
		}
		if (from === 'General - Other' && !emailState && !mentorTypeEmail) {
			let strYear
			if (otherTo.endsWith(',')) {
				strYear = otherTo.slice(0, -1);
			}
			formData.mail_to = strYear ? strYear : yearInProgramArray?.join(', ')
		}
		let payload = {
			mail_type: formData.mail_type,
			mail_to: !isAdvisor?formData.mail_to:[...new Set(selectedRecord.map(record => record.class))].join(', '),
			mail_content: removeExtraSpaces(formData.mail_content),
			mail_recipient: formData.mail_recipient ? [...new Set(formData.mail_recipient)] : [],
			email_id_type: formData.email_id_type ? formData.email_id_type : emailIdType,
			mail_attachment: formData.mail_attachment,
			semester_type: formData.semester_type ? formData.semester_type : 0,
			mail_subject: removeExtraSpaces(formData.mail_subject)
		}

		if (chooseFaculty) {
			payload = {
				...payload,
				faculty_type: formData.faculty_type
			}
		}
		if (emailState || mentorTypeEmail || studentState) {
			payload = {
				...payload, list_mail: 1
			}
			if (emailState) {
				if (emailState[0]?.faculty) {
					payload = {
						...payload,
						faculty_type: formData.faculty_type
					}
				}
			}
		}
		if (payload?.mail_recipient?.length > 0) {
			//Calling send email api
			setChooseFaculty(false)
			errorRef.current.scrollIntoView({ behavior: "smooth" });
			sendEmail(payload, setLoading, form);
			setChooseFaculty(false)
			setFrom('')
			setTo('')
			setSelectedRowKeys([]);
			setYearInProgramArray([]);
			setFromOption(constants.NewFromList)
			setToOption(constants.ToList)
			setStaffLinked(false)
			setFile([])
			window.history.replaceState({}, '')
			if (isAdvisor) {
				setFromOption(constants.OnlyOtherOption)
			}
			form.resetFields()
			if (emailState || mentorTypeEmail || studentState) {
				navigate({
					pathname: location.pathname,
					search: location.search,
					state: {
							...location.state,
							emailState: null,
					},
			}, { replace: true });
			}
		} else {
			setLoading(false)
			scrollUp()
			message.error('Please select the recipients')
		}
	}

	//Function to handle file upload
	const uploadHandler = (info) => {
		if (info?.fileList) {
			let checkBeforeUpload = beforeUploadCheckFileType(info?.file,false);
			if(checkBeforeUpload){
				setFile(info.fileList);
			}
		}
	};

	//Function to scroll to required field value
	const scrollToRequiredField = (errorFields) => {
		if (errorFields && errorFields.length > 0) {
			const firstRequiredField = errorFields.find(field => field.errors.some(error => error.includes('required')));
			if (firstRequiredField) {
				const fieldName = firstRequiredField.name;
				const fieldElement = formRef.current.getFieldInstance(fieldName);
				if (fieldElement) {
					fieldElement.focus();
					fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			}
		}
	};
	const handleFinishFailed = (errorInfo) => {
		scrollToRequiredField(errorInfo.errorFields);
	};

	const handleRecordSort = (e) => {
		let nonLoggedPayload = {
			mail_type: typeId,
			mail_to: 10,
			year: e,
			faculty_type: faculty,
			semester_type: semester
		}
		const url = `/get-all-recipients`
		fetchStaffApi(url, 'post', nonLoggedPayload)
			.then((res) => {
				if (res.code === '200') {
					const emailDataWithKeys = res.data.map((record, index) => {
						return {
							...record,
							key: index, // or you can use any unique identifier if available in your data
						};
					});
					setRecipientData(emailDataWithKeys)
					setFilterTableData(emailDataWithKeys)
				} else {
					setRecipientData([])
					setFilterTableData([])
				}
			})
			.catch((error) => {
			});

	}

	// const onRowClick = (record) => {
  //   const selectedIndex = selectedRowKeys.indexOf(record.id);
  //   let newSelectedRowKeys = [...selectedRowKeys];
  //   let newSelectedRows = [];

  //   if (selectedIndex === -1) {
  //     newSelectedRowKeys.push(record.id);
  //   } else {
  //     newSelectedRowKeys.splice(selectedIndex, 1);
  //   }

  //   newSelectedRows = newSelectedRowKeys.map(key => filteredData.find(item => item.id === key));
  //   handleRow(newSelectedRowKeys, newSelectedRows);
  // };

	const onSemesterChange = (e) => {
		setSemester(e)
		if(!studentState){
			setSelectedRowKeys([]);
		}
		setSavedSelectedRowKeys([]);
	}

	const showStaffTypeDropdown = () => {
		let flag = (chooseFaculty || staffLinked) && !isAdvisor
		if(!flag) {
			form.setFieldsValue({
				faculty_type: null,
			})
		}
		return flag
	}

	return (
		<div className='email-container' id='top' ref={errorRef}>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom px-3">
				<Spin tip={constants.PleaseWait} spinning={loading}>
					<Form layout='vertical' className='mail-form' form={form} onFinish={handleEmail}
						ref={formRef}
						onFinishFailed={handleFinishFailed}>
						<Row justify="start" gutter={24}>
							{/* Type input field */}
							<Col xl={6} lg={6} md={24} sm={24} xs={24}>
								<Form.Item
									label={constants.Type}
									name="mail_type"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
									]}
								>
									<Select placeholder='Select Type' listHeight={400} onChange={handleFromChange} allowClear
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().includes(input.toLowerCase())
										}
									>
										{fromOption.map(category => (
											<Select.OptGroup key={category.title} label={category.label}>
												{category.options.map(option => (
													<Select.Option value={option.value} key={option.key}>
														{option.label}
													</Select.Option>
												))}
											</Select.OptGroup>
										))}
									</Select>
								</Form.Item>
							</Col>
							{/* To input field */}
							{!isAdvisor ? <Col xl={toSpan.toSize} lg={toSpan.toSize} md={24} sm={24} xs={24}>
								<Form.Item
									label={constants.To}
									name="mail_to"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
									]}
								>
									<Select 
										allowClear
										showSearch 
										placeholder='To' 
										onChange={handleSetTo} 
										onSelect={(e, options) => generateMailToArray(e, options)}
										onDeselect={(e, options) => generateMailToArray(e, options)}
										disabled={location.state?.emailState || mentorTypeEmail || !from || studentState} 
										mode={isMultiple ? 'multiple' : 'default'}
										value={to} 
										filterOption={(input, option) =>
											option.children.toLowerCase().includes(input.toLowerCase())
										}
									>
										{toOption
											.map((options) =>
												<Select.Option name={options.option} value={options.key} key={options.key}>{options.option}</Select.Option>
											)
										}
									</Select>
								</Form.Item>
							</Col>
								: ''}
							{/*  Semester input field */}
							{!semesterCheck && semesterInput && semesterDisplay ?
								<Col xl={6} lg={6} md={24} sm={24} xs={24}>
									<Form.Item
										label={constants.Semester}
										name="semester_type"
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}
									>
										<Select
											placeholder='Semester'
											maxTagCount='responsive'
											allowClear
											disabled={(emailState||studentState||mentorTypeEmail)?true:!from}
											onChange={(e) => onSemesterChange(e)}
										>
											{semesterOption
												.sort((a, b) => a.semester_is_ended - b.semester_is_ended) // Sort options based on semester_is_ended
												.map(option => {
													const record = constants.StudentSemList.find(record => record.key === option.semester_type);
													if (option.semester_is_ended === '2') {
														return (
															<Select.Option key={record.key} value={2}>{record.value}</Select.Option>
														);
													} else {
														return (
															<Select.Option key={record.key} value={0}>{`Current(${record.value})`}</Select.Option>
														);
													}
												})
											}
										</Select>

									</Form.Item>
								</Col> : ''
							}
							{/* Faculty type input field */}
							{showStaffTypeDropdown() ? 
								<Col xl={toSpan.staffSpan} lg={toSpan.staffSpan} md={24} sm={24} xs={24}>
									<Form.Item
										label={constants.StaffType}
										name="faculty_type"
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}
									>
										<Select 
											placeholder={`${constants.StaffType}`} 
											onChange={handleChangeFaculty}
											onSelect={(e, options) => generateMailToArray(e, options)}
											onDeselect={(e, options) => generateMailToArray(e, options)}
											disabled={location.state?.emailState} 
											mode={location.state?.emailState || from==='General - Other' ? 'multiple' : 'default'} 
											allowClear
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().includes(input.toLowerCase())
											}
										>
											{
												facultyType.map((options) =>
													<Select.Option name={options.option} value={options.key} key={options.key} >{options.option}</Select.Option>
												)
											}
										</Select>
									</Form.Item>
								</Col> : ''
							}
							{/* Email type will only appear if freshman is selected*/}
							{to === 1 && !isAdvisor ?
								<Col xl={4} lg={4} md={24} sm={24} xs={24}>
									<Form.Item
										label={constants.EmailType}
										name="email_id_type"
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}
									>
										<Select placeholder='Email Type' maxTagCount='responsive' allowClear>
											{
												emailType.map((options) =>
													<Select.Option value={options.key} key={options.key} >{options.option}</Select.Option>
												)
											}
										</Select>
									</Form.Item>
								</Col> : ''
							}
							{!location.state?.emailState && !mentorTypeEmail && !studentState ?
								<Col xl={4} lg={4} md={24} sm={24} xs={24}>
									<Form.Item
										label="      "
										rules={[
											{
												required: false,
											},
										]}
									>
										<AntBtn className="btn-info"
											disabled={location.state?.emailState || mentorTypeEmail}
											onClick={handleRecipients}>{constants.SelectRecipient}</AntBtn>
									</Form.Item>
								</Col> : ''
							}
						</Row>
						{/* Recipient input field */}
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									label={constants.Recipient}
									name="mail_recipient"
									rules={[
										{
											required: false,
											message: 'Recipient',
										},
									]}
								>
									<TextArea value={recipient} autoSize readOnly />
								</Form.Item>
							</Col>
						</Row>
						{/* Subject field value */}
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									label={constants.Subject}
									name="mail_subject"
									rules={[
										{
											required: false,
											message: 'subject',
										},
									]}
								>
									<Input placeholder='Subject' />
								</Form.Item>
							</Col>
							{/* <Col span={24}>
							<Flex gap={4}>{constants.EmailContentMsg}</Flex>
						</Col> */}
							{/* Content field value */}
							<Col span={24}>
								<Form.Item
									label={constants.EmailContentMsg}
									name="mail_content"
									rules={[
										{
											required: false,
											message: 'body',
										},
									]}
								>
									<Editor
										textareaName='body'
										tinymceScriptSrc={constants.TextEditorSrcPath}
										onInit={(evt, editor) => {
											editorRef.current = editor;
										}}
										onEditorChange={(content) => {
											form.setFieldsValue({ mail_content: content })
										}}
										init={{
											toolbar: constants.TinyMceToolbar,
											content_style: constants.TinyMceContentStyle,
											statusbar:false,
											readonly: true,
											menubar: false,
                      branding: false,
											height: 400,
											plugins: constants.TinyMcePlugins,
											toolbar_mode: 'sliding',
											noneditable_class: 'uneditable',
											setup: function (editor) {
												editor.on('dragstart', function (e) {
													if (e.target && e.target.classList && e.target.classList.contains('uneditable')) {
														e.preventDefault();
													}
												});
											
										}}}
									/>
								</Form.Item>
							</Col>
							{/* Attachment input field */}
							<Col span={24}>
								<Form.Item
									label="Attachments"
									name="mail_attachment"
									rules={[
										{
											required: false,
											message: 'attachment',
										},
									]}
								>
									<FileUpload
										renderContent={<AntBtn icon={<UploadOutlined />}>{constants.ChooseFile}</AntBtn>}
										name='file'
										listType='text'
										className=''
										showUploadList={true}
										isMultiple={false}
										beforeUpload={beforeUploadCheckFileType}
										onChange={uploadHandler}
										defaultFileList={file.length > 0 ? file : []}
										fileCount={1}
									/>
								</Form.Item>
							</Col>
							<Col span={24}>
								<span className='mail-detail'>{constants.ValidFileType}</span>
							</Col>
							<Col span={24}>
								<span className='mail-detail'>{constants.ValidFileSize}</span>
							</Col>
						</Row>
						<Flex gap="small" align="flex-end" vertical >
							<AntBtn className=" text-capitalize btn-info" htmlType='submit' >{constants.Send}</AntBtn>
						</Flex>
					</Form>
				</Spin>
			</Card>
			{/* Recipient Modal */}
			<Modal 
				title={<span className='pr-3 lg:pr-0'>{`Email Recipients - ${from}`}</span>} 
				open={showRecipientModal} 
				onCancel={handleCloseRecipientModal} 
				width={800} 
				footer={null} 
				className='!top-5'
			>
				{to === 10 ?
					<>
						<Row>
							<Col offset={19} span={5} >
								<Flex justify='end'>
									<Select
										placeholder='Year'
										style={{
											width: 120,
										}}
										onChange={handleRecordSort}
										allowClear={true}
									>
										{constants.NonLoggedInStudentOptions.map((record) =>
											<Select.Option value={record.key} key={record.key} >{record.option}</Select.Option>
										)}
									</Select>
								</Flex>
							</Col>
						</Row>
						<br>
						</br>
					</>
					: ''
				}
				<div className="flex flex-wrap justify-between items-center">
						<Space>Show
							<Select
								style={{
									width: 120,
								}}
								onChange={(e) => setTotalRecords(e)}
								allowClear
								value={totalRecords}
							>
								{
									entries.map((options) =>
										<Select.Option key={options.key} value={options.option}>{options.option}</Select.Option>
									)
								}
							</Select>
							Entries
						</Space>
							<Input placeholder='Search'
								className='mt-2 lg:mt-0 w-full lg:w-48'
								onChange={onSearch}
								value={searchText}
							/>
				</div>
				<Table columns={columns} dataSource={recipientData} bordered
					size="small"
					rowKey={(record) => record.id}
					rowSelection={{
						...rowSelectionCheckbox,
						getCheckboxProps: (record) => ({
							disabled: record.student_assigned_status === '0',
						}),
					}}
					className="records-table data_table mt-2" rowSelectedBg='#e6f4ff' pagination={{
						pageSize: totalRecords,
						showTotal: (total, range) => showTotalItems(total, range, recipientData),
						itemRender: getNextAndPreviousButtons,
					}} searchableProps={{ fuzzySearch: true }}
					scroll={{ x: 600, y: '50vh' }}
					rowClassName={(record) => record.student_assigned_status === '0' ? 'disable-row' : ''}
					onRow={(record) => ({
						onClick: () => {
							const isSelected = selectedRowKeys.includes(record.id);
							const newSelectedRowKeys = isSelected
								? selectedRowKeys.filter(key => key !== record.id)
								: [...selectedRowKeys, record.id];
							
							const newSelectedRowData = filteredData.filter(data => newSelectedRowKeys.includes(data.id));
				
							onSelectChange(
								newSelectedRowKeys, // Update with new selected row keys
								newSelectedRowData, // Update with new selected row data
								record, // Current selected record
								filteredData.findIndex(item => item.id === record.id) // Index of the current record
							);
						},
					})}
					 />
				<br>
				</br>
				<Flex gap="small" align="flex-end" vertical>
					<Flex gap="small" wrap="wrap">
						<AntBtn type="primary" className='action-btn' danger onClick={handleCloseRecipientModal} >
							{constants.Cancel}
						</AntBtn>
						<AntBtn type="primary" className='action-btn' htmlType='submit' onClick={saveRecipient}>
							{constants.Save}
						</AntBtn>
					</Flex>
				</Flex>
				<br>
				</br>
			</Modal>
		</div>
	)
}
export default SendEmail
