import React, { useState, useEffect, useRef } from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import constants from '../../Constants/constants';
import { Button, Checkbox, Col, DatePicker, Divider, Flex, Form, Input, Row, Select, message, Card, Space, Spin } from 'antd';
import FileUpload from '../Component/FileUpload';
import { UserAddOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { allowAlphabets, beforeProfileUpload, beforeUploadCheckFileType, generateNumberValidator, getBase64, disableSpaceBar, handleKeyPressHandler, removeExtraSpaces, checkEmptySpace } from '../../GlobalFunctions/GlobalFunctions';
import { fetchApi, fetchStaffApi, fetchStaffFormData } from '../../Services/Api';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { COOKIE, getCookie } from '../../Services/Cookie';
import { useFetchStates, useFetchPermaStates } from '../Component/FacultyComponents/FetchStates';

const { Option } = Select;
const { TextArea } = Input;

const Registration = () => {
	const errorRef = useRef(null);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [imageURL, setImageURL] = useState("");
	const [profileFile, setProfileFile] = useState([]);
	const [loading, setLoading] = useState(false);
	const [onSaveLoader, setOnSaveLoader] = useState(false);
	const [isTransferChecked, setIsTransferChecked] = useState(false);
	const [isOnlineChecked, setIsOnlineChecked] = useState(false);
	const [listCountries, setListCountries] = useState([]);
	const [showPermanentAdress, setShowPermanentAdress] = useState(false);
	const [uploadFileList, setUploadFileList] = useState([]);
	const [listAdvisors, setListAdvisors] = useState([]);
	const [studentType, setStudentType] = useState('');
	const [disableState, setDisableState] = useState({
		current: true,
		permanent: true
	});
	const [semesterData, setSemesterData] = useState([])
	const [mentorDetails, setMentorDetails] = useState([])
	const [isMentorDisabled, setIsMentorDisabled] = useState(true)
	const { permaStates, fetchPermaStates } = useFetchPermaStates();
	const { states, fetchStates } = useFetchStates();
	const [colSize,setColSize] = useState(8)

	useEffect(() => {
		getCountries();
		getAdvisorList();
		getSemester()
	}, []);

	const breadcrumbItems = [
		{ label: constants.StudentRegistration, active: true },
	];

	//Removing all null values.
	function filterNonNullValues(obj) {
		const filteredObj = {};
		Object.keys(obj).forEach((key) => {
			const value = obj[key];
			if (value !== null && value !== undefined && value !== '') {
				filteredObj[key] = value;
			}
		});
		return filteredObj;
	}

	const handleSave = (values) => {
		setOnSaveLoader(true)
		let payload = {
			student_first_name: removeExtraSpaces(values?.first_name),
			student_middle_name: removeExtraSpaces(values?.middle_name),
			student_last_name: removeExtraSpaces(values?.last_name),
			student_id_number: values?.student_id?.replace(/\s+/g, '') || '',
			student_dob: values?.dob ? dayjs(values?.dob).format("YYYY-MM-DD") : "",
			student_asha_number: values?.asha_num,
			student_psha_number: values?.psha_num,
			student_mobile_number: values?.cell_no,
			student_home_number: values?.home_phn,
			student_personal_email: removeExtraSpaces(values?.p_email?.toLowerCase()),
			student_university_email: removeExtraSpaces(values?.mu_email?.toLowerCase()),
			student_sat_combined_super: values?.sat_combined_super,
			student_sat_reading_super: values?.sat_reading_super,
			student_sat_math_super: values?.sat_math_super,
			student_act_composite_super: values?.act_composite_super,
			student_identify: removeExtraSpaces(values?.identify_as),
			student_type: values?.type,
			student_current_country: values?.current_country,
			student_current_state: values?.current_state,
			student_current_city_text: removeExtraSpaces(values?.current_city),
			student_address: removeExtraSpaces(values?.current_street_adress),
			student_zip: values?.current_zip,
			student_notes: removeExtraSpaces(values?.notes),
			admin_id: getCookie(COOKIE.UserId),
			is_transfer: isTransferChecked === false ? "2" : "1",
			is_active: "0",
			year_in_program: values?.year_program,
			mentor_id: values?.mentor,
			faculty_id: values?.advisor,
			student_semester: values?.semester_type || '0',
			online: isOnlineChecked === true ? "1" : "0",
		}
		if (showPermanentAdress) {
			payload.address_check = "1";
		} else {
			payload.address_check = "0";
			payload.student_permanent_country = values?.permanent_country;
			payload.student_permanent_state = values?.permanent_state;
			payload.student_permanent_city_text = removeExtraSpaces(values?.permanent_city);
			payload.student_permanent_address = removeExtraSpaces(values?.permanent_street_adress);
			payload.student_permanent_zip = values?.permanent_zip;
		}
		if(isTransferChecked) {
			payload.student_gpa = values?.transfer_gpa
			payload.transfer_details = values?.transfer_details
			payload.joining_date = values?.joining_date ? dayjs(values?.joining_date).format("YYYY-MM-DD") : ""
		} else payload.student_gpa = values?.non_transfer_gpa

		const filteredObject = filterNonNullValues(payload);
		handleUpload(filteredObject);
	};

	const handleUpload = async (payload) => {
		try {
			const formData = new FormData();
			//Appending profile image file.
			profileFile.forEach((file) => {
				if (file.originFileObj) {
					formData.append('student_profile', file.originFileObj);
				}
			});
			//Appending Upload file.
			uploadFileList.forEach((file) => {
				if (file.originFileObj) {
					formData.append('student_doc[]', file.originFileObj);
				}
			});
			// Append array parameter (other form values)
			Object.entries(payload).forEach(([key, value]) => {
				formData.append(`${key}`, value);
			});
			fetchStaffFormData(`/student-registration`, 'POST', formData).then((res) => {
				if (res.code === "200") {
					setProfileFile([]);
					setUploadFileList([]);
					setIsOnlineChecked(false);
					setIsTransferChecked(false);
					form.resetFields();
					message.success(res?.message);
					errorRef.current.scrollIntoView({ behavior: "smooth" });
				} else {
					message.error(res?.message);
				}
				setOnSaveLoader(false)
			});
		} catch (error) {
			message.error(error.message);
			setOnSaveLoader(false)
		}
	};

	const uploadButton = (
		<button
			className='bg-transparent border-0'
			type="button"
		>
			{loading ? <LoadingOutlined /> : <UserAddOutlined className='fs-1' />}
		</button>
	);

	const profileRenderContent = imageURL ? (
		<img src={imageURL} alt="avatar" className='photoPick' />
	) : (
		uploadButton
	);

	//function to handle profile change.
	const handleProfileChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response.
			getBase64(info.file.originFileObj, (url) => {
				setLoading(false);
				setImageURL(url);
			});
		}
		if (info?.fileList) {
			setProfileFile(info.fileList);
		}
	};

	//handle Transfer
	const handleTransfer = (e) => {
		if (e.target.checked) {
			form.setFieldsValue({
				non_transfer_gpa: '',
			})
			setIsTransferChecked(true);
		} else {
			form.setFieldsValue({
				transfer_details: '',
				transfer_gpa: '',
			})
			setIsTransferChecked(false);
		}
	};

	//function to return similer form field
	const getSimilerForm = (label, formfield, is_required, check_alphabet = null) => {
		let rules = []
		if(!check_alphabet)
			rules.push({ pattern: /^\d*\.?\d+$/, message: 'Only numerics are allowed.' })
		if (is_required) {
			return <Col xs={24} lg={8}>
				<Form.Item
					label={label}
					name={formfield}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
					]}
				>
					{check_alphabet !== null ? 
						<Input
							onKeyPress={(event) => check_alphabet(event)}
							className="faculty-inp" 
							placeholder={label} 
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
						/> : 
						<Input 
							className="faculty-inp" 
							placeholder={label} 
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
						/>}
				</Form.Item>
			</Col>
		} else {
			return <Col xs={24} lg={8}>
				<Form.Item
					label={label}
					name={formfield}
					rules={[...rules]}
				>
					{check_alphabet !== null ? 
						<Input 
							onKeyPress={(event) => check_alphabet(event)} 
							className="faculty-inp" 
							placeholder={label}
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
						/> : 
						<Input 
							className="faculty-inp" 
							placeholder={label}
							onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(formfield))}
						/>}
				</Form.Item>
			</Col>
		}
	};

	//Common generateNumberValidator function for Cell Phone, PSHA and ASHA form field validation.
	const validateAshaNumber = generateNumberValidator(8, constants.AshaValidateMessage);
	const validatePshaNumber = generateNumberValidator(5, constants.PshaValidateMessage);
	// const validateCellNumber = generateNumberValidator(12, constants.MobileValidateMessage);

	const onChangeType = (e) => {
		setStudentType(e)
		if (e === "2") {
			// form.setFieldsValue({ year_program: '6' })
		} else {
			let getValue = form.getFieldValue('year_program') === '6' ? [] : form.getFieldValue('year_program') || []
			form.setFieldsValue({ year_program: getValue })
		}
	};

	//onChange phone number
	const handleCellPhoneInputChange = (e, formval) => {
		const inputValue = e.target.value;
		// const formattedInput = phoneNumberFormatter(inputValue);
		form.setFieldsValue({ [formval]: inputValue })
	};

	//adress form field
	const adressFormField = (adressType, disableState, listStates) => {
		let field_name;
		if (adressType === "current") {
			field_name = "current_";
		} else {
			field_name = "permanent_";
		};
		return <Row gutter={[24, 0]}>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.StreetAddress}
					name={field_name + "street_adress"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
					]}
				>
					<Input 
						placeholder={constants.StreetAddress}
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field_name}street_adress`))}
					/>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.City}
					name={field_name + "city"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
					]}
				>
					<Input 
						placeholder={constants.City}
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field_name}city`))}
					/>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.Zip}
					name={field_name + "zip"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
							whitespace: true,
						},
						// {
						// 	validator: generateNumberValidator(5, constants.ZipCodeValidateMsg),
						// }
					]}
				>
					<Input 
						/*onKeyPress={(e) => handleKeyPressHandler(e, 5)}*/ 
						placeholder={constants.Zip}
						onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`${field_name}zip`))}
					/>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.Country}
					name={field_name + "country"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
						},
					]}
				>
					<Select
						placeholder={constants.Country}
						showSearch
						allowClear
						filterOption={selectFilterOption}
						onChange={(e) => onChangeCountry(e, adressType)}
					>
						{listCountries &&
							listCountries.map((listCountry) => (
								<Option key={listCountry.country_code} value={listCountry.id}>
									{listCountry.country_name}
								</Option>
							))}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					label={constants.State}
					name={field_name + "state"}
					rules={[
						{
							required: true,
							message: constants.RequiredField,
						},
					]}
				>
					<Select
						placeholder={constants.State}
						showSearch
						allowClear
						filterOption={selectFilterOption}
						disabled={disableState}
					>
						{listStates &&
							listStates.map((listState) => (
								<Option key={listState.state_code} value={listState.id}>
									{listState.state_name}
								</Option>
							))}
					</Select>
				</Form.Item>
			</Col>
		</Row>
	};

	//function to filter select option
	const selectFilterOption = (input, option) => {
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};

	//onChange Country
	const onChangeCountry = (val, type) => {
		if (type === "current") {
			setDisableState(prevState => ({
				...prevState,
				current: false
			}));
			form.setFieldsValue({
				current_state: ''
			})
			fetchStates(val);
		} else {
			setDisableState(prevState => ({
				...prevState,
				permanent: false
			}));
			form.setFieldsValue({
				permanent_state: ''
			})
			fetchPermaStates(val)
		}
	};

	//get all country list
	const getCountries = () => {
		fetchApi(`/get-countries`, 'get').then((res) => {
			if (res?.code === "200") {
				setListCountries(res?.data?.countries);
			} else {
				setListCountries([]);
			}
		});
	};

	//onChange Adress function
	const onChangeAdress = (flag) => {
		setShowPermanentAdress(flag);
		if(flag === true) { //CLEAR FIELDS
			form.setFieldsValue({
				permanent_city: '',
				permanent_country:[],
				permanent_state: [],
				permanent_street_adress: '',
				permanent_zip: '',
			})
		}
	};

	//function to handle file's change.
	const handleFilesChange = (info) => {
		if (info?.fileList) {
			let checkBeforeUpload = beforeUploadCheckFileType(info?.file,false);
			if(checkBeforeUpload){
				setUploadFileList(info.fileList);
			}
		}
	};

	const getAdvisorList = (id) => {
		fetchStaffApi(`/get-advising-faculty-details`, 'post').then((res) => {
			if (res?.code === "200") {
				setListAdvisors(res?.data);
				let findIndex = res?.data?.findIndex((advisor) => advisor.id === id);
				form.setFieldsValue({
					advisor_contact_no: res?.data?.[findIndex]?.faculty_mobile_number,
					advisor_email: res?.data?.[findIndex]?.faculty_university_email
				})
			} else {
				setListAdvisors([]);
			}
		});
	};

	const handleSelect = (id, type) => {
		if (type === "advisor") {
			let findIndex = listAdvisors.findIndex((advisor) => advisor.id === id);
			form.setFieldsValue({
				advisor_contact_no: listAdvisors?.[findIndex]?.faculty_mobile_number,
				advisor_email: listAdvisors?.[findIndex]?.faculty_university_email
			})
		} else {
			let findIndex = mentorDetails.findIndex((mentor) => mentor.id === id);
			form.setFieldsValue({
				mentor_contact_no: mentorDetails?.[findIndex]?.student_mobile_number,
				mentor_email: mentorDetails?.[findIndex]?.student_university_email
			})
		}
	};
	const getSemester = async () => {
		const generateSelectDropdown = (data) => {
			let filteredData = data?.map((item) => {
				const semesterObj = constants.StudentSemList?.find((sem) => sem.key === item.semester_type)
				return {
					...item,
					semester: item?.semester_is_ended === '0' ? `Current(${semesterObj?.value})` : semesterObj?.value
				}
			}).sort((a, b) => { return a.semester_is_ended - b.semester_is_ended })
			return filteredData
		}

		await fetchApi(`/semester-data`, 'GET')
			.then(res => {
				if (res.code === '200') {
					let data = res?.data
					setSemesterData(generateSelectDropdown(data));
					const size = data?.length <=1 ? 8 : 4;
					setColSize(size)
					if(size === 8) getAllMentor(data?.[0]?.semester_is_ended)
				}
			}).catch((err) => {
				console.log(err);
			});
	};

	const getAllMentor = (semValue) => {
		let payload = {
			value: 1,
			semester: semesterData?.length <= 1 ? 0 : semValue
		}
		fetchStaffApi('/get-all-mentors', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setMentorDetails(res.data)
				setIsMentorDisabled(false)
			}
		});
	}
	const disableFutureDate = (current) => {
		return current && current > dayjs().endOf('day');
	};

	const yearInProgramOption = (isPartTime) => {
		// if(isPartTime) return <Option value="6">{constants.PartTime}</Option>
		return(
			<>
				<Option value="1">{constants.Freshman}</Option>
				<Option value="2">{constants.Sophomore}</Option>
				<Option value="3">{constants.Junior}</Option>
				<Option value="4">{constants.GraduateYear1}</Option>
				<Option value="5">{constants.GraduateYear2}</Option>
				{isPartTime && <Option value="6">{constants.PartTime}</Option>}
			</>		
		)
	}
	
	return (
		<Spin tip={constants.PleaseWait} spinning={onSaveLoader}>
			<div ref={errorRef}>
				<BreadcrumbHeader pageTitle={constants.StudentRegistration} breadcrumbItems={breadcrumbItems} />
				<Card className="container-border-custom px-3">
					<div className='student-form-header position-relative rounded h-100px mt-2'></div>
					<Form layout="vertical" form={form} onFinish={handleSave} scrollToFirstError={true}>
						<Flex align='center' justify='center' >
							<Form.Item
								className='position-absolute z-3 top-5 lg:top-12'
								name="user_profile"
							>
								<FileUpload
									renderContent={profileRenderContent}
									name='avatar'
									listType='picture-circle'
									className='avatar-uploader'
									showUploadList={false}
									beforeUpload={beforeProfileUpload}
									onChange={handleProfileChange}
									isMultiple={false}
								/>
							</Form.Item>
						</Flex>
						<Row gutter={[24, 0]} className='mt-4'>
							<Col xs={24} lg={16} className='place-content-center'>
								<Space>
								<Form.Item
									name="transfer"
									className='mb-0'
								>
									<Checkbox checked={isTransferChecked} onChange={handleTransfer}>{constants.Transfer}</Checkbox>
								</Form.Item>
								<Form.Item
									name="type2"
									className='mb-0'
								>
									<Checkbox checked={isOnlineChecked} onChange={(e) => setIsOnlineChecked(e.target.checked)}>{constants.Online}</Checkbox>
								</Form.Item>
								</Space>
							</Col>
							{
								isTransferChecked ?
								<>
									<Col xs={24} lg={16} className='mt-3'>
										<Form.Item
											name="transfer_details"
											label={constants.TransferDetails}
											rules={[
												{
													required: isTransferChecked,
													message: constants.RequiredField,
												},
											]}
											className='!mb-0'
										>
											<TextArea placeholder={constants.TransferDetails} autoSize />
										</Form.Item>
									</Col>
									<Col xs={24} lg={4} className='mt-3'>
										<Form.Item
											name="joining_date"
											label={constants.JoiningDate}
											className='!mb-0'
										>
											<DatePicker
												placeholder={constants.JoiningDate}
												disabledDate={(current) => current && current < dayjs().subtract(1, 'day').endOf('day')}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} lg={4} className='mt-3'>
										<Form.Item
											name="transfer_gpa"
											label={constants.TransferGpa}
											rules={[
												{
													required: isTransferChecked,
													message: constants.RequiredField,
												},
												{ 
													pattern: /^([1-3](\.\d+)?|4(\.0+)?)$/, message: 'Only numerics are allowed (Range from 1-4).',
												}
											]}
											className='!mb-0'
										>
											<Input
												placeholder={constants.TransferGpa} 
												onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`transfer_gpa`))}
											/>
										</Form.Item>
									</Col>
								</>
								:
								<Col xs={24} lg={8}>
									<Form.Item
										name="non_transfer_gpa"
										label={constants.Gpa}
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
											{ 
												pattern: /^([1-3](\.\d+)?|4(\.0+)?)$/, message: 'Only numerics are allowed (Range from 1-4).',
											}
										]}
										className='!mb-0'
									>
										<Input
											placeholder={constants.Gpa} 
											className='!w-40'
											onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue(`non_transfer_gpa`))}
										/>
									</Form.Item>
								</Col>
							}
						</Row>
						<Row gutter={[24, 0]} className='mt-4'>
							{getSimilerForm(constants.FirstName, "first_name", true, allowAlphabets)}
							{getSimilerForm(constants.MiddleName, "middle_name", false, allowAlphabets)}
							{getSimilerForm(constants.LastName, "last_name", true, allowAlphabets)}
							{getSimilerForm(constants.StudentId, "student_id", false)}
							<Col xs={24} lg={colSize} xl={colSize}>
								<Form.Item
									label={constants.Type}
									name="type"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
									]}
								>
									<Select
										onChange={onChangeType}
										placeholder={constants.SelectType}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().includes(input.toLowerCase())
										}
										>
										{constants.StudentTypeList.map((record) =>
											<Option value={record.value} key={record.value}>{record.label}</Option>
										)}
									</Select>
								</Form.Item>
							</Col>
							{semesterData.length > 1 ?
								<Col xs={24} lg={colSize} xl={colSize}>
									<Form.Item
										label={constants.Semester}
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}
										name='semester_type'
									>
										<Select 
											placeholder='Semester' 
											onChange={(e) => getAllMentor(e)}
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().includes(input.toLowerCase())
											}
										>
											{
												semesterData?.map((sem) => (
													<Option 
														key={sem.semester_is_ended} 
														value={sem.semester_is_ended}>
															{sem.semester}
													</Option>
												))
											}
										</Select>
									</Form.Item>
								</Col> : ''
							}
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.YearProgram}
									name="year_program"
									rules={[
										{
											required: /*form.getFieldValue('type') === '2' ? false : */true,
											message: constants.RequiredField,
										},
									]}
								>
									<Select 
										showSearch
										optionFilterProp="children"
									/*disabled={form.getFieldValue('type') === '2'}*/ placeholder={constants.SelectYear}>
									{
										studentType === '2' ? yearInProgramOption(true) : yearInProgramOption(false)
									}
								</Select>
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.DOB}
									name="dob"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
									]}
								>
									<DatePicker 
										placeholder={constants.DOB} 
										format={constants.DateFormat} 
										className="w-100" 
										disabledDate={disableFutureDate}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.AshaNumber}
									name="asha_num"
									rules={[
										{
											validator: validateAshaNumber,
										},
									]}
								>
									<Input onKeyPress={(e) => handleKeyPressHandler(e, 8)} placeholder={constants.AshaNumber} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.PshaNumber}
									name="psha_num"
									rules={[
										{
											validator: validatePshaNumber,
										},
									]}
								>
									<Input onKeyPress={(e) => handleKeyPressHandler(e, 5)} placeholder={constants.PshaNumber} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.CellPhone}
									name="cell_no"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
											whitespace: true,
										},
										// {
										// 	validator: validateCellNumber
										// }
									]}
								>
									<Input 
										onChange={(e) => handleCellPhoneInputChange(e, "cell_no")} 
										onKeyPress={(e) => handleKeyPressHandler(e, null)} 
										className="faculty-inp" 
										placeholder={constants.CellPhone} 
									/>
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.HomePhn}
									name="home_phn"
									rules={[

										// {
										// 	validator: validateCellNumber
										// }
									]}
								>
									<Input 
										onChange={(e) => handleCellPhoneInputChange(e, "home_phn")} 
										onKeyPress={(e) => handleKeyPressHandler(e, null)} 
										placeholder={constants.HomePhn} 
									/>
								</Form.Item>
							</Col>
							{getSimilerForm(constants.IdentifyAs, "identify_as", false, allowAlphabets)}
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.PersonalEmail}
									name="p_email"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
										{
											type: 'email',
											message: constants.InvalidFormat,
										},
									]}
								>
									<Input onKeyDown={disableSpaceBar} placeholder={constants.PersonalEmail} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.MuEmail}
									name="mu_email"
									rules={[
										{
											type: 'email',
											message: constants.InvalidFormat,
										},
									]}
								>
									<Input onKeyDown={disableSpaceBar} placeholder={constants.MuEmail} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.SatCombinedSuper}
									name="sat_combined_super"
								>
									<Input onKeyPress={(e) => handleKeyPressHandler(e, 6)} placeholder={constants.SatCombinedSuper} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.SatReadingSuper}
									name="sat_reading_super"
								>
									<Input onKeyPress={(e) => handleKeyPressHandler(e, 6)} placeholder={constants.SatReadingSuper} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.SatMathSuper}
									name="sat_math_super"
								>
									<Input onKeyPress={(e) => handleKeyPressHandler(e, 6)} placeholder={constants.SatMathSuper} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.ActCompositeSuper}
									name="act_composite_super"
								>
									<Input onKeyPress={(e) => handleKeyPressHandler(e, 6)} placeholder={constants.ActCompositeSuper} />
								</Form.Item>
							</Col>
						</Row>
						<Divider />
						<div className='mb-2'><label><b>{constants.CurrentAddress}</b></label></div>
						{adressFormField("current", disableState.current, states)}
						<Form.Item
							name="is_permanent_adress"
						>
							<Checkbox checked={showPermanentAdress} onChange={(e) => onChangeAdress(e.target.checked)}>{constants.AdressLabel}</Checkbox>
						</Form.Item>
						{
							!showPermanentAdress ?
								<>
									<div className='mb-2'><label><b>{constants.PermanentAddress}</b></label></div>
									{adressFormField("permanent", disableState.permanent, permaStates)}
								</> :
								null
						}
						<Divider />
						<Row gutter={[24, 0]} className='mt-4'>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.Notes}
									name="notes"
								>
									<TextArea 
										rows={2} 
										showCount 
										maxLength={255}
										placeholder={constants.Notes}
										onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('notes'))}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.UploadDoc}
									name="upload_doc"
								>
									<FileUpload
										renderContent={<Button icon={<UploadOutlined />}>{constants.ChooseFile}</Button>}
										name='file'
										listType='text'
										className=''
										showUploadList={true}
										isMultiple={true}
										beforeUpload={beforeUploadCheckFileType}
										onChange={handleFilesChange}
										defaultFileList={uploadFileList.length > 0 ? uploadFileList : []}
									/>
									<div>{constants.ValidFileType}</div>
								</Form.Item>
							</Col>
						</Row>
						<Divider />
						<Row gutter={[24, 0]} className='mt-4'>
							<Col xs={24} lg={8}>
								<Form.Item
									label={"Advisor's Name"}
									name={"advisor"}
								>
									<Select
										showSearch
										allowClear
										placeholder={constants.Select}
										filterOption={selectFilterOption}
										onChange={(id) => handleSelect(id, "advisor")}
									>
										{listAdvisors &&
											listAdvisors.map((val) => (
												<Option key={val.id} value={val.id}>
													{val.faculty}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.Email}
									name={"advisor_email"}
								>
									<Input disabled={true} placeholder={constants.Email} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.ContactNo}
									name={"advisor_contact_no"}
								>
									<Input disabled={true} placeholder={constants.ContactNo} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={"Mentor's Name"}
									name={"mentor"}
								>
									<Select
										showSearch
										allowClear
										placeholder={constants.Select}
										filterOption={selectFilterOption}
										onChange={(id) => handleSelect(id, "mentor")}
										disabled={isMentorDisabled}
									>
										{
											mentorDetails.map((record) =>
												<Select.Option value={record.id} key={record.id}>{record.student_name}</Select.Option>
											)
										}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.Email}
									name={"mentor_email"}
								>
									<Input disabled={true} placeholder={constants.Email} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={8}>
								<Form.Item
									label={constants.ContactNo}
									name={"mentor_contact_no"}
								>
									<Input disabled={true} placeholder={constants.ContactNo} />
								</Form.Item>
							</Col>
						</Row>
						<Flex gap="small" align="flex-end" vertical className='faculty-action-container'>
							<Flex gap="small" wrap="wrap">
								<Button type="primary" className='action-btn' danger onClick={() => navigate('/student-listing')} >{constants.Cancel}</Button>
								<Button type="primary" className='action-btn' onClick={() => form.submit()}>{constants.Save}</Button>
							</Flex>
						</Flex>
					</Form>
				</Card>
			</div>
		</Spin>
	)
};

export default Registration;