import React, { useState, useEffect } from 'react'
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import constants from '../../../Constants/constants';
import { Modal, Card, Input, Table, Button, Checkbox, Select, Space, Flex, Col, Row, Form, InputNumber, message, Spin } from 'antd';
import { fetchStaffApi } from '../../../Services/Api';
import { performSearch, CustomSortIcon, HighlightedText, getNextAndPreviousButtons, showTotalItems,handleKeyPressHandler, removeExtraSpaces, handleRowSelect, checkEmptySpace } from '../../../GlobalFunctions/GlobalFunctions';
const { Option } = Select;

function SLPCourses() {
	const breadcrumbItems = [
		{ label: 'SLP Courses', active: true },]

	const [dataSource, setDataSource] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [form] = Form.useForm();
	const [modalMode, setModalMode] = useState("add");
	const [showDisconModal, setShowDisconModal] = useState(false);
	const [selectedType, setSelectedType] = useState(0);
	const [showDiscontinued, setShowDiscontinued] = useState(false);
	const [isDiscontinuedChecked, setIsDiscontinuedChecked] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [ashaCourseChecked, setAshaCourseChecked] = useState(false);
	const [filterTableData, setFilterTableData] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [rowData,setRowData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	const rowSelection = {
		type: 'checkbox',
		columnWidth: '5%',
			onSelect: (recordData, selected, selectedRows) =>{ 
			console.log(recordData)
			console.log(selectedRows)	
			onSelectChange(
				// selectedRows?.map((item) => item.id) || [], // Ensure it is an array
				// selectedRows || [], // Ensure it is an array
				null,
				null,
				recordData, // Current selected record
				dataSource?.findIndex((item) => item.id === recordData.id) // Index of the currently selected record from table data
		);},
		selectedRowKeys: selectedRowKeys
	};

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], recordData = null, index = null) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			recordData, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: selectedRowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setSelectedRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'single' //SELECTION TYPE
		)
		setSelectedRecord(recordData)
  };

	useEffect(() => {
		getAllAreaList();
	}, [selectedType, showDiscontinued]);

	const handleDropdownTypeChange = (value) => {
		setSelectedType(value);
	};

	//fetching all area data list
	const getAllAreaList = () => {
		setLoading(true)
		let payload = {
			type: 2,
			value: selectedType || 0,
			discontinue: showDiscontinued ? "1" : "0",
		}
		fetchStaffApi('/get-all-courses', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				//adding key item for row selection
				setDataSource(res?.data?.length > 0 ? res.data : []);
				setFilterTableData(res?.data);
			} else {
				setDataSource([]);
				setFilterTableData([]);
				message.error(res?.message)
			}
			setLoading(false)
		}).catch(() => setLoading(false));
	};
	const columns = [
		{
			title: 'Course Number',
			dataIndex: 'course_code',
			width:"25%",
			sorter:(a, b) => a.course_code?.localeCompare(b.course_code, 'en', { numeric: true }),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_code,value) => <span onDoubleClick={()=> handleEdit(value)}><HighlightedText text={course_code} highlight={searchValue} /></span>
		},
		{
			title: 'Course Name',
			dataIndex: 'course_name',
			width:"40%",
			sorter: (a, b) => a.course_name.localeCompare(b.course_name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (course_name,value) => <span onDoubleClick={()=> handleEdit(value)}><HighlightedText text={course_name} highlight={searchValue} /></span>,
		},
		{
			title: 'Credits',
			width:"15%",
			align:"right",
			sorter: (a, b) => a.course_credit - b.course_credit,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_,record) => <HighlightedText text={record?.course_credit} highlight={searchValue} />,
		},
		{
			title: 'Sections',
			width:"20%",
			align:"right",
			sorter: (a, b) => a.number_of_sections - b.number_of_sections,
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			render: (_,record) => <HighlightedText text={record?.number_of_sections} highlight={searchValue} />,
		}
	]
	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search)
		const searchFieldNames = [
			"course_code",
			"course_name",
			"course_credit",
			"number_of_sections"
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setDataSource(searchedValue);
	};

	const handleAdd = () => {
		setModalMode("add");
		setShowModal(true);
		setAshaCourseChecked(false)
		form.resetFields()
	}
	//edit
	const handleEdit = () => {
		selectedRecord?.asha_course==="1"? setAshaCourseChecked(true):setAshaCourseChecked(false)

		const isDiscontinued = selectedRecord?.discontinue === "1";
		setShowDiscontinued(isDiscontinued);
		setIsDiscontinuedChecked(isDiscontinued)

		setModalMode("edit");
		setShowModal(true);

		form.setFieldsValue({
			course_code: selectedRecord?.course_code,
			course_name: selectedRecord?.course_name,
			course_credit: selectedRecord?.course_credit,
			number_of_sections: selectedRecord?.number_of_sections,
			course_type: selectedRecord?.course_type,
		});
	}
	const handleCloseModal = () => {
		setShowModal(false);
		form.resetFields();
	}
	const handleDiscontinueCheckboxChange = (e) => {
		setIsDiscontinuedChecked(e.target.checked);
	}
	const handleAshaCourse = (e) => {
		setAshaCourseChecked(e.target.checked);
	}
	//on submit of add or edit
	const handleSaveRecord = (values) => {
		setButtonDisabled(true);
		if (modalMode === "add") {
			const isDuplicate = dataSource.some(item => item.course_code === values.course_code);
			if (isDuplicate) {
				setButtonDisabled(false);
				message.error(constants.errorMessage);
				return; // won't proceed with saving if it's a duplicate
			}
			const payload = {
				adminid: 1,
				type: 2,
				course_code: removeExtraSpaces(values.course_code),
				course_name: removeExtraSpaces(values.course_name),
				course_credit: values.course_credit,
				number_of_sections: values.number_of_sections,
				course_type: values.course_type,
				asha_course:ashaCourseChecked ? "1" : "0"
			};
			fetchStaffApi('/add-course', 'POST', payload).then((res) => {
				if (res?.code === "200") {
					message.success(constants.successMessage);
					handleCloseModal()
					clearRecord()
					getAllAreaList();
				} else message.error(res?.message)
				setButtonDisabled(false);
			}).catch(() => setButtonDisabled(false));
		}
		//on submit of edit
		else if (modalMode === "edit") {
			const payload = {
				adminid: "1",
				type: "2",
				course_code: removeExtraSpaces(form.getFieldValue('course_code')),
				course_id: selectedRecord?.id,
				course_name: removeExtraSpaces(form.getFieldValue('course_name')),
				course_credit: form.getFieldValue('course_credit'),
				number_of_sections: form.getFieldValue('number_of_sections'),
				course_type: values.course_type,
				discontinue: isDiscontinuedChecked ? "1" : "0",
				asha_course: ashaCourseChecked ? "1" : "0",
			};

			fetchStaffApi('/edit-course', 'PUT', payload).then((res) => {
				if (res?.code === "200") {
					message.success(constants.EditSucessMessage)
					handleCloseModal()
					clearRecord()
					setButtonDisabled(false);
					if (showDiscontinued) {
						setShowDiscontinued(true)
						getAllAreaList();
					} else getAllAreaList();
				} else message.error(res?.message)
				setButtonDisabled(false);
			});
		}
	};
	const clearRecord=()=>{
		setSelectedRowKeys([]);
		setSelectedRecord(null);
		setRowData([]);
	};
	//discontinue
	const handleDiscontinue = () => {
		setShowDisconModal(true)
	}
	const handleCloseDisconModal = () => {
		setShowDisconModal(false)
	}
	const handleConfirmDiscontinue = () => {
		setButtonDisabled(true);
		const payload = {
			adminid: 1,
			type: 2,
			course_id: selectedRecord?.id
		}
		// Call the API to discontinue the area
		fetchStaffApi('/delete-course', 'PUT', payload).then((res) => {
			if (res?.code === '200') {
				message.success(constants.DiscontinueSucessMessage);
				setShowDisconModal(false);
				clearRecord()
				getAllAreaList();
			} else message.error(constants.AddAPIError)
			setButtonDisabled(false);
		}).catch(() => setButtonDisabled(false));
	}
	return (
		<Spin spinning={loading}>
			<div>
				<BreadcrumbHeader pageTitle={constants.SLPcourses} breadcrumbItems={breadcrumbItems} />
				<Card className="container-border-custom">
					<Row gutter={[10, 10]}>
						<Col lg={12} xs={24}>
							<Space wrap>
								<Select allowClear className="select-container" placeholder={constants.CourseType} 
								onChange={handleDropdownTypeChange}
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}
								>
									{constants.dropdownOption.map(option => (
										<Option key={option.id} value={option.id} >{option.name}</Option>
									))}
								</Select>

								<Button className=" text-capitalize btn-info"
									hidden={showDiscontinued}
									disabled={rowData.length===1 && (selectedRowKeys.length > 0 || showDiscontinued)}
									onClick={handleAdd}>{constants.Add}
								</Button>
								<Button className="text-capitalize btn-info"
									disabled={rowData.length===0}
									onClick={() => {
										if(selectedRowKeys?.length > 0) handleEdit(selectedRecord)
									}}>{constants.Edit}
								</Button>
								<Button className="text-capitalize btn-info"
									hidden={showDiscontinued}
									disabled={selectedRowKeys.length > 0 ? false : true || showDiscontinued}
									onClick={handleDiscontinue} >{constants.disconBtn}
								</Button>
							</Space>
						</Col>

						<Col lg={12} xs={24}>
							<Flex gap="small" align="flex-end" vertical >
								<Row gutter={[5, 5]}>
									<Col className='checkbox-align' >
										<Checkbox onChange={(e) => {
											setSelectedRowKeys([])
											setSelectedRecord(null)
											setShowDiscontinued(e.target.checked);
										}}>{constants.showDiscontinuedArea}
										</Checkbox>
									</Col>
									<Col>
										<Input className="w-48" placeholder="Search" onChange={onSearch} />
									</Col>
								</Row>
							</Flex>
						</Col>
					</Row>

					<div className="mt-2">
						<Table rowSelection={{...rowSelection,hideSelectAll:true }}
							rowKey={(selectedRecord) => selectedRecord?.id}
							columns={columns}
							dataSource={dataSource}
							bordered size="small"
							className="records-table data_table" rowSelectedBg='#e6f4ff'
							pagination={{
								className:"!mb-0",
								pageSize: constants.TablePageSize,
								showTotal: (total, range) => showTotalItems(total, range, dataSource),
								itemRender: getNextAndPreviousButtons,
							}}
							scroll={{ x: 768, y: 'calc(100vh - 280px)' }}
							onRow={(record, rowIndex) => {
								return {
									onClick: () => onSelectChange(null, null, record, rowIndex),
								}
							}}
						/>
					</div>
				</Card>

				{/*Add and Edit modal*/}
				
				<Modal title={modalMode === "add" ?constants.SlpAdd : `${constants.SlpEdit}${selectedRecord?.course_name}` } open={showModal} onCancel={handleCloseModal} width={600} footer={null}>
					<Form form={form} layout="vertical" onFinish={handleSaveRecord}>
						<Row gutter={[10, 0]}>
							<Col span={12}>
								<Form.Item name="course_code" label={constants.CourseNum}
									rules={[{
										required: true,
										message: constants.FieldRequired,
										whitespace: true,
									}]}>
									<Input 
										disabled={rowData.length===1 && (selectedRecord?.course_code || showDiscontinued)} 
										onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('course_code'))}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="course_name" label={constants.CourseName}
									rules={[{ required: true, message: constants.FieldRequired, whitespace: true, }]}>
									<Input 
										disabled={rowData.length===1 && (selectedRecord?.course_name || showDiscontinued)}
										onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('course_name'))}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="course_credit" label={constants.Credits}
									rules={[{ required: true, message: constants.FieldRequired }]}>
									<InputNumber onKeyPress={(e) => handleKeyPressHandler(e, 2)} min={1} 
									disabled={showDiscontinued} className='w-100' />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="number_of_sections" label={constants.Sections}
									rules={[{ required: true, message: constants.FieldRequired }]}>
									<Select disabled={showDiscontinued} className='select-style' popupClassName="select-dropdown">
										{constants.sectionsDropdownOptions.map(option => (
											<Option key={option} value={option} >{option}</Option>))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="course_type" label={constants.Type}
									rules={[{ required: true, message: constants.FieldRequired }]}>
									<Select disabled={showDiscontinued}  className='select-style' placeholder="Select Course Type" popupClassName="select-dropdown">
										{constants.dropdownOption.map(option => (
											<Option key={option.id} value={option.id} >{option.name}</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12} className='AshaMargin'>
								<Form.Item name="asha_course">
									<Checkbox disabled={showDiscontinued} checked={ashaCourseChecked}
										onChange={handleAshaCourse}>{constants.AshaCourse}</Checkbox>
								</Form.Item>
							</Col>
							<Col span={12} hidden={!showDiscontinued}>
								<Form.Item name="discontinue" >
									<Checkbox checked={isDiscontinuedChecked} 
										onChange={handleDiscontinueCheckboxChange}>{constants.discontinue}</Checkbox>
								</Form.Item>
							</Col>
						</Row>

						<Flex gap="small" align="flex-end" className='action-button-container'>
							<Flex gap="small" wrap="wrap">
								<Button type="primary" className='action-btn' danger
									onClick={handleCloseModal}>
									{constants.Cancel}</Button>
								<Button type="primary" className='action-btn'
									htmlType='submit' disabled={buttonDisabled}>{constants.Save} </Button>
							</Flex>
						</Flex>
					</Form>

				</Modal>
				{/*Discontinue modal*/}
				<Modal 
					title={`${constants.SLPDiscontinueTitle}${selectedRecord?.course_name}`} 
					open={showDisconModal} 
					onCancel={handleCloseDisconModal} 
					width={600} 
					footer={null}
				>
					{
						selectedRecord?.active_survey_list?.length > 0 && (
							<>
								<p>{constants.DiscontinueCourseActiveSurveys}</p>
								<ul>
									{selectedRecord.active_survey_list.map((item, index) => (
										<li key={index}>
											{
												item?.toLowerCase()?.includes('learning') ? 
												`${item} ${constants.Survey}` : item
											}
										</li>
									))}
								</ul>
							</>
						)
					}
					{selectedRecord?.active_survey_list?.length > 0 ? constants.Proceed : constants.DiscontinueCourse}
					<Flex gap="small" align="flex-end" className='action-button-container'>
						<Flex gap="small" wrap="wrap">
							<Button type="primary" className='action-btn' danger
								onClick={handleCloseDisconModal} >
								{constants.Cancel}</Button>
							<Button type="primary" className='action-btn'
								htmlType='submit' disabled={buttonDisabled} onClick={handleConfirmDiscontinue}>{constants.disconBtn}</Button>
						</Flex>
					</Flex>
				</Modal>
			</div>
		</Spin>
	)
}
export default SLPCourses