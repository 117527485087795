/*
File Name: FacultyEdit.js
Author: Saish Sawant
Modified On: 16/1/2024
Description: Faculty Edit
API's used:
1) /get-faculty-list-id/1
2) /edit-faculty-details/216
3) /get-faculty-list
*/
import React, { useEffect, useState, useRef, useContext } from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import { Form, message,Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { fetchStaffApi, fetchStaffFormData } from '../../Services/Api';
import constants from '../../Constants/constants';
import dayjs from 'dayjs'
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import FacultyFormItem from '../Component/FacultyComponents/FacultyFormItem';
import { useFetchStates,useFetchPermaStates } from '../Component/FacultyComponents/FetchStates';
import { removeExtraSpaces } from '../../GlobalFunctions/GlobalFunctions';
import { UserContext } from '../../Context';
const FacultyEdit = () => {
	const { setProfileImage } = useContext(UserContext);
	const facultyType = constants.facultyType;
	const [imageFile, setImageFile] = useState(null);
	const [type, setType] = useState(false)
	const [active, setActive] = useState(null)
	const [advisorCheck,setAdvisorCheck] = useState(false)	
	const [loading,setLoading] = useState(false)
	const location = useLocation()
	const { editrecord, isProfile } = location.state;
	const pageTitle = isProfile?constants.Profile:constants.StaffEdit
	const breadcrumbItemsFacultyEdit = [
		{ label: constants.StaffListing, href: '/staff-list' },
		{ label: constants.StaffEdit, active: true }
	]
	const breadcrumbItemsProfile=[{ label: constants.Profile, active: true },]
	const finalBreadCrubItems=isProfile?breadcrumbItemsProfile:breadcrumbItemsFacultyEdit
	const [display, setDisplay] = useState(true)
	const [form] = Form.useForm();
	const [facultyData, setFacultyData] = useState([])
	const [cellCheck,setCellCheck] = useState(false)
	const [imageText,setImageText] = useState(null)
	const { permaStates, fetchPermaStates } = useFetchPermaStates();
	const { states, fetchStates } = useFetchStates();
	const errorRef = useRef(null)
	const getFacultyData = (() => {
		fetchStaffApi(`/get-faculty-list-id/${editrecord}`, 'GET').then((res) => {	
			if(isProfile) {
				localStorage.setItem("profile_image", res?.data?.faculty_image);
				sessionStorage.setItem("profile_image", res?.data?.faculty_image);
			}
			setFacultyData(res.data)
			if (res.data?.is_active === '0') {
				setActive('2')
			} else {
				setActive(res.data?.is_active)
			}
			if (res.data?.faculty_current_address === res.data?.faculty_permanent_address &&
				res.data?.faculty_current_city_text === res.data?.faculty_permanent_city_text &&
				res.data?.faculty_current_zip === res.data?.faculty_permanent_zip &&
				res.data?.faculty_current_state === res.data?.faculty_permanent_state &&
				res.data?.faculty_current_country === res.data?.faculty_permanent_country
			) {
				setDisplay(true)
			} else {
				setDisplay(false)
			}
		}).catch((error) => {
		})
	})

	// const formatPhoneNumber = (phoneNumber) => {
	// 	// Check if the phone number is already formatted
	// 	const regex = /^\d{3}-\d{3}-\d{4}$/;
	// 	if (!regex.test(phoneNumber)) {
	// 		// If not, format the phone number
	// 		return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
	// 	}
	// 	return phoneNumber;
	// };
	
	useEffect(() => {
		getFacultyData()
	}, [])

	useEffect(() => {
		if (Object.keys(facultyData).length > 0) {
			// Base64 image string
			let facultyImageUrl = facultyData.faculty_image ? facultyData?.faculty_image : null;
			const fetchImg = () => {
				setImageText(facultyImageUrl)
				if (isProfile && facultyData?.faculty_image) {
					localStorage.setItem("profile_image", facultyImageUrl);
					sessionStorage.setItem("profile_image", facultyImageUrl);
				};
			}
			fetchImg()
			fetchStates(facultyData?.faculty_current_country);
			fetchPermaStates(facultyData?.faculty_permanent_country)
			setAdvisorCheck(facultyData?.is_visible_mobile_number==='1')
			setCellCheck(facultyData?.is_advisor===1 || facultyData?.faculty_roles==='5' ||facultyData?.faculty_roles==='4'||facultyData?.faculty_roles==='2' )
			facultyType.forEach((option) => {
				if (facultyData?.user_role === option.option) {
					form.setFieldsValue({
						faculty_roles: option.key,
					});
				}
			});
			if (facultyData?.email_type === "1") {
				setType(true)
			}
			let cell_no = facultyData?.faculty_mobile_number ? facultyData.faculty_mobile_number : '';
			let home_phn = facultyData?.faculty_home_number ? facultyData.faculty_home_number : '';
			let office_phn = facultyData?.faculty_office_number ? facultyData.faculty_office_number : '';
			form.setFieldsValue({
				faculty_first_name: facultyData?.faculty_first_name,
				faculty_middle_name: facultyData?.faculty_middle_name,
				faculty_last_name: facultyData?.faculty_last_name,
				faculty_identify: facultyData?.faculty_identify,
				faculty_mobile_number: cell_no,
				faculty_office_number: office_phn,
				faculty_home_number: home_phn,
				faculty_personal_email: facultyData?.faculty_personal_email,
				faculty_university_email: facultyData?.faculty_university_email,
				faculty_date_joining: facultyData?.faculty_date_joining ? dayjs(facultyData?.faculty_date_joining) : null,
				faculty_asha_number: facultyData?.faculty_asha_number,
				faculty_psha_number: facultyData?.faculty_psha_number,
				faculty_current_address: facultyData?.faculty_current_address,
				faculty_current_country: facultyData?.faculty_current_country,
				faculty_current_city_text: facultyData?.faculty_current_city_text,
				faculty_current_state: facultyData?.faculty_current_state,
				faculty_current_zip: facultyData?.faculty_current_zip,
				faculty_permanent_address: facultyData?.faculty_permanent_address,
				faculty_permanent_country: facultyData?.faculty_permanent_country,
				faculty_permanent_state: facultyData?.faculty_permanent_state,
				faculty_permanent_city_text: facultyData?.faculty_permanent_city_text,
				faculty_permanent_zip: facultyData?.faculty_permanent_zip,
				faculty_username: facultyData?.faculty_username,
				faculty_note: facultyData?.faculty_note,
				is_active: facultyData?.is_active === '2'? '0':facultyData?.is_active
			});
		}
	}, [facultyData, form]);

	

	const onFinish = (formData) => {
		setLoading(true)
		if (formData.faculty_university_email === '' || formData.faculty_university_email === null) {
			delete formData.faculty_university_email;
		}
		formData.faculty_identify=removeExtraSpaces(formData.faculty_identify)
		formData.faculty_current_city_text=removeExtraSpaces(formData.faculty_current_city_text)
		formData.faculty_current_address=removeExtraSpaces(formData.faculty_current_address)
		formData.faculty_personal_email=removeExtraSpaces(formData.faculty_personal_email)
		formData.faculty_identify=removeExtraSpaces(formData.faculty_identify)
		formData.faculty_note=removeExtraSpaces(formData.faculty_note)
		formData.is_active = active
		if(formData.faculty_permanent_address && !display){
			formData.faculty_permanent_city_text=removeExtraSpaces(formData.faculty_permanent_city_text)
			formData.faculty_permanent_address=removeExtraSpaces(formData.faculty_permanent_address)
		}
		let payload = display ? '1' : '0';
		
		if (type) formData = {...formData,email_type: 'on'};
		if(imageFile) formData={...formData,faculty_image:imageFile}	
		if(isProfile && advisorCheck) formData = {...formData,is_visible_mobile_number:'on'}

		fetchStaffFormData(`/edit-faculty-details/${editrecord}?addressCheck=${payload}`, 'POST', formData)
		.then((res) => {
			errorRef.current.scrollIntoView({ behavior: "smooth" });
			if (res?.code === "200") {
				getFacultyData()
				if(isProfile) {
					sessionStorage.setItem('profile_image', res?.data?.image_url || '')
					localStorage.setItem('profile_image', res?.data?.image_url || '')
					setProfileImage(res?.data?.image_url || '')
					message.success(constants.ProfileUpdateSuccess)
				} else message.success(constants.DataUpdated)
			} else if(res?.code === 400){
				message.error(res?.message)
			} else {
				message.error(constants.ErrorMessage)
			}
			setLoading(false)
		}).catch(() => {
			setLoading(false)
		})
	};

	const headerContainer=<div className='student-form-header position-relative rounded h-150px mt-2 pb-5'>
		<h3 className='p-4'>{facultyData?.faculty}</h3>
	</div>

	return (
		<div ref={errorRef}>
			<BreadcrumbHeader pageTitle={`${pageTitle} - ${facultyData?.faculty || ''}`} breadcrumbItems={finalBreadCrubItems} />
			<div>
			<Spin tip={constants.PleaseWait} spinning={loading}>
				<FacultyFormItem saveLoader={loading} edit={true} searchStates={states} searchPermaStates={permaStates}
					onFinish={onFinish} form={form} imageFile={imageFile} setImageFile={setImageFile}
				 active={active} setActive={setActive} display={display} setDisplay={setDisplay} type={type} setType={setType} isProfile={isProfile} isAdvisor={cellCheck} 
					advisorCheck={advisorCheck} setAdvisorCheck={setAdvisorCheck} headerContainer={headerContainer}
					imageText={imageText} setImageText={setImageText}
				/>
				</Spin>
			</div>
		</div>
	)
}

export default FacultyEdit